import React, { Component } from 'react';
import { Result } from 'antd';

class ErrorPage extends Component {
  render() {
    return (
      <Result
        status="500"
        title="Sorry"
        subTitle="Something problem in our side. We will get back to you soon."
      />
    );
  }
}

export default ErrorPage;

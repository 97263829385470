import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings/index';
import { store } from '../store';
import {
  getPromise,
  postPromise,
  patchPromise,
  deletePromise,
  unAuthorisedRedirection,
  showErrorMessages,
  getModuleDetails,
  convertIntoSingular,
} from '../../shared/helper';
import { message } from 'antd';
import sharedActions from '../shared/actions';

export function* fetchUserDetails(params) {
  const searchValue = store.getState().Users.userDetails.searchUser;
  try {
    const url = `${settings.ROOT_URL}users?${
      searchValue ? `search_text=${searchValue}` : `page=${params.page}`
    }`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_USER_TABLE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_USER_TABLE_DETAILS_FAILURE,
    });
    showErrorMessages(error, 'users');
  }
}

export function* createUser(params) {
  try {
    const url = `${settings.ROOT_URL}users`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.CREATE_USER_SUCCESS,
      payload: data.data,
      modalVisible: false,
    });
    yield put({
      type: actions.FETCH_USER_TABLE_DETAILS,
      page: params.currentPage,
    });
    message.success(
      `${convertIntoSingular(
        getModuleDetails('schema', 'users'),
      )} created successfully`,
    );
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.CREATE_USER_FAILURE,
    });
    showErrorMessages(error, 'users');
  }
}

export function* updateUser(params) {
  try {
    const url = `${settings.ROOT_URL}users/${params.id}`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_USER_SUCCESS,
      payload: data.data && data['data'],
      modalVisible: false,
    });
    yield put({
      type: actions.FETCH_USER_TABLE_DETAILS,
      page: params.currentPage,
    });
    message.success(
      `${convertIntoSingular(
        getModuleDetails('schema', 'users'),
      )} updated successfully`,
    );
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.UPDATE_USER_FAILURE,
    });
    showErrorMessages(error, 'users');
  }
}

export function* deleteUser(params) {
  const { pagination, id } = params,
    { current } = pagination;
  try {
    const url = `${settings.ROOT_URL}users/${id}`;
    yield call(() => deletePromise(url));
    yield put({
      type: actions.DELETE_USER_DATA_IN_TABLE_SUCCESS,
    });
    store.dispatch({
      type: actions.FETCH_USER_TABLE_DETAILS,
      page: current,
    });
    message.success(
      `${convertIntoSingular(
        getModuleDetails('schema', 'users'),
      )} deleted successfully`,
    );
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DELETE_USER_DATA_IN_TABLE_FAILURE,
    });
    showErrorMessages(error, 'users');
  }
}

export function* fetchUserProfileDetails(params) {
  try {
    const url = `${settings.ROOT_URL}profile`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_USER_PROFILE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_USER_PROFILE_DETAILS_FAILURE,
    });
    showErrorMessages(error);
  }
}

export function* updateUserProfileDetails(params) {
  try {
    const url = `${settings.ROOT_URL}profile_update`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_USER_PROFILE_DETAILS_SUCCESS,
      payload: data,
    });
    yield put({
      type: sharedActions.SET_PROFILE_DETAILS,
      payload: data,
    });
    message.success('Profile Updated Successfully');
    yield put({
      type: 'CLEAR_PROFILE_AVATAR',
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.UPDATE_USER_PROFILE_DETAILS_FAILURE,
    });
    showErrorMessages(error);
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_USER_TABLE_DETAILS, fetchUserDetails),
    takeEvery(actions.CREATE_USER, createUser),
    takeEvery(actions.UPDATE_USER, updateUser),
    takeEvery(actions.DELETE_USER_DATA_IN_TABLE, deleteUser),
    takeEvery(actions.FETCH_USER_PROFILE_DETAILS, fetchUserProfileDetails),
    takeEvery(actions.UPDATE_USER_PROFILE_DETAILS, updateUserProfileDetails),
  ]);
}

import { cloneDeep, findIndex } from 'lodash';
import actions from './actions';

const initState = {
  userDetails: {
    loader: false,
    tableLists: [],
    tableDetails: undefined,
    modalLoader: false,
    modalVisible: false,
    deleteLoader: false,
    editData: undefined,
    searchUser: undefined,
  },
  userProfile: {
    loader: false,
    editData: {
      data: undefined,
      included: [],
    },
    editLoader: false,
  },
};

export default function usersReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_USER_TABLE_DETAILS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          loader: true,
        },
      };

    case actions.FETCH_USER_TABLE_DETAILS_SUCCESS:
      const { included } = action.payload;
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          loader: false,
          tableLists: action.payload.data,
          tableDetails: action.payload.meta,
          userRoles: included.map(data => data.attributes),
        },
      };

    case actions.FETCH_USER_TABLE_DETAILS_FAILURE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          loader: false,
        },
      };

    case actions.CREATE_USER:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          modalLoader: true,
        },
      };

    case actions.CREATE_USER_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          modalLoader: false,
          editData: action.payload,
          modalVisible: action.modalVisible,
        },
      };

    case actions.CREATE_USER_FAILURE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          modalLoader: false,
        },
      };

    case actions.UPDATE_USER:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          modalLoader: true,
        },
      };

    case actions.UPDATE_USER_SUCCESS:
      let tableLists = cloneDeep(state.userDetails.tableLists);
      let positionOfIndex = findIndex(tableLists, function(object) {
        return object.attributes.id === action.payload.attributes.id;
      });
      tableLists.splice(positionOfIndex, 1, action.payload);
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          modalLoader: false,
          tableLists,
          modalVisible: false,
        },
      };

    case actions.UPDATE_USER_FAILURE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          modalLoader: false,
          modalVisible: true,
        },
      };

    case actions.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          modalVisible: [false, true].includes(action.modalVisible)
            ? action.modalVisible
            : state.userDetails.modalVisible,
          editData: action.editData ? action.editData : undefined,
        },
      };

    case actions.DELETE_USER_DATA_IN_TABLE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          deleteLoader: true,
        },
      };

    case actions.DELETE_USER_DATA_IN_TABLE_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          deleteLoader: false,
        },
      };

    case actions.DELETE_USER_DATA_IN_TABLE_FAILURE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          deleteLoader: false,
        },
      };

    case actions.SEARCH_USER_DETAILS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          searchUser: action.searchValue,
        },
      };

    case actions.CLEAR_USER_DETAILS:
      return initState;

    case actions.FETCH_USER_PROFILE_DETAILS:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          loader: true,
        },
      };

    case actions.FETCH_USER_PROFILE_DETAILS_SUCCESS:
      const details = action.payload;
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          loader: false,
          editData: {
            data: details.data,
            included: details.included,
          },
        },
      };

    case actions.FETCH_USER_PROFILE_DETAILS_FAILURE:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          loader: false,
        },
      };

    case actions.UPDATE_USER_PROFILE_DETAILS:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          editLoader: true,
        },
      };

    case actions.UPDATE_USER_PROFILE_DETAILS_SUCCESS:
      const { payload } = action;
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          editLoader: false,
          editData: {
            data: payload.data,
            included: payload.included,
          },
        },
      };

    case actions.UPDATE_USER_PROFILE_DETAILS_FAILURE:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          editLoader: false,
        },
      };

    default:
      return state;
  }
}

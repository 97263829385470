import { settingsActionNames, settingsDefaultActions } from './constant';

export function generateSettingsActions() {
  let actions = {};
  Object.keys(settingsActionNames).forEach(actionKey => {
    let actionValue = settingsActionNames[actionKey];
    for (let defaultAction of settingsDefaultActions) {
      actions = {
        ...actions,
        [`${actionValue}_${defaultAction}`]: `${actionValue}_${defaultAction}`,
      };
    }
  });
  return actions;
}

import asyncComponent from '../shared/asyncFunction';
import { convertIntoHypenValues, getModuleDetails } from '../shared/helper';

export function getChangeRequestRoutePath() {
  const moduleName = getModuleDetails('ui', 'change_requests');
  return [
    {
      path: convertIntoHypenValues(moduleName),
      component: asyncComponent(() => import('../containers/changeRequests')),
    },
    {
      path: `${convertIntoHypenValues(moduleName)}/:employee_name/:id`,
      component: asyncComponent(() => import('../components/changeRequests/viewChangeRequest')),
    },
  ];
}
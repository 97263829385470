import Auth from './auth/reducer';
import Settings from './settings/reducer';
import Users from './users/reducer';
import MemberList from './memberList/reducer';
import Reports from './reports/reducer';
import Shared from './shared/reducer';
import Notification from './notifications/reducer';
import ChangeRequest from './changeRequests/reducer';
import ContactList from './contactList/reducer';
import Grievance from './grievence/reducer';
import MailLog from './mailLog/reducer';
import UserAudit from './userAudit/reducer'
export default {
  Auth,
  MemberList,
  Reports,
  Users,
  Settings,
  Shared,
  Notification,
  ChangeRequest,
  ContactList,
  Grievance,
  MailLog,
  UserAudit
};

import actions from './actions';

const initState = {
  mailLogDetails: {
    loader: false,
    tableLists: [],
    tableDetails: undefined,
    modalVisible: false,
    editData: null,
    changesDetails: null,
    currentPage: 1
  }
};

export default function mailLogReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_MAIL_LOG_DETAILS:
      return {
        ...state,
        mailLogDetails: {
          ...state.mailLogDetails,
          loader: true,
          currentPage: action.page ? action.page : state.currentPage
        }
      };

    case actions.FETCH_MAIL_LOG_DETAILS_SUCCESS:
      return {
        ...state,
        mailLogDetails: {
          ...state.mailLogDetails,
          loader: false,
          tableLists: action.payload.data,
          tableDetails: action.payload.meta
        }
      };

    case actions.FETCH_MAIL_LOG_DETAILS_FAILURE: {
      return {
        ...state,
        mailLogDetails: {
          ...state.mailLogDetails,
          loader: false
        }
      };
    }
    case actions.SET_MAIL_LOG_MODAL_VISIBLE: {
      return {
        ...state,
        mailLogDetails: {
          ...state.mailLogDetails,
          modalVisible: action.payload,
          editData: action.editData,
          currentPage: action.page ? action.page : state.currentPage
        }
      };
    }
    case actions.CREATE_MAIL_LOG_DETAILS: {
      return {
        ...state,
        mailLogDetails: {
          ...state.mailLogDetails,
          loader: true
        }
      };
    }
    case actions.CREATE_MAIL_LOG_DETAILS_SUCCESS: {
      return {
        ...state,
        mailLogDetails: {
          ...state.mailLogDetails,
          loader: false,
          modalVisible: false
        }
      };
    }
    case actions.CREATE_MAIL_LOG_DETAILS_FAILURE: {
      return {
        ...state,
        mailLogDetails: {
          ...state.mailLogDetails,
          loader: false
        }
      };
    }
    case actions.UPDATE_MAIL_LOG_DETAILS: {
      return {
        ...state,
        mailLogDetails: {
          ...state.mailLogDetails,
          loader: true
        }
      };
    }
    case actions.UPDATE_MAIL_LOG_DETAILS_SUCCESS: {
      return {
        ...state,
        mailLogDetails: {
          ...state.mailLogDetails,
          loader: false,
          modalVisible: false
        }
      };
    }
    case actions.UPDATE_MAIL_LOG_DETAILS_FAILURE: {
      return {
        ...state,
        mailLogDetails: {
          ...state.mailLogDetails,
          loader: false
        }
      };
    }
    case actions.DELETE_MAIL_LOG_DETAILS: {
      return {
        ...state,
        mailLogDetails: {
          ...state.mailLogDetails,
          loader: false
        }
      };
    }
    case actions.DELETE_MAIL_LOG_DETAILS_SUCCESS: {
      return {
        ...state,
        mailLogDetails: {
          ...state.mailLogDetails,
          loader: false
        }
      };
    }
    case actions.DELETE_MAIL_LOG_DETAILS_FAILURE: {
      return {
        ...state,
        mailLogDetails: {
          ...state.mailLogDetails,
          loader: false
        }
      };
    }
    default:
      return state;
  }
}

import { all, call, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';
import authActions from '../auth/actions';
import sharedActions from '../shared/actions';
import settings from '../../settings/index';
import {
  postPromise,
  deletePromise,
  patchPromise,
  getPromise,
  unAuthorisedRedirection,
  showErrorMessages,
  updateBrowserHistory,
  getSubDomain
} from '../../shared/helper';
import { message } from 'antd';
import { history, store } from '../store';

export function* checkAuthorization() {
  try {
    let url = `${settings.ROOT_URL}authenticate`;
    const data = yield call(() => getPromise(url));
    yield put({ type: authActions.AUTHORIZATION_SUCCESS, payload: data.data });
    store.dispatch({ type: authActions.FETCH_USER_PERMISSIONS });
    store.dispatch({ type: sharedActions.SET_PROFILE_DETAILS, payload: data });
    store.dispatch({ type: authActions.GET_SAAS_DATA });
  } catch (error) {
    const is404 = error?.response?.status === 404;
    yield put({
      type: actions.AUTHORIZATION_FAILURE,
      isSubDomainInvalid: is404
    });
    if (is404) {
      updateBrowserHistory('/404');
    } else {
      showErrorMessages(error);
    }
  }
}

export function* login(params) {
  try {
    let url = `${settings.ROOT_URL}users/login`;
    const data = yield call(() => postPromise(url, params.payload));
    localStorage.setItem(`${getSubDomain()}LoginTime`, new Date());
    message.success(data.message);
    yield put({
      type: actions.LOGIN_SUCCESS
    });
    store.dispatch({ type: authActions.CHECK_AUTHORIZATION });
  } catch (error) {
    showErrorMessages(error);
    yield put({
      type: actions.LOGIN_FAILURE
    });
  }
}

export function* logOut() {
  try {
    const url = `${settings.ROOT_URL}users/logout`;
    yield call(() => deletePromise(url));
    yield put({
      type: actions.LOGOUT_SUCCESS
    });
    history.push('/sign-in');
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
  }
}

export function* forgotPassword(params) {
  try {
    let url = `${settings.ROOT_URL}users/forgot_password`;
    yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.FORGOT_PASSWORD_SUCCESS
    });
    message.success(
      'If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.'
    );
    history.push('/sign-in');
  } catch (error) {
    showErrorMessages(error);
    yield put({
      type: actions.FORGOT_PASSWORD_FAILURE
    });
    yield put({
      type: actions.LOGIN_FAILURE
    });
  }
}

export function* resetPassword(params) {
  try {
    let url = `${settings.ROOT_URL}${
      params.isEmployee ? 'employees' : 'users'
    }/reset_password`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.RESET_PASSWORD_SUCCESS
    });
    message.success(data.message);
    history.push('/');
  } catch (error) {
    showErrorMessages(error);
    yield put({
      type: actions.RESET_PASSWORD_FAILURE
    });
    yield put({
      type: actions.LOGIN_FAILURE
    });
  }
}

export function* getSaaSData() {
  try {
    let url = `${settings.ROOT_URL}account`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_SAAS_DATA_SUCCESS,
      payload: data.data.attributes.saas_json
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions.GET_SAAS_DATA_FAILURE
    });
  }
}

export function* fetchPermissions(params) {
  try {
    const url = `${settings.ROOT_URL}user_rights`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_USER_PERMISSIONS_SUCCESS,
      payload: data
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_USER_PERMISSIONS_FAILURE
    });
    showErrorMessages(error);
  }
}

export function* refreshAuthToken(params) {
  try {
    const url = `${settings.ROOT_URL}refresh_jwt_token`;
    yield call(() => getPromise(url));
    localStorage.setItem(`${getSubDomain()}LoginTime`, new Date());
    yield put({
      type: actions.REFRESH_AUTH_TOKEN_SUCCESS
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.REFRESH_AUTH_TOKEN_FAILURE
    });
    showErrorMessages(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHECK_AUTHORIZATION, checkAuthorization),
    takeEvery(actions.LOGIN, login),
    takeEvery(actions.LOGOUT, logOut),
    takeEvery(actions.FORGOT_PASSWORD, forgotPassword),
    takeEvery(actions.RESET_PASSWORD, resetPassword),
    takeEvery(actions.GET_SAAS_DATA, getSaaSData),
    takeEvery(actions.FETCH_USER_PERMISSIONS, fetchPermissions),
    takeEvery(actions.REFRESH_AUTH_TOKEN, refreshAuthToken)
  ]);
}

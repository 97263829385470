import asyncComponent from '../shared/asyncFunction';
import {
  convertIntoHypenValues,
  convertIntoSingular,
  getModuleDetails,
} from '../shared/helper';

export function getMemberListRoutePath() {
  const moduleName = getModuleDetails('ui', 'employees');
  return [
    {
      path: convertIntoHypenValues(moduleName),
      component: asyncComponent(() => import('../containers/memberList')),
    },
    {
      path: `${convertIntoHypenValues(moduleName)}/:employee_name/edit`,
      component: asyncComponent(() =>
        import('../components/memberList/listPage/employeeDetail'),
      ),
    },
    {
      path: `${convertIntoHypenValues(moduleName)}/new-${convertIntoHypenValues(
        convertIntoSingular(
          getModuleDetails('schema', 'employees'),
        ).toLowerCase(),
      )}`,
      component: asyncComponent(() =>
        import('../components/memberList/listPage/addMember'),
      ),
    },
    {
      path: `${convertIntoHypenValues(moduleName)}/:employee_name`,
      component: asyncComponent(() =>
        import('../components/memberList/listPage/employeeDetail'),
      ),
    },
  ];
}

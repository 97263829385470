import actions from './actions';

const initState = {
  userAuditDetails: {
    loader: false,
    tableLists: [],
    tableDetails: undefined,
    modalVisible: false,
    editData: null,
    changesDetails: null
  }
};

export default function userAuditReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_USER_AUDIT_DETAILS:
      return {
        ...state,
        userAuditDetails: {
          ...state.userAuditDetails,
          loader: true
        }
      };

    case actions.FETCH_USER_AUDIT_DETAILS_SUCCESS:
      return {
        ...state,
        userAuditDetails: {
          ...state.userAuditDetails,
          loader: false,
          tableLists: action.payload.data,
          tableDetails: action.payload.meta
        }
      };

    case actions.FETCH_USER_AUDIT_DETAILS_FAILURE: {
      return {
        ...state,
        userAuditDetails: {
          ...state.userAuditDetails,
          loader: false
        }
      };
    }
    case actions.SET_USER_AUDIT_MODAL_VISIBLE: {
      return {
        ...state,
        userAuditDetails: {
          ...state.userAuditDetails,
          modalVisible: action.payload,
          editData: action.editData
        }
      };
    }
    case actions.FETCH_USER_DETAILS:
      return {
        ...state,
        userAuditDetails: {
          ...state.userAuditDetails,
          loader: true
        }
      };

    case actions.FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userAuditDetails: {
          ...state.userAuditDetails,
          loader: false,
          changesDetails: action.payload?.data
        }
      };

    case actions.FETCH_USER_DETAILS_FAILURE: {
      return {
        ...state,
        userAuditDetails: {
          ...state.userAuditDetails,
          loader: false
        }
      };
    }
    default:
      return state;
  }
}

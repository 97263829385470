import asyncComponent from '../shared/asyncFunction';
import { convertIntoHypenValues, getModuleDetails } from '../shared/helper';

export function getSettingsRoutePath() {
  return [
    {
      path: convertIntoHypenValues(getModuleDetails('ui', 'settings')),
      component: asyncComponent(() => import('../containers/settings')),
    },
  ];
}

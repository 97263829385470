import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import memberListSaga from './memberList/saga';
import reportsSaga from './reports/saga';
import usersSaga from './users/saga';
import settingsSaga from './settings/saga';
import sharedSaga from './shared/saga';
import notificationSaga from './notifications/saga';
import changeRequestSaga from './changeRequests/saga';
import contactListSaga from './contactList/saga';
import Grievance from './grievence/saga';
import mailLog from './mailLog/saga';
import userAudit from './userAudit/saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    memberListSaga(),
    reportsSaga(),
    usersSaga(),
    settingsSaga(),
    sharedSaga(),
    notificationSaga(),
    changeRequestSaga(),
    contactListSaga(),
    Grievance(),
    mailLog(),
    userAudit()
  ]);
}

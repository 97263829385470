import './style.scss';
import React, { Component } from 'react';
import { Layout, Menu, Icon } from 'antd';
import Logo from '../../images/logo.svg';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import actions from '../../redux/shared/actions';
import { store } from '../../redux/store';
import {
  checkBeneficiariesPercentage,
  checkTabsAccessPermission,
  convertIntoHypenValues,
  getModuleDetails,
  setTableColumnWidth
} from '../../shared/helper';
import {
  memberTabs,
  userTabs,
  reportTabs,
  settingsTabs,
  notificationTabs,
  superUserTabs,
  changeRequestsTabs,
  contactListTabs, grievanceListTabs, mailLog, userAudit
} from '../../shared/constants';
import authActions from '../../redux/auth/actions';

const { Sider } = Layout;

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    };
  }

  onCollapse = collapsed => {
    const { tableName } = this.props;
    this.setState({ collapsed });
    if (tableName) {
      setTimeout(() => {
        setTableColumnWidth(tableName);
      }, 200);
    }
  };

  breakPoint = broken => {
    if (broken) {
      store.dispatch({
        type: actions.SET_IS_MOBILE_OR_NOT,
        payload: true
      });
    } else {
      store.dispatch({
        type: actions.SET_IS_MOBILE_OR_NOT,
        payload: false
      });
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { navbarToggledStatus } = this.props;
    if (prevProps.navbarToggledStatus !== navbarToggledStatus) {
      this.setState({
        collapsed: !navbarToggledStatus
      });
    }
  }

  toggleCollapsed = () => {
    const { isMobile, navbarToggledStatus } = this.props;
    isMobile &&
      store.dispatch({
        type: actions.SET_NAVBAR_TOGGLED_STATUS,
        payload: !navbarToggledStatus
      });
  };

  logOut = () => {
    store.dispatch({
      type: authActions.LOGOUT
    });
  };

  render() {
    const {
        navbarToggledStatus,
        isMobile,
        currentMenu,
        superUserJson
      } = this.props,
      { collapsed } = this.state;
    return (
      <Sider
        collapsible
        breakpoint={'md'}
        collapsed={collapsed}
        onCollapse={this.onCollapse}
        className={`${!collapsed ? 'un-collapsed' : ''} ${
          isMobile ? 'mobile' : ''
        } ${isMobile && !navbarToggledStatus ? 'hide-sidebar' : ''}`}
        onBreakpoint={this.breakPoint}
        trigger={<Icon type={`${collapsed ? 'right' : 'left'}-circle`} />}
      >
        <div className="logo" onClick={this.toggleCollapsed}>
          <NavLink
            onClick={checkBeneficiariesPercentage}
            to={`/${convertIntoHypenValues(
              getModuleDetails('ui', 'employees'),
            )}`}
          >
            <img width="36" height="40" src={Logo} alt={'logo'} />
          </NavLink>
        </div>
        <Menu theme="dark" selectedKeys={currentMenu} mode="inline">
          {checkTabsAccessPermission(memberTabs) &&
            getModuleDetails('ui', 'employees') && (
            <Menu.Item key="1" onClick={this.toggleCollapsed}>
              <Icon type="bars" />
              <NavLink
                onClick={checkBeneficiariesPercentage}
                to={`/${convertIntoHypenValues(
                  getModuleDetails('ui', 'employees'),
                )}`}
              >
                <span>{getModuleDetails('ui', 'employees')}</span>
              </NavLink>
            </Menu.Item>
          )}
          {checkTabsAccessPermission(grievanceListTabs) &&
            getModuleDetails('ui', 'employee_grievances') && (
            <Menu.Item key="employee_grievances" onClick={this.toggleCollapsed}>
              <Icon type="profile" />
              <NavLink
                onClick={checkBeneficiariesPercentage}
                to={`/${convertIntoHypenValues(
                  getModuleDetails('ui', 'employee_grievances'),
                )}`}
              >
                <span>{getModuleDetails('ui', 'employee_grievances')}</span>
              </NavLink>
            </Menu.Item>
          )}
          {checkTabsAccessPermission(contactListTabs) &&
          getModuleDetails('ui', 'contact_persons') && (
            <Menu.Item
              key="6"
              onClick={this.toggleCollapsed}
            >
              <Icon type="contacts" />
              <NavLink
                to={`/${convertIntoHypenValues(
                  getModuleDetails('ui', 'contact_persons'),
                )}`}
              >
                <span>{getModuleDetails('ui', 'contact_persons')}</span>
              </NavLink>
            </Menu.Item>
          )}
          {checkTabsAccessPermission(reportTabs) &&
            getModuleDetails('ui', 'reports') && (
            <Menu.Item
              key="2"
              onClick={this.toggleCollapsed}
              className={'hide-element'}
            >
              <Icon type="bar-chart" />
              <NavLink
                onClick={checkBeneficiariesPercentage}
                to={`/${convertIntoHypenValues(
                  getModuleDetails('ui', 'reports'),
                )}`}
              >
                <span>{getModuleDetails('ui', 'reports')}</span>
              </NavLink>
            </Menu.Item>
          )}
          {checkTabsAccessPermission(notificationTabs) &&
            getModuleDetails('ui', 'notification') && (
            <Menu.Item key="7" onClick={this.toggleCollapsed}>
              <Icon type="bell" />
              <NavLink
                onClick={checkBeneficiariesPercentage}
                to={`/${convertIntoHypenValues(
                  getModuleDetails('ui', 'notification'),
                )}`}
              >
                <span>{getModuleDetails('ui', 'notification')}</span>
              </NavLink>
            </Menu.Item>
          )}
          {checkTabsAccessPermission(superUserTabs) &&
            superUserJson['schema']['super_users']['key_name'] && (
            <Menu.Item key="8" onClick={this.toggleCollapsed}>
              <Icon type="user" />
              <NavLink
                onClick={checkBeneficiariesPercentage}
                to={`/${convertIntoHypenValues(
                  superUserJson['schema']['super_users']['key_name'],
                )}`}
              >
                <span>
                  {superUserJson['schema']['super_users']['key_name']}
                </span>
              </NavLink>
            </Menu.Item>
          )}
          {checkTabsAccessPermission(userTabs) &&
            getModuleDetails('schema', 'users') && (
            <Menu.Item key="3" onClick={this.toggleCollapsed}>
              <Icon type="user" />
              <NavLink
                onClick={checkBeneficiariesPercentage}
                to={`/${convertIntoHypenValues(
                  getModuleDetails('schema', 'users'),
                )}`}
              >
                <span>{getModuleDetails('schema', 'users')}</span>
              </NavLink>
            </Menu.Item>
          )}
          {checkTabsAccessPermission(settingsTabs) &&
            getModuleDetails('ui', 'settings') && (
            <Menu.Item key="4" onClick={this.toggleCollapsed}>
              <Icon type="setting" />
              <NavLink
                onClick={checkBeneficiariesPercentage}
                to={`/${convertIntoHypenValues(
                  getModuleDetails('ui', 'settings'),
                )}`}
              >
                <span>{getModuleDetails('ui', 'settings')}</span>
              </NavLink>
            </Menu.Item>
          )}
          {checkTabsAccessPermission(changeRequestsTabs) &&
            getModuleDetails('ui', 'change_requests') && (
            <Menu.Item key="9" onClick={this.toggleCollapsed}>
              <Icon type="check" />
              <NavLink
                to={`/${convertIntoHypenValues(
                  getModuleDetails('ui', 'change_requests'),
                )}`}
              >
                <span>{getModuleDetails('ui', 'change_requests')}</span>
              </NavLink>
            </Menu.Item>
          )}
          {checkTabsAccessPermission(userAudit) &&
            getModuleDetails('ui', 'user_audit') && (
            <Menu.Item key="user_audit" onClick={this.toggleCollapsed}>
              <Icon type="audit" />
              <NavLink
                onClick={checkBeneficiariesPercentage}
                to={`/${convertIntoHypenValues(
                  getModuleDetails('ui', 'user_audit'),
                )}`}
              >
                <span>{getModuleDetails('ui', 'user_audit')}</span>
              </NavLink>
            </Menu.Item>
          )}
          {checkTabsAccessPermission(mailLog) &&
            getModuleDetails('ui', 'maillogs') && (
            <Menu.Item key="maillogs" onClick={this.toggleCollapsed}>
              <Icon type="mail" />
              <NavLink
                to={`/${convertIntoHypenValues(
                  getModuleDetails('ui', 'maillogs'),
                )}`}
              >
                <span>{getModuleDetails('ui', 'maillogs')}</span>
              </NavLink>
            </Menu.Item>
          )}
          {isMobile && (
            <Menu.Item key="5" onClick={this.toggleCollapsed}>
              <Icon type="profile" />
              <NavLink onClick={checkBeneficiariesPercentage} to={'/profile'}>
                <span>Profile</span>
              </NavLink>
            </Menu.Item>
          )}
          {isMobile && (
            <Menu.Item key="logout" onClick={this.logOut}>
              <Icon type="logout" />
              <span>Logout</span>
            </Menu.Item>
          )}
        </Menu>
      </Sider>
    );
  }
}
function mapStateToProps(state) {
  const { navbarToggledStatus, isMobile, tableName } = state.Shared,
    { superUserJson } = state.Auth;
  return {
    navbarToggledStatus,
    isMobile,
    tableName,
    superUserJson
  };
}

export default connect(mapStateToProps, actions)(Index);

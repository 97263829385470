import actions from './actions';
import { getLocalData, setLocalData } from '../../shared/helper'

const commonValues = {
  loader: false,
  tableLists: [],
  tableDetails: undefined,
  searchValue: undefined,
  modalLoader: false,
  modalVisible: false,
  deleteLoader: false,
  editData: undefined
};

const initState = {
  createLoader: false,
  contactDetails: {
    loader: false,
    basicContactDetails: {
      editData: {},
      contactInformation: []
    },
    tableLists: undefined,
    tableDetails: undefined,
    employeeDepartments: commonValues,
    employeeFacilities: commonValues,
    categoryList: [],
    facilityList: [],
    selectedEmployeeId: getLocalData('selectedContactList') ?
      getLocalData('selectedContactList') : null
  },
  searchValue: ''
}

export default function ContactListReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_CONTACT_LIST:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          loader: true
        }
      }
    case actions.GET_CONTACT_LIST_SUCCESS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          tableLists: action.payload.data,
          tableDetails: action.payload.meta,
          loader: false
        }
      }
    case actions.GET_CONTACT_LIST_FAILURE:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          loader: false
        }
      }
    case actions.RESET_CONTACT_LIST:
      return {
        ...state,
        contactDetails:{
          ...state.contactDetails,
          categoryList: [],
          facilityList: []
        }
      }
    case actions.CREATE_CONTACT_LIST:
      return {
        ...state,
        createLoader: true
      }
    case actions.CREATE_CONTACT_LIST_SUCCESS:
      setLocalData('selectedContactList', action.payload.data?.id);
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          basicContactDetails: {
            ...state.contactDetails.basicContactDetails,
            editData: action.payload.data,
            contactInformation: action.payload.included
          },
          loader: false,
          selectedEmployeeId: action.payload.data?.id
        },
        createLoader: false
      }
    case actions.CREATE_CONTACT_LIST_FAILURE:
      return {
        ...state,
        createLoader: false
      }
    case actions.UPDATE_CONTACT_LIST:
      return {
        ...state,
        createLoader: true
      }
    case actions.UPDATE_CONTACT_LIST_SUCCESS:
      setLocalData('selectedContactList', action.payload.data?.id);
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          basicContactDetails: {
            ...state.contactDetails.basicContactDetails,
            editData: action.payload.data,
            contactInformation: action.payload.included
          },
          loader: false,
          selectedEmployeeId: action.payload.data?.id
        },
        createLoader: false
      }
    case actions.UPDATE_CONTACT_LIST_FAILURE:
      return {
        ...state,
        createLoader: false
      }
    case actions.DELETE_CONTACT_LIST:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          loader: true
        }
      }
    case actions.DELETE_CONTACT_LIST_SUCCESS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          loader: false
        }
      }
    case actions.DELETE_CONTACT_LIST_FAILURE:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          loader: false
        }
      }
    case actions.GET_CONTACT_DETAILS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          loader: true
        }
      }
    case actions.GET_CONTACT_DETAILS_SUCCESS:
      setLocalData('selectedContactList', action.payload.data?.id);
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          basicContactDetails: {
            ...state.contactDetails.basicContactDetails,
            editData: action.payload.data,
            contactInformation: action.payload.included
          },
          loader: false,
          selectedEmployeeId: action.payload.data?.id
        }
      }
    case actions.GET_CONTACT_DETAILS_FAILURE:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          loader: false
        }
      }
    case actions.RESET_CONTACT_EDIT_DATA:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          basicContactDetails: {
            ...state.contactDetails.basicContactDetails,
            editData: {},
            contactInformation: []
          }
        }
      }
    case actions.SET_PROFILE_OTHERS_MODAL_VISIBLE:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          employeeDepartments: {
            ...state.contactDetails.employeeDepartments,
            modalVisible: action.employeeDepartments
          },
          employeeFacilities: {
            ...state.contactDetails.employeeFacilities,
            modalVisible: action.employeeFacilities
          }
        }
      }
    case actions.EMPLOYEES_DEPARTMENTS_CREATE_DETAILS_SUCCESS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          employeeDepartments: {
            ...state.contactDetails?.employeeDepartments,
            modalLoader: false,
            editData: action.payload,
            modalVisible: action.modalVisible
          }
        }
      };

    case actions.EMPLOYEES_DEPARTMENTS_CREATE_DETAILS_FAILURE:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          employeeDepartments: {
            ...state.contactDetails?.employeeDepartments,
            modalLoader: false,
            editData: undefined
          }
        }
      };
    case actions.FETCH_EMPLOYEES_DEPARTMENTS_DETAILS_SUCCESS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          categoryList: action.payload.data
        }
      };

    case actions.FETCH_EMPLOYEES_DEPARTMENTS_DETAILS_FAILURE:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails
        }
      };
    case actions.FETCH_EMPLOYEES_FACILITY_DETAILS_SUCCESS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          facilityList: action.payload.data
        }
      };

    case actions.FETCH_EMPLOYEES_FACILITY_DETAILS_FAILURE:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails
        }
      };

    case actions.EMPLOYEES_FACILITY_CREATE_DETAILS_SUCCESS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          employeeFacilities: {
            ...state.contactDetails?.employeeFacilities,
            modalLoader: false,
            editData: action.payload,
            modalVisible: action.modalVisible
          }
        }
      };

    case actions.EMPLOYEES_FACILITY_CREATE_DETAILS_FAILURE:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          employeeFacilities: {
            ...state.contactDetails?.employeeFacilities,
            modalLoader: false,
            editData: undefined
          }
        }
      };
    case actions.SET_SELECTED_EMPLOYEE_DETAIL:
      setLocalData('selectedContactList', action.slug);
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          selectedEmployeeId: action.slug
        }
      };

    case actions.SET_EMPLOYEE_DETAIL_EDIT_DATA:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          employeeDepartments: {
            ...state.contactDetails?.employeeDepartments,
            editData: action.editData
          }
        }
      };

    case actions.SET_EMPLOYEE_FACILITY_DETAIL_EDIT_DATA:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          employeeFacilities: {
            ...state.contactDetails?.employeeFacilities,
            editData: action.editData
          }
        }
      };

    case actions.SET_EMPLOYEE_DETAIL_DATA:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          employeeDepartments: {
            ...state.contactDetails?.employeeDepartments,
            editData: action.editData
          }
        }
      };
    default:
      return state;
  }
}
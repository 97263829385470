// Breadcrumbs
import { getSubDomain } from './helper';

export const tabKeyValues = {
  memberList: [
    { key: '1', value: 'Profile' },
    { key: '2', value: 'Firearm Statuses' },
    { key: '3', value: 'PAF' },
    { key: '4', value: 'Awards' },
    { key: '5', value: 'Unions' },
    { key: '6', value: 'Benefits' },
    { key: '7', value: 'Disciplines' },
    { key: '8', value: 'Grievances' },
    { key: '9', value: 'Uploads' },
    { key: '10', value: 'Analytics' }
  ],
  reports: [
    {
      key: '1',
      value: 'Single Member',
      writePermission: 'reportSingleMember'
    },
    { key: '2', value: 'Benefits', writePermission: 'reportBenefits' },
    { key: '3', value: 'Sick Bank', writePermission: 'reportSickBank' },
    { key: '4', value: 'Lodi', writePermission: 'reportLodi' },
    {
      key: '5',
      value: 'Union Meetings',
      writePermission: 'reportUnionMeetings'
    },
    {
      key: '6',
      value: 'Member Delegate Assignment',
      writePermission: 'reportMemberDelegateAssignment'
    },
    { key: '7', value: 'Disciplines', writePermission: 'reportDisciplines' },
    { key: '8', value: 'Grievances', writePermission: 'reportGrievances' },
    { key: '9', value: 'Janus', writePermission: 'reportJanus' },
    { key: '10', value: 'Benefit Coverage Expiration', writePermission: 'reportBenefitCoverageExpiration' }
  ],
  settings: [
    {
      key: '1',
      value: 'Benefits',
      readPermission: 'readBenefit',
      writePermission: 'writeBenefit'
    },
    {
      key: '2',
      value: 'Departments',
      readPermission: 'readDepartment',
      writePermission: 'writeDepartment'
    },
    {
      key: '3',
      value: 'Commands',
      readPermission: 'readCommand',
      writePermission: 'writeCommand'
    },
    {
      key: '4',
      value: 'PAF',
      readPermission: 'readPaf',
      writePermission: 'writePaf'
    },
    {
      key: '5',
      value: 'Ranks',
      readPermission: 'readRank',
      writePermission: 'writeRank'
    },
    {
      key: '6',
      value: 'Firearm Statuses',
      readPermission: 'readFirearmStatus',
      writePermission: 'writeFirearmStatus'
    },
    {
      key: '7',
      value: 'Employment Statuses',
      readPermission: 'readEmploymentStatus',
      writePermission: 'writeEmploymentStatus'
    },
    {
      key: '8',
      value: 'Officer Statuses',
      readPermission: 'readOfficerStatus',
      writePermission: 'writeOfficerStatus'
    },
    {
      key: '9',
      value: 'Marital Statuses',
      readPermission: 'readMaritalStatus',
      writePermission: 'writeMaritalStatus'
    },
    {
      key: '10',
      value: 'Positions',
      readPermission: 'readPosition',
      writePermission: 'writePosition'
    },
    {
      key: '11',
      value: 'Payment Types',
      readPermission: 'readPaymentTypes',
      writePermission: 'writePaymentTypes'
    },
    {
      key: '12',
      value: 'Delegate Meeting Types',
      readPermission: 'readMeetingType',
      writePermission: 'writeMeetingType'
    },
    {
      key: '13',
      value: 'Disciplines',
      readPermission: 'readDisciplines',
      writePermission: 'writeDisciplines'
    },
    {
      key: '14',
      value: 'Grievances',
      readPermission: 'readGrievance',
      writePermission: 'writeGrievance'
    },
    {
      key: '15',
      value: 'Gender',
      readPermission: 'readGender',
      writePermission: 'writeGender'
    },
    {
      key: '16',
      value: 'Units',
      readPermission: 'readUnit',
      writePermission: 'writeUnit'
    },
    {
      key: '17',
      value: 'Sections',
      readPermission: 'readSection',
      writePermission: 'writeSection'
    },
    {
      key: '18',
      value: 'Titles',
      readPermission: 'readTitle',
      writePermission: 'writeTitle'
    }
  ]
};

export const socketProtocol =
  window.location.protocol === 'https:' ? 'wss://' : 'ws://';

export const CABLE_URL = `${socketProtocol}${getSubDomain()}.api.myfusesystems.com/cable`;

export const defaultPermissions = {
  // For Settings Permissions
  readGender: 'read_gender',
  readBenefit: 'read_benefit',
  readGrievance: 'read_grievance',
  readMaritalStatus: 'read_marital_status',
  readFirearmStatus: 'read_firearm_status',
  readCommand: 'read_office',
  readPaf: 'read_paf',
  readPaymentTypes: 'read_payment_type',
  readPosition: 'read_position',
  readMeetingType: 'read_meeting_type',
  readSection: 'read_section',
  readTitle: 'read_title',
  readUnit: 'read_unit',
  readDepartment: 'read_department',
  readDisciplines: 'read_discipline_setting',
  readRank: 'read_rank',
  readOfficerStatus: 'read_officer_status',
  readEmploymentStatus: 'read_employment_status',
  readAnalyticsConfiguration: 'read_analytics_configuration',
  readTourOfDuties: 'read_tour_of_duty',
  readAffiliations: 'read_affiliation',
  readPlatoon: 'read_platoon',
  readDisciplineCharge: 'read_discipline_charge',
  readDisciplineStatus: 'read_discipline_status',
  readGrievanceCharge: 'read_grievance_charge',
  readGrievanceStatus: 'read_grievance_status',
  writeGender: 'write_gender',
  writeBenefit: 'write_benefit',
  writeGrievance: 'write_grievance',
  writeMaritalStatus: 'write_marital_status',
  writeFirearmStatus: 'write_firearm_status',
  writeCommand: 'write_office',
  writePaf: 'write_paf',
  writePaymentTypes: 'write_payment_type',
  writePosition: 'write_position',
  writeMeetingType: 'write_meeting_type',
  writeSection: 'write_section',
  writeTitle: 'write_title',
  writeUnit: 'write_unit',
  writeDepartment: 'write_department',
  writeRank: 'write_rank',
  writeOfficerStatus: 'write_officer_status',
  writeDisciplines: 'write_discipline_setting',
  writeEmploymentStatus: 'write_employment_status',
  writeAnalyticsConfiguration: 'write_analytics_configuration',
  writeTourOfDuties: 'write_tour_of_duty',
  writeAffiliations: 'write_affiliation',
  writePlatoon: 'write_platoon',
  writeDisciplineCharge: 'write_discipline_charge',
  writeDisciplineStatus: 'write_discipline_status',
  writeGrievanceCharge: 'write_grievance_charge',
  writeGrievanceStatus: 'write_grievance_status',
  // For User permissions
  readUser: 'read_user',
  writeUser: 'write_user',
  readUserProfile: 'read_user_profile',
  writeUserProfile: 'write_user_profile',
  readSuperUser: 'read_super_user',
  writeSuperUser: 'write_super_user',
  // For Reports Permissions
  reportSingleMember: 'report_single_employee',
  reportBenefitCoverageExpiration:'report_benefit_coverage_expiration',
  reportSickBank: 'report_sick_bank',
  reportGrievances: 'report_grievances',
  reportDisciplines: 'report_disciplines',
  reportJanus: 'report_janus',
  reportBenefits: 'report_benefits',
  reportLodi: 'report_lodi',
  reportUnionMeetings: 'report_union_meetings',
  reportMemberDelegateAssignment: 'report_employee_delegate_assignment',
  reportBeneficiary:'report_beneficiary',
  // For Reports Demo Permissions
  readReportSingleMember: 'read_report_single_employee',
  readReportSickBank: 'read_report_sick_bank',
  readReportGrievances: 'read_report_grievances',
  readReportDisciplines: 'read_report_disciplines',
  readReportJanus: 'read_report_janus',
  readReportBenefits: 'read_report_benefits',
  readReportLodi: 'read_report_lodi',
  readReportUnionMeetings: 'read_report_union_meetings',
  readReportMemberDelegateAssignment:
    'read_report_employee_delegate_assignment',
  readReportBeneficiary:'read_report_beneficiary',
  // For Employee Permissions
  readEmployeePaf: 'read_employee_paf',
  readEmployeeAnalytics: 'read_employee_analytics',
  readEmployeeAward: 'read_employee_award',
  readEmployeeDiscipline: 'read_employee_discipline_setting',
  readEmployeeMeetingType: 'read_employee_meeting_type',
  readEmployeeFirearmStatus: 'read_employee_firearm_status',
  readEmployeeGrievance: 'read_employee_grievance',
  readEmployeePosition: 'read_employee_position',
  readEmployee: 'read_employee',
  readEmployeeCommand: 'read_employee_office',
  readEmployeeUpload: 'read_employee_upload',
  readEmployeeBenefit: 'read_employee_benefit',
  writeEmployeePaf: 'write_employee_paf',
  writeEmployeeAnalytics: 'write_employee_analytics',
  writeEmployeeAward: 'write_employee_award',
  writeEmployeeDiscipline: 'write_employee_discipline_setting',
  writeEmployeeMeetingType: 'write_employee_meeting_type',
  writeEmployeeFirearmStatus: 'write_employee_firearm_status',
  writeEmployeeGrievance: 'write_employee_grievance',
  writeEmployeePosition: 'write_employee_position',
  writeEmployee: 'write_employee',
  writeEmployeeCommand: 'write_employee_office',
  writeEmployeeUpload: 'write_employee_upload',
  writeEmployeeBenefit: 'write_employee_benefit',
  readLegislativeDetails: 'read_legislative_address',
  readNote: 'read_note',
  writeNote: 'write_note',
  // For Benefits module permissions
  writeBenefitDisbursement: 'write_benefit_disbursement',
  writeBenefitCoverage: 'write_benefit_coverage',
  writeBeneficiary: 'write_beneficiary',
  writeDisability: 'write_disability',
  readBenefitDisbursement: 'read_benefit_disbursement',
  readBenefitCoverage: 'write_benefit_coverage',
  readBeneficiary: 'read_beneficiary',
  readDisability: 'read_disability',
  // For Notification permissions
  readNotification: 'read_notification',
  writeNotification: 'write_notification',
  // For Delegate Series permissions
  readDelegateSeries: 'read_delegate_series',
  writeDelegateSeries: 'write_delegate_series',
  // For lifeInsurance Series permissions
  readLifeInsurances: 'read_life_insurance',
  writeLifeInsurances: 'write_life_insurance',
  // for changeRequest permissions
  readChangeRequest: 'read_change_request',
  writeChangeRequest: 'write_change_request',
  //for report pacfs
  readReportPacfs: 'read_report_pacfs',
  reportPacfs: 'report_pacfs',
  //for report workers_comp
  readReportWorkersComp: 'read_report_workers_comp',
  reportWorkersComp: 'report_workers_comp',
  // for report benefit coverages
  readReportBenefitCoverages: 'read_report_benefit_coverages',
  reportBenefitCoverages: 'report_benefit_coverages',
  //for contact list
  readContactPerson: 'read_contact_person',
  writeContactPerson: 'write_contact_person',
  // for Facilities
  readFacility: 'read_facility',
  writeFacility: 'write_facility',
  // for pesh
  readPesh: 'read_pesh',
  writePesh: 'write_pesh',
  // for assault
  writeAssault: 'write_assault',
  readAssault: 'read_assault',
  //Benefit fund user employee status view
  writeEmployeeEmploymentStatusInEmployeeBenefit: 'write_employee_employment_status_in_employee_benefit',
  //mail log
  readMailLog: 'read_maillog',
  writeMailLog: 'write_maillog',
  // User Audit
  readUserAudit: 'read_user_audit',
  writeUserAudit: 'write_user_audit',
  // member list report
  readReportMemberCount: 'read_report_member_count',
  writeReportMemberCount: 'report_member_count',
  //placard write
  writePlacardOnly: 'write_placard_only'
};

export const settingsTabs = [
  'readGender',
  'readBenefit',
  'readGrievance',
  'readMaritalStatus',
  'readFirearmStatus',
  'readCommand',
  'readPaf',
  'readPaymentTypes',
  'readPosition',
  'readMeetingType',
  'readSection',
  'readTitle',
  'readUnit',
  'readDepartment',
  'readDisciplines',
  'readRank',
  'readOfficerStatus',
  'readEmploymentStatus',
  'readAnalyticsConfiguration',
  'readTourOfDuties',
  'readAffiliations',
  'readBenefitCoverageExpiration',
  'readPlatoon',
  'writeGender',
  'writeBenefit',
  'writeGrievance',
  'writeMaritalStatus',
  'writeFirearmStatus',
  'writeCommand',
  'writePaf',
  'writePaymentTypes',
  'writePosition',
  'writeMeetingType',
  'writeSection',
  'writeTitle',
  'writeUnit',
  'writeDepartment',
  'writeRank',
  'writeOfficerStatus',
  'writeDisciplines',
  'writeEmploymentStatus',
  'writeAnalyticsConfiguration',
  'writeTourOfDuties',
  'writeAffiliations',
  'writePlatoon'
];

export const reportTabs = [
  'reportSingleMember',
  'reportSickBank',
  'reportGrievances',
  'reportDisciplines',
  'reportJanus',
  'reportBenefits',
  'reportBenefitCoverageExpiration',
  'reportBeneficiary',
  'reportLodi',
  'reportUnionMeetings',
  'reportMemberDelegateAssignment',
  'readReportSingleMember',
  'readReportSickBank',
  'readReportGrievances',
  'readReportDisciplines',
  'readReportJanus',
  'readReportBenefits',
  'readReportLodi',
  'readReportUnionMeetings',
  'readReportMemberDelegateAssignment',
  'readReportLifeInsurances'
];

export const userTabs = ['readUser', 'writeUser'];
export const superUserTabs = ['readSuperUser', 'writeSuperUser'];

export const notificationTabs = ['readNotification', 'writeNotification'];
export const changeRequestsTabs = ['readChangeRequest', 'writeChangeRequest'];
export const contactListTabs = ['readContactPerson', 'writeContactPerson'];
export const userAudit = ['readUserAudit', 'writeUserAudit'];

export const mailLog = ['readMailLog', 'writeMailLog'];

export const grievanceListTabs = ['readEmployeeGrievance', 'writeEmployeeGrievance'];
export const memberTabs = [
  'readEmployeePaf',
  'readEmployeeAnalytics',
  'readEmployeeAward',
  'readEmployeeDiscipline',
  'readEmployeeMeetingType',
  'readEmployeeFirearmStatus',
  'readEmployeeGrievance',
  'readEmployeePosition',
  'readEmployee',
  'readEmployeeCommand',
  'readEmployeeBenefit',
  'writeEmployeePaf',
  'writeEmployeeAnalytics',
  'writeEmployeeAward',
  'writeEmployeeDiscipline',
  'writeEmployeeMeetingType',
  'writeEmployeeFirearmStatus',
  'writeEmployeeGrievance',
  'writeEmployeePosition',
  'writeEmployee',
  'writeEmployeeCommand',
  'writeEmployeeUpload',
  'writeEmployeeBenefit'
];

import asyncComponent from '../shared/asyncFunction';
import { convertIntoHypenValues, getModuleDetails } from '../shared/helper';

export function getGrievanceRoutePath() {
  return [
    {
      path: convertIntoHypenValues(getModuleDetails('ui', 'employee_grievances')),
      component: asyncComponent(() => import('../containers/grievance'))
    }
  ];
}
import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import {
  unAuthorisedRedirection,
  showErrorMessages,
  postPromise,
  getPromise,
} from '../../shared/helper';
import settings from '../../settings';
import { message, Modal } from 'antd';
import { store } from '../store';

export function* notificationCreate(params) {
  const { saasData } = store.getState().Auth,
    { ui } = saasData;
  try {
    const url = `${settings.ROOT_URL}notifications`;
    yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.NOTIFICATION_CREATE_SUCCESS,
    });
    message.success(`${ui['notification']['key_name']} created successfully`);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.NOTIFICATION_CREATE_FAILURE,
    });
    showErrorMessages(error);
  }
}

export function* downloadExcelTrackerFile(params) {
  try {
    const url = `${settings.ROOT_URL}reports`;
    yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.NOTIFICATION_CREATE_SUCCESS,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.NOTIFICATION_CREATE_FAILURE,
    });
    showErrorMessages(error);
  }
}

export function* fetchNotificationDetails(params) {
  const { page, perPage, startDate, endDate } = params;
  try {
    const url = `${settings.ROOT_URL}notifications?page=${page}${
      perPage && perPage !== '' ? `&per_page=${perPage}` : ''
    }&from_date=${startDate ? startDate : ''}&to_date=${
      endDate ? endDate : ''
    }`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_NOTIFICATION_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_NOTIFICATION_DETAILS_FAILURE,
    });
    showErrorMessages(error);
  }
}

export function* fetchNotificationTrackersDetails(params) {
  const { page, perPage, id, filterStatus } = params,
    { status, type, searchText } = filterStatus;
  try {
    const url = `${
      settings.ROOT_URL
    }notification_trackers?notification_id=${id}&page=${page}${
      perPage && perPage !== '' ? `&per_page=${perPage}` : ''
    }&type=${type}&status=${status}&search_text=${searchText}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_NOTIFICATION_TRACKERS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_NOTIFICATION_TRACKERS_LIST_FAILURE,
    });
    showErrorMessages(error);
  }
}

export function* fetchNotificationReceiverCount(params) {
  try {
    const url = `${settings.ROOT_URL}notification_receiver_count`,
      data = yield call(() => postPromise(url, params.payload));
    Modal.confirm({
      title: data.message,
      centered: true,
      onOk() {
        store.dispatch({
          type: actions['NOTIFICATION_CREATE'],
          payload: params.payload,
        });
      },
      onCancel() {},
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.NOTIFICATION_CREATE, notificationCreate),
    takeEvery(actions.FETCH_NOTIFICATION_DETAILS, fetchNotificationDetails),
    takeEvery(
      actions.FETCH_NOTIFICATION_TRACKERS_LIST,
      fetchNotificationTrackersDetails,
    ),
    takeEvery(actions.DOWNLOAD_EXCEL_TRACKER_FILE, downloadExcelTrackerFile),
    takeEvery(
      actions.FETCH_NOTIFICATION_RECEIVER_COUNT, fetchNotificationReceiverCount
    )
  ]);
}

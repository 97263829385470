import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import {
  getPromise,
  unAuthorisedRedirection,
  showErrorMessages, patchPromise, deletePromise, postPromise
} from '../../shared/helper';
import { store } from '../store';
import { message } from 'antd';

export function* getGrievanceDetails(params) {
  try {
    const searchNumberValue = params?.searchNumberValue
      ? params.searchNumberValue : store.getState().Grievance.employeeGrievances.grievances.searchNumberValue;
    const url = `${settings.ROOT_URL}employee_grievances?employee_id=null&${
      searchNumberValue ? `search_number=${searchNumberValue}&` : ''}page=${params.page}`;
    const response = yield call(() => getPromise(url));
    yield put({
      type: actions['FETCH_GRIEVANCE_DETAILS_SUCCESS'],
      payload: response
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions.FETCH_GRIEVANCE_DETAILS_FAILURE
    });
  }
}
export function* createGrievanceDetails(params) {
  try {
    const url = `${settings.ROOT_URL}employee_grievances`;
    const response = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions['CREATE_GRIEVANCE_DETAILS_SUCCESS'],
      payload: response
    });
    store.dispatch({
      type: actions['FETCH_GRIEVANCE_DETAILS'],
      payload: true,
      page: 1
    });
    message.success('Grievance created successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions.CREATE_GRIEVANCE_DETAILS_FAILURE
    });
  }
}
export function* updateGrievanceDetails(params) {
  try {
    const url = `${settings.ROOT_URL}/employee_grievances/${params.id}`;
    const response = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions['UPDATE_GRIEVANCE_DETAILS_SUCCESS'],
      payload: response
    });
    store.dispatch({
      type: actions['FETCH_GRIEVANCE_DETAILS'],
      payload: true,
      page: 1
    });
    message.success('Grievance updated successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions.UPDATE_GRIEVANCE_DETAILS_FAILURE
    });
  }
}

export function* createGrievanceStepDetails(params) {
  try {
    const url = `${settings.ROOT_URL}employee_grievance_steps`;
    const response = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions['CREATE_GRIEVANCE_DETAILS_SUCCESS'],
      payload: response
    });
    store.dispatch({
      type: actions['FETCH_GRIEVANCE_DETAILS'],
      payload: true,
      page: 1
    });
    message.success('Grievance created successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions.CREATE_GRIEVANCE_DETAILS_FAILURE
    });
  }
}
export function* updateGrievanceStepDetails(params) {
  try {
    const url = `${settings.ROOT_URL}/employee_grievance_steps/${params.slug}`;
    const response = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions['UPDATE_GRIEVANCE_DETAILS_SUCCESS'],
      payload: response
    });
    store.dispatch({
      type: actions['FETCH_GRIEVANCE_DETAILS'],
      payload: true,
      page: 1
    });
    message.success('Grievance updated successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions.UPDATE_GRIEVANCE_DETAILS_FAILURE
    });
  }
}
export function* deleteGrievanceDetails(params) {
  try {
    const url = `${settings.ROOT_URL}employee_grievances/${params.id}`;
    yield call(() => deletePromise(url));
    yield put({
      type: actions['DELETE_GRIEVANCE_DETAILS_SUCCESS']
    });
    store.dispatch({
      type: actions['FETCH_GRIEVANCE_DETAILS'],
      payload: true,
      page: 1
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions.DELETE_GRIEVANCE_DETAILS_FAILURE
    });
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_GRIEVANCE_DETAILS, getGrievanceDetails),
    takeEvery(actions.CREATE_GRIEVANCE_DETAILS, createGrievanceDetails),
    takeEvery(actions.UPDATE_GRIEVANCE_DETAILS, updateGrievanceDetails),
    takeEvery(actions.CREATE_GRIEVANCE_STEP_DETAILS, createGrievanceStepDetails),
    takeEvery(actions.UPDATE_GRIEVANCE_STEP_DETAILS, updateGrievanceStepDetails),
    takeEvery(actions.DELETE_GRIEVANCE_DETAILS, deleteGrievanceDetails)
  ]);
}

import actions from './actions';
import { cloneDeep, findIndex, remove } from 'lodash';
import {
  statusComponentActionNames,
  memberListActionNames
} from '../../components/memberList/constant';

const statusCommonValues = {
  loader: false,
  statusLists: [],
  statusDetails: undefined,
  deleteLoader: false,
  editData: undefined
};

const commonValues = {
  loader: false,
  tableLists: [],
  tableDetails: undefined,
  searchValue: undefined,
  modalLoader: false,
  modalVisible: false,
  deleteLoader: false,
  editData: undefined,
  searchCaseNoValue:undefined,
  searchStatusValue: undefined,
  searchNumberValue: undefined,
  searchSsnValue: undefined,
  searchDob: undefined,
  formValues: [],
  selectedStatuses: false
};

const initState = {
  memberDetails: {
    memberList: commonValues,
    analyticsRights: false,
    memberListCount: {},
    profile: {
      loader: false,
      basicProfileDetails: {
        editData: undefined,
        editLoader: false,
        titleCodeLoader: undefined,
        updatedNameDetails: undefined,
        isNewNotesActive: false
      },
      others: {
        employeeEmploymentStatuses: statusCommonValues,
        employeeOfficerStatuses: statusCommonValues,
        employeeOffices: statusCommonValues,
        delegateAssignments: statusCommonValues,
        employeePositions: statusCommonValues,
        employeeRanks: statusCommonValues,
        employeeDepartments: statusCommonValues,
        employeeTitles: statusCommonValues,
        employeeSections: statusCommonValues,
        employeeFacilities: statusCommonValues,
        modalVisible: {
          employeeEmploymentStatuses: false,
          employeeOfficerStatuses: false,
          employeeOffices: false,
          delegateAssignments: false,
          employeePositions: false,
          employeeRanks: false,
          employeeDepartments: false,
          employeeTitles: false,
          employeeSections: false,
          edit: false
        }
      },
      contactInformationDetails: undefined,
      notesSection: {
        editData: undefined,
        editLoader: false,
        isNewNotesActive: false
      }
    },
    firearmStatuses: commonValues,
    firearmRangeScores: commonValues,
    pacfs: commonValues,
    awards: commonValues,
    meetingTypes: commonValues,
    benefits: commonValues,
    beneficiaries: commonValues,
    benefitCoverages: commonValues,
    benefitDisbursements: commonValues,
    disabilities: commonValues,
    uploads: commonValues,
    disciplineSettings: commonValues,
    disciplineSteps: commonValues,
    grievances: commonValues,
    grievanceSteps: commonValues,
    compTime: commonValues,
    overTime: commonValues,
    personal: commonValues,
    sickBank: commonValues,
    vacation: commonValues,
    workersComp: commonValues,
    ava: commonValues,
    employeeLodis: commonValues,
    assaults: commonValues,
    lifeInsurance: commonValues,
    dependent: commonValues,
    lodisTab: commonValues,
    employees: commonValues,
    benefitCoverageStatuses: [],
    analyticsConfigurationDetails: {
      viewAnalyticsDetails: false
    },
    legislation: {
      loader: false,
      legislativeDetails: {}
    },
    peshes: commonValues,
    benefitsNotes: {
      isNewNotesActive: false,
      actionButtonVisible: false,
      notesLoader: false,
      isEditNotesActive: false,
      editNotes: {},
      editNotesId: null,
      singleNotes: undefined,
      multiNotes: [],
      benefitsNotesId: null
    },
    benefitsNotesData: [],
    grievanceAutoGeneration: {
      loader: false,
      data: undefined
    }
  }
};

export default function memberListReducer(state = initState, action) {
  const { keyName } = action.keyValues ? action.keyValues : '';

  switch (action.type) {
    case actions['NOTES_SECTION_GET_DETAILS_SUCCESS']: {
      const { data } = action.payload;
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            notesSection: {
              ...state.memberDetails.profile.notesSection,
              editData: data
            }
          }
        }
      };
    }

    case actions['MEMBER_LIST_CREATE']:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          memberList: {
            ...state.memberDetails.memberList,
            loader: true
          }
        }
      };

    case actions['MEMBER_LIST_CREATE_SUCCESS']:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          memberList: {
            ...state.memberDetails.memberList,
            loader: false
          }
        }
      };

    case actions['MEMBER_LIST_CREATE_FAILURE']:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          memberList: {
            ...state.memberDetails.memberList,
            loader: false
          }
        }
      };

    case actions['MEMBER_LIST_UPDATE']:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            basicProfileDetails: {
              ...state.memberDetails.profile.basicProfileDetails,
              loader: true,
              editLoader: true
            }
          }
        }
      };

    case actions['MEMBER_LIST_UPDATE_SUCCESS']:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            basicProfileDetails: {
              ...state.memberDetails.profile.basicProfileDetails,
              loader: false,
              editLoader: false
            }
          }
        }
      };

    case actions['MEMBER_LIST_UPDATE_FAILURE']:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            basicProfileDetails: {
              ...state.memberDetails.profile.basicProfileDetails,
              loader: false,
              editLoader: false
            }
          }
        }
      };

    case actions['NOTES_SECTION_UPDATE']:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            notesSection: {
              ...state.memberDetails.profile.notesSection,
              loader: true,
              editLoader: true
            }
          }
        }
      };

    case actions['NOTES_SECTION_UPDATE_SUCCESS']:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            notesSection: {
              ...state.memberDetails.profile.notesSection,
              editData: action.payload.data
            }
          }
        }
      };

    case actions['NOTES_SECTION_UPDATE_FAILURE']:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            notesSection: {
              ...state.memberDetails.profile.notesSection,
              loader: false,
              editLoader: false
            }
          }
        }
      };

    case actions['MEMBER_LIST_DELETE_DATA_IN_TABLE']:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          memberList: {
            ...state.memberDetails.memberList,
            loader: true
          }
        }
      };

    case actions['MEMBER_LIST_DELETE_DATA_IN_TABLE_SUCCESS']:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          memberList: {
            ...state.memberDetails.memberList,
            loader: false
          }
        }
      };

    case actions['MEMBER_LIST_DELETE_DATA_IN_TABLE_FAILURE']:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          memberList: {
            ...state.memberDetails.memberList,
            loader: false
          }
        }
      };

    case actions[`${memberListActionNames[keyName]}_FETCH_TABLE_DETAILS`]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          [`${keyName === 'analyticsConfiguration' ? action?.analyticType?.keyName : keyName}`]: {
            ...state.memberDetails[`${keyName === 'analyticsConfiguration' ? action?.analyticType?.keyName : keyName}`],
            loader: true
          }
        }
      };

    case actions[
      `${memberListActionNames[keyName]}_FETCH_TABLE_DETAILS_SUCCESS`
    ]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          [`${keyName}`]: {
            ...state.memberDetails[`${keyName}`],
            loader: false,
            tableLists: action.payload.data,
            tableDetails: action.payload.meta,
            tableIncluded: action.payload.included
              ? action.payload.included
              : '',
            analyticsTotal: action.payload?.analytics_total || action?.payload?.analytics_configuration_total
          }
        }
      };

    case actions[
      `${memberListActionNames[keyName]}_FETCH_TABLE_DETAILS_FAILURE`
    ]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          [`${keyName}`]: {
            ...state.memberDetails[`${keyName}`],
            loader: false
          }
        }
      };

    case actions[`${memberListActionNames[keyName]}_TAB_DETAILS`]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          [`${keyName}`]: {
            ...state.memberDetails[`${keyName}`],
            modalVisible: [false, true].includes(action.modalVisible)
              ? action.modalVisible
              : state[`${keyName}`].modalVisible,
            editData: action.editData ? action.editData : undefined
          }
        }
      };

    case actions[`${memberListActionNames[keyName]}_CREATE`]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          [`${keyName}`]: {
            ...state.memberDetails[`${keyName}`],
            modalLoader: true
          }
        }
      };

    case actions[`${memberListActionNames[keyName]}_CREATE_SUCCESS`]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          [`${keyName}`]: {
            ...state.memberDetails[`${keyName}`],
            modalLoader: false,
            editData: action.payload,
            modalVisible: action.modalVisible
          }
        }
      };

    case actions[`${memberListActionNames[keyName]}_CREATE_FAILURE`]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          [`${keyName}`]: {
            ...state.memberDetails[`${keyName}`],
            modalLoader: false,
            editData: undefined
          }
        }
      };

    case actions[`${memberListActionNames[keyName]}_UPDATE`]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          [`${keyName}`]: {
            ...state.memberDetails[`${keyName}`],
            modalLoader: true
          }
        }
      };

    case actions[`${memberListActionNames[keyName]}_UPDATE_SUCCESS`]:
      let tableLists = cloneDeep(state.memberDetails[`${keyName}`].tableLists);
      let positionOfIndex = findIndex(tableLists, function(object) {
        return object.attributes.id === action.payload.attributes.id;
      });
      tableLists.splice(positionOfIndex, 1, action.payload);
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          [`${keyName}`]: {
            ...state.memberDetails[`${keyName}`],
            editData: action.payload,
            modalLoader: false,
            tableLists,
            modalVisible: action.modalVisible
          }
        }
      };

    case actions[`${memberListActionNames[keyName]}_UPDATE_FAILURE`]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          [`${keyName}`]: {
            ...state.memberDetails[`${keyName}`],
            modalLoader: false
          }
        }
      };

    case actions[`${memberListActionNames[keyName]}_DELETE_DATA_IN_TABLE`]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          [`${keyName}`]: {
            ...state.memberDetails[`${keyName}`],
            deleteLoader: true
          }
        }
      };

    case actions[
      `${memberListActionNames[keyName]}_DELETE_DATA_IN_TABLE_SUCCESS`
    ]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          [`${keyName}`]: {
            ...state.memberDetails[`${keyName}`],
            deleteLoader: false,
            tableLists: remove(
              cloneDeep(state.memberDetails[`${keyName}`].tableLists),
              function(object) {
                return object.attributes.id !== action.slug;
              },
            )
          }
        }
      };

    case actions[
      `${memberListActionNames[keyName]}_DELETE_DATA_IN_TABLE_FAILURE`
    ]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          [`${keyName}`]: {
            ...state.memberDetails[`${keyName}`],
            deleteLoader: false
          }
        }
      };

    case actions[`${memberListActionNames[keyName]}_GET_DETAILS`]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            loader: true,
            basicProfileDetails: {
              ...state.memberDetails.profile.basicProfileDetails
            },
            contactInformationDetails: undefined
          }
        }
      };

    case actions[`${memberListActionNames[keyName]}_GET_DETAILS_SUCCESS`]:
      const { data, included, analytics_rights } = action.payload;
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            loader: false,
            basicProfileDetails: {
              ...state.memberDetails.profile.basicProfileDetails,
              editData: data
            },
            notesSection: {
              ...state.memberDetails.profile.notesSection,
              editData: data
            },
            contactInformationDetails: included.filter(
              data => data.type === 'contact',
            ),
            profileOptionValues: included.filter(
              data => data.type !== 'contact',
            )
          },
          analyticsRights: analytics_rights
        }
      };

    case actions[`${memberListActionNames[keyName]}_GET_DETAILS_FAILURE`]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            loader: false,
            basicProfileDetails: {
              ...state.memberDetails.profile.basicProfileDetails
            }
          }
        }
      };

    case actions.SET_SEARCH_VALUE_IN_MEMBER:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          memberList: {
            ...state.memberDetails.memberList,
            searchValue: action.searchValue
          }
        }
      };

    case actions.SET_SEARCH_CASE_NUMBER_IN_MEMBER:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          memberList: {
            ...state.memberDetails.memberList,
            searchCaseNoValue: action.searchCaseNoValue
          }
        }
      };

    case actions.SET_SELECTED_STATUS_IN_MEMBER:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          memberList: {
            ...state.memberDetails.memberList,
            selectedStatuses: action.selectedStatuses
          }
        }
      };

    case actions.SET_SEARCH_SSN_IN_MEMBER:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          memberList: {
            ...state.memberDetails.memberList,
            searchSsnValue: action.searchSsnValue
          }
        }
      };
    case actions.SET_SEARCH_DOB_IN_MEMBER:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          memberList: {
            ...state.memberDetails.memberList,
            searchDob: action.searchDob
          }
        }
      };
    case actions.SET_SEARCH_NUMBER_IN_MEMBER:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          memberList: {
            ...state.memberDetails.memberList,
            searchNumberValue: action.searchNumberValue
          }
        }
      };

    case actions.SET_DISCIPLINE_STATUS_SEARCH_VALUE:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          memberList: {
            ...state.memberDetails.disciplineSettings,
            searchStatusValue: action.searchStatusValue
          }
        }
      };

    case actions.CLEAR_EDIT_MEMBER_DETAILS:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            basicProfileDetails:
              initState.memberDetails.profile.basicProfileDetails,
            contactInformationDetails:
              initState.memberDetails.profile.contactInformationDetails
          }
        }
      };

    case actions.SET_PROFILE_OTHERS_MODAL_VISIBLE:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            others: {
              ...state.memberDetails.profile.others,
              modalVisible: {
                ...state.memberDetails.profile.others.modalVisible,
                employeeEmploymentStatuses: [true, false].includes(
                  action.employeeEmploymentStatuses,
                )
                  ? action.employeeEmploymentStatuses
                  : state.memberDetails.profile.others.modalVisible
                    .employeeEmploymentStatuses,
                employeeOfficerStatuses: [true, false].includes(
                  action.employeeOfficerStatuses,
                )
                  ? action.employeeOfficerStatuses
                  : state.memberDetails.profile.others.modalVisible
                    .employeeOfficerStatuses,
                employeeOffices: [true, false].includes(action.employeeOffices)
                  ? action.employeeOffices
                  : state.memberDetails.profile.others.modalVisible
                    .employeeOffices,
                delegateAssignments: [true, false].includes(
                  action.delegateAssignments,
                )
                  ? action.delegateAssignments
                  : state.memberDetails.profile.others.modalVisible
                    .delegateAssignments,
                employeePositions: [true, false].includes(
                  action.employeePositions,
                )
                  ? action.employeePositions
                  : state.memberDetails.profile.others.modalVisible
                    .employeePositions,
                employeeRanks: [true, false].includes(action.employeeRanks)
                  ? action.employeeRanks
                  : state.memberDetails.profile.others.modalVisible
                    .employeeRanks,
                employeeTitles: [true, false].includes(action.employeeTitles)
                  ? action.employeeTitles
                  : state.memberDetails.profile.others.modalVisible
                    .employeeTitles,
                employeeFacilities: [true, false].includes(action.employeeFacilities)
                  ? action.employeeFacilities
                  : state.memberDetails.profile.others.modalVisible
                    .employeeFacilities,
                employeeDepartments: [true, false].includes(
                  action.employeeDepartments,
                )
                  ? action.employeeDepartments
                  : state.memberDetails.profile.others.modalVisible
                    .employeeDepartments,
                employeeSections: [true, false].includes(
                  action.employeeSections,
                )
                  ? action.employeeSections
                  : state.memberDetails.profile.others.modalVisible
                    .employeeSections,
                edit: [true, false].includes(action.edit)
                  ? action.edit
                  : state.memberDetails.profile.others.modalVisible.edit
              }
            }
          }
        }
      };

    case actions[`${statusComponentActionNames[keyName]}_FETCH_DETAILS`]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            others: {
              ...state.memberDetails.profile.others,
              [`${keyName}`]: {
                ...state.memberDetails.profile.others[`${keyName}`],
                loader: true
              }
            }
          }
        }
      };

    case actions[
      `${statusComponentActionNames[keyName]}_FETCH_DETAILS_SUCCESS`
    ]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            others: {
              ...state.memberDetails.profile.others,
              [`${keyName}`]: {
                ...state.memberDetails.profile.others[`${keyName}`],
                loader: false,
                statusLists: action.payload.data,
                statusDetails: action.payload.meta
              }
            }
          }
        }
      };

    case actions[
      `${statusComponentActionNames[keyName]}_FETCH_DETAILS_FAILURE`
    ]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            others: {
              ...state.memberDetails.profile.others,
              [`${keyName}`]: {
                ...state.memberDetails.profile.others[`${keyName}`],
                loader: false
              }
            }
          }
        }
      };

    case actions[`${statusComponentActionNames[keyName]}_CREATE`]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            others: {
              ...state.memberDetails.profile.others,
              [`${keyName}`]: {
                ...state.memberDetails.profile.others[`${keyName}`],
                loader: true
              }
            }
          }
        }
      };

    case actions[`${statusComponentActionNames[keyName]}_CREATE_SUCCESS`]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            others: {
              ...state.memberDetails.profile.others,
              [`${keyName}`]: {
                ...state.memberDetails.profile.others[`${keyName}`],
                loader: false,
                editData: action.payload.attributes
              }
            }
          }
        }
      };

    case actions[`${statusComponentActionNames[keyName]}_CREATE_FAILURE`]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            others: {
              ...state.memberDetails.profile.others,
              [`${keyName}`]: {
                ...state.memberDetails.profile.others[`${keyName}`],
                loader: false
              }
            }
          }
        }
      };

    case actions[`${statusComponentActionNames[keyName]}_UPDATE`]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            others: {
              ...state.memberDetails.profile.others,
              [`${keyName}`]: {
                ...state.memberDetails.profile.others[`${keyName}`],
                loader: true
              }
            }
          }
        }
      };

    case actions[`${statusComponentActionNames[keyName]}_UPDATE_SUCCESS`]:
      let statusLists = cloneDeep(
        state.memberDetails.profile.others[`${keyName}`].statusLists,
      );
      let indexPosition = findIndex(statusLists, function(object) {
        return object.attributes.id === action.slug;
      });
      statusLists.splice(indexPosition, 1, action.payload);
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            others: {
              ...state.memberDetails.profile.others,
              [`${keyName}`]: {
                ...state.memberDetails.profile.others[`${keyName}`],
                statusLists,
                loader: false,
                editData: action.payload.attributes
              }
            }
          }
        }
      };

    case actions[`${statusComponentActionNames[keyName]}_UPDATE_FAILURE`]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            others: {
              ...state.memberDetails.profile.others,
              [`${keyName}`]: {
                ...state.memberDetails.profile.others[`${keyName}`],
                loader: false,
                editData: undefined
              }
            }
          }
        }
      };

    case actions[`${statusComponentActionNames[keyName]}_DELETE_DETAILS`]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            others: {
              ...state.memberDetails.profile.others,
              [`${keyName}`]: {
                ...state.memberDetails.profile.others[`${keyName}`],
                deleteLoader: true
              }
            }
          }
        }
      };

    case actions[
      `${statusComponentActionNames[keyName]}_DELETE_DETAILS_SUCCESS`
    ]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            others: {
              ...state.memberDetails.profile.others,
              [`${keyName}`]: {
                ...state.memberDetails.profile.others[`${keyName}`],
                statusLists: remove(
                  cloneDeep(
                    state.memberDetails.profile.others[`${keyName}`]
                      .statusLists,
                  ),
                  function(object) {
                    return object.attributes.id !== action.slug;
                  },
                ),
                deleteLoader: false
              }
            }
          }
        }
      };

    case actions[
      `${statusComponentActionNames[keyName]}_DELETE_DETAILS_FAILURE`
    ]:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            others: {
              ...state.memberDetails.profile.others,
              [`${keyName}`]: {
                ...state.memberDetails.profile.others[`${keyName}`],
                deleteLoader: false
              }
            }
          }
        }
      };

    case actions.SET_PROFILE_OTHERS_STATUS_EDIT_DATA:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            others: {
              ...state.memberDetails.profile.others,
              [`${keyName}`]: {
                ...state.memberDetails.profile.others[`${keyName}`],
                editData: action.editData
              }
            }
          }
        }
      };

    case actions.CLEAR_STATUS_EDIT_DATA:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            others: {
              ...state.memberDetails.profile.others,
              [`${keyName}`]: {
                ...state.memberDetails.profile.others[`${keyName}`],
                editData: undefined
              }
            }
          }
        }
      };

    case actions.SET_BENEFIT_DETAILS_TO_VIEW:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          benefits: {
            ...state.memberDetails.benefits,
            viewBenefitDetails: [true, false].includes(action.isView)
              ? action.isView
              : state.memberDetails.benefits,
            employeeBenefitId: action.employeeBenefitId,
            editData: action.editData
          }
        }
      };

    case actions.SET_BENEFICIARIES_PERCENTAGE_FOR_VALIDATION:
      const { primaryTotalPercent, secondaryTotalPercent, contingentTotalPercent } = action;
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          benefits: {
            ...state.memberDetails.benefits,
            primaryTotalPercent,
            secondaryTotalPercent,
            contingentTotalPercent
          }
        }
      };

    case actions.CLEAR_MEMBER_LIST_DETAILS:
      return initState;

    case actions.TITLE_CODE_BASED_CREATION:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            basicProfileDetails: {
              ...state.memberDetails.profile.basicProfileDetails,
              titleCodeLoader: undefined
            }
          }
        }
      };

    case actions.TITLE_CODE_BASED_CREATION_SUCCESS:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            basicProfileDetails: {
              ...state.memberDetails.profile.basicProfileDetails,
              titleCodeLoader: true
            }
          }
        }
      };

    case actions.TITLE_CODE_BASED_CREATION_FAILURE:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            basicProfileDetails: {
              ...state.memberDetails.profile.basicProfileDetails,
              titleCodeLoader: false
            }
          }
        }
      };
    case actions.SET_LIFE_INSURANCE_DETAILS_TO_VIEW:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          lifeInsurance: {
            ...state.memberDetails.lifeInsurance,
            viewInsurancesDetails: [true, false].includes(action.isView)
              ? action.isView
              : state.memberDetails.benefits,
            employeeBenefitId: action.employeeBenefitId,
            selectedData: action.editData,
            editData: action.editData
          }
        }
      };
    case actions.SET_ANALYTICS_CONFIGURATION_DETAILS_TO_VIEW:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          [`${action.analyticsType}`]: {
            ...state.memberDetails[`${action.analyticsType}`],
            loader: false,
            tableLists: [],
            tableDetails: []
          },
          analyticsConfigurationDetails: {
            ...state.memberDetails.analyticsConfigurationDetails,
            viewAnalyticsDetails: action.isView,
            analyticsType: action.isView ? action.analyticsType : '',
            selectedData: action.editData
          }
        }
      };
    case actions.MEMBER_DETAILS_PROFILE_UPDATE_SUCCESS:
      const { payload } = action,
        { attributes } = payload;
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            basicProfileDetails: {
              ...state.memberDetails.profile.basicProfileDetails,
              editData: {
                ...state.memberDetails.profile.basicProfileDetails.editData,
                attributes
              }
            }
          }
        }
      };
    case actions.SET_EMPLOYMENT_STATUS_FILTER_VALUE:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          memberList: {
            ...state.memberDetails.memberList,
            employmentStatusValue: action.employmentStatusValue
          }
        }
      };
    case actions.GET_ANALYTIC_CONFIGURATION_DATA_IN_TABLE_SUCCESS:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          analyticsConfigurationDetails: {
            ...state.memberDetails.analyticsConfigurationDetails,
            selectedData: action.payload[0]
          }
        }
      };
    case actions.SEND_LOGIN_INFORMATION_SUCCESS:
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            loader: false,
            basicProfileDetails: {
              ...state.memberDetails.profile.basicProfileDetails,
              editData: {
                ...state.memberDetails.profile.basicProfileDetails.editData,
                attributes: action.payload
              }
            }
          }
        }
      };
    case actions.FETCH_BENEFIT_COVERAGES_STATUSES: {
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          benefitCoverageStatuses: []
        }
      }
    }
    case actions.FETCH_BENEFIT_COVERAGES_STATUSES_SUCCESS: {
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          benefitCoverageStatuses: action.payload
        }
      }
    }
    case actions.FETCH_MEMBER_LIST_COUNT_SUCCESS: {
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          memberListCount: action.payload
        }
      }
    }
    case actions.SET_NOTES_ACTIVE: {
      return {
        memberDetails: {
          ...state.memberDetails,
          profile: {
            ...state.memberDetails.profile,
            loader: false,
            basicProfileDetails: {
              ...state.memberDetails.profile.basicProfileDetails,
              isNewNotesActive: action.payload
            }
          }
        }
      }
    }
    case actions.FETCH_LEGISLATIVE_DETAILS: {
      return {
        memberDetails: {
          ...state.memberDetails,
          legislation: {
            ...state.legislation,
            loader: true
          }
        }
      }
    }
    case actions.FETCH_LEGISLATIVE_DETAILS_SUCCESS: {
      return {
        memberDetails: {
          ...state.memberDetails,
          legislation: {
            ...state.legislation,
            loader: false,
            legislativeDetails: action.payload
          }
        }
      }
    }
    case actions.FETCH_LEGISLATIVE_DETAILS_FAILURE: {
      return {
        memberDetails: {
          ...state.memberDetails,
          legislation: {
            ...state.legislation,
            loader: false
          }
        }
      }
    }
    case actions.ADD_BENEFITS_TAB_NOTES_SUCCESS: {
      if(action.isSingleNotes) {
        return {
          memberDetails: {
            ...state.memberDetails,
            benefitsNotesData: state.memberDetails.benefitsNotesData,
            benefitsNotes: {
              ...state.memberDetails.benefitsNotes,
              singleNotes: action.payload?.attributes?.notes,
              benefitsNotesId: action.payload?.id
            }
          }
        }
      }
      return {
        memberDetails: {
          ...state.memberDetails,
          benefitsNotesData: action.payload
        }
      }
    }

    case actions.UPDATE_BENEFITS_TAB_SINGLE_NOTES_SUCCESS: {
      if(action.isSingleNotes) {
        return {
          memberDetails: {
            ...state.memberDetails,
            benefitsNotesData: state.memberDetails.benefitsNotesData,
            benefitsNotes: {
              ...state.memberDetails.benefitsNotes,
              singleNotes: action.payload?.attributes?.notes
            }
          }
        }
      }
      return {
        memberDetails: {
          ...state.memberDetails,
          benefitsNotesData: action.payload
        }
      }
    }

    case actions.ADD_NEW_BENEFIT_NOTES: {
      return {
        memberDetails: {
          ...state.memberDetails,
          benefitsNotes: {
            ...state.memberDetails.benefitsNotes,
            ...action.payload
          }
        }
      }
    }
    case actions.FETCH_BENEFITS_TAB_NOTES_SUCCESS: {
      return {
        memberDetails: {
          ...state.memberDetails,
          benefitsNotesData: !action.isSingleNotes ? action.payload :
            state.memberDetails.benefitsNotesData,
          benefitsNotes: {
            ...state.memberDetails.benefitsNotes,
            isNewNotesActive: false,
            actionButtonVisible: false,
            notesLoader: false,
            isEditNotesActive: false,
            editNotesId: null,
            multiNotes: action.payload
          }
        }
      }
    }
    case actions.FETCH_BENEFITS_SINGLE_TAB_NOTES_SUCCESS: {
      return {
        memberDetails: {
          ...state.memberDetails,
          benefitsNotesData: !action.isSingleNotes ? action.payload :
            state.memberDetails.benefitsNotesData,
          benefitsNotes: {
            ...state.memberDetails.benefitsNotes,
            isNewNotesActive: false,
            actionButtonVisible: false,
            notesLoader: false,
            isEditNotesActive: false,
            editNotesId: null,
            singleNotes: action.payload
          }
        }
      }
    }
    case actions.GRIEVANCE_AUTO_GENERATION: {
      return {
        memberDetails: {
          ...state.memberDetails,
          grievanceAutoGeneration: {
            ...state.memberDetails.grievanceAutoGeneration,
            loader: true
          }
        }
      }
    }
    case actions.GRIEVANCE_AUTO_GENERATION_SUCCESS: {
      return {
        memberDetails: {
          ...state.memberDetails,
          grievanceAutoGeneration: {
            ...state.memberDetails.grievanceAutoGeneration,
            loader: false,
            data: action.payload
          }
        }
      }
    }
    case actions.GRIEVANCE_AUTO_GENERATION_FAILURE: {
      return {
        memberDetails: {
          ...state.memberDetails,
          grievanceAutoGeneration: {
            ...state.memberDetails.grievanceAutoGeneration,
            loader: false
          }
        }
      }
    }
    case actions.SET_ANALYTICS_FORM_VALUE: {
      if(state.memberDetails.assaults.formValues.length) {
        const index = state.memberDetails.assaults.formValues.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.memberDetails.assaults.formValues[index] = action.payload
          return {
            memberDetails: {
              ...state.memberDetails,
              assaults: {
                ...state.memberDetails.assaults,
                formValues: [...state.memberDetails.assaults.formValues]
              }
            }
          }
        } else {
          return {
            memberDetails: {
              ...state.memberDetails,
              assaults: {
                ...state.memberDetails.assaults,
                formValues: [...state.memberDetails.assaults.formValues, action.payload]
              }
            }
          }
        }
      } else {
        return {
          memberDetails: {
            ...state.memberDetails,
            assaults: {
              ...state.memberDetails.assaults,
              formValues: [action.payload]
            }
          }
        }
      }
    }

    case actions.EDIT_ANALYTICS_FORM_VALUE: {
      const result = state.memberDetails.assaults.formValues.map(entryB => {
        const entryA = action.payload.find(entry => entry.id === entryB.id);
        return entryA ? entryA : entryB;
      });
      return {
        memberDetails: {
          ...state.memberDetails,
          assaults: {
            ...state.memberDetails.assaults,
            formValues: result
          }
        }
      }
    }
    case actions.ADD_NEW_ASSAULT_LIST: {
      return {
        memberDetails: {
          ...state.memberDetails,
          assaults: {
            ...state.memberDetails.assaults,
            formValues: []
          }
        }
      }
    }

    case actions.DELETE_EMPLOYESS_CHARGE: {
      return {
        ...state,
        memberDetails: {
          ...state.memberDetails,
          [`${keyName}`]: {
            ...state.memberDetails[`${keyName}`]
          }
        }
      };
    }

    default:
      return state;
  }
}

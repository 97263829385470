const actions = {
  FETCH_CHANGE_REQUESTS_DETAILS: 'FETCH_CHANGE_REQUESTS_DETAILS',
  FETCH_CHANGE_REQUESTS_DETAILS_SUCCESS: 'FETCH_CHANGE_REQUESTS_DETAILS_SUCCESS',
  FETCH_CHANGE_REQUESTS_DETAILS_FAILURE: 'FETCH_CHANGE_REQUESTS_DETAILS_FAILURE',
  SET_CHANGE_REQUEST_DETAILS_TO_VIEW: 'SET_CHANGE_REQUEST_DETAILS_TO_VIEW',
  CHANGE_REQUEST_CLEAR_DETAILS: 'CHANGE_REQUEST_CLEAR_DETAILS',
  UPDATE_CHANGE_REQUESTS_DETAILS: 'UPDATE_CHANGE_REQUESTS_DETAILS',
  UPDATE_CHANGE_REQUESTS_DETAILS_SUCCESS: 'UPDATE_CHANGE_REQUESTS_DETAILS_SUCCESS',
  SET_MODAL_VISIBLE: 'SET_MODAL_VISIBLE',
  SET_MODAL_LOADER: 'SET_MODAL_LOADER',
  GET_CHANGE_REQUESTS_LIST: 'GET_CHANGE_REQUESTS_LIST',
  GET_CHANGE_REQUESTS_LIST_SUCCESS: 'GET_CHANGE_REQUESTS_LIST_SUCCESS',
};

export default actions;

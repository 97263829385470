import actions from './actions';
import { camelCase } from 'lodash';
import { reportsKeyValues } from '../../components/reports/constant';

const commonValues = {
  loader: false,
  currentReportType: '',
  reportsData: ''
};

const initState = {
  reportsDetails: {
    singleEmployee: commonValues,
    benefits: commonValues,
    sickBank: commonValues,
    lodi: commonValues,
    unionMeetings: commonValues,
    employeeDelegateAssignment: commonValues,
    disciplines: commonValues,
    grievances: commonValues,
    janus: commonValues,
    lifeInsurances: commonValues,
    pacfs: commonValues,
    workersComp: commonValues,
    benefitCoverages: commonValues,
    benefitCoverageExpiration: commonValues,
    beneficiary:commonValues,
    memberCount: commonValues,
    isClear: false
  }
};

export default function reportsReducer(state = initState, action) {
  switch (action.type) {
    case actions.GENERATE_REPORTS:
      return {
        ...state,
        reportsDetails: {
          ...state.reportsDetails,
          [action.reportTab]: {
            ...state.reportsDetails[action.reportTab],
            loader: true,
            currentReportType: action.reportType
          }
        }
      };

    case actions.GENERATE_REPORTS_FAILURE:
      return {
        ...state,
        reportsDetails: {
          ...state.reportsDetails,
          [action.reportTab]: {
            ...state.reportsDetails[action.reportTab],
            loader: false
          }
        }
      };

    case actions.REPORTS_SOCKET_RESPONSE:
      const { data } = action.payload,
        { attributes } = data,
        reportType = camelCase(attributes.report_type);
      return {
        ...state,
        reportsDetails: {
          ...state.reportsDetails,
          [reportsKeyValues[reportType].keyName]: {
            ...state.reportsDetails[reportsKeyValues[reportType].keyName],
            loader: false,
            currentReportType: '',
            reportsData: attributes
          }
        }
      };

    case actions.CLEAR_REPORT_DATE_VALIDATIONS: {
      return {
        ...state,
        reportsDetails: {
          ...state.reportsDetails,
          isClear: !state.reportsDetails.isClear
        }
      };
    }

    default:
      return state;
  }
}

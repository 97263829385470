import actions from './actions';

const initState = {
  notificationDetails: {
    loader: false,
    tableLists: [],
    tableDetails: undefined,
    searchNotifications: undefined,
    emailEditorValue: null
  },
  analyticsViewDetails: {
    view: false,
    details: undefined,
    loader: false,
    trackerList: [],
    trackerDetails: undefined
  },
  reportsDetails: {
    loader: false,
    currentReportType: '',
    reportsData: ''
  },
  createLoader: false
};

export default function notificationReducer(state = initState, action) {
  switch (action.type) {
    case actions.NOTIFICATION_CREATE:
      return {
        ...state,
        createLoader: true
      };

    case actions.NOTIFICATION_CREATE_SUCCESS:
      return {
        ...state,
        createLoader: false
      };

    case actions.NOTIFICATION_CREATE_FAILURE:
      return {
        ...state,
        createLoader: false
      };

    case actions.FETCH_NOTIFICATION_DETAILS:
      return {
        ...state,
        notificationDetails: {
          ...state.notificationDetails,
          loader: true,
          emailEditorValue: null
        }
      };

    case actions.FETCH_NOTIFICATION_DETAILS_SUCCESS:
      return {
        ...state,
        notificationDetails: {
          ...state.notificationDetails,
          loader: false,
          tableLists: action.payload.data,
          tableDetails: action.payload.meta
        }
      };

    case actions.FETCH_NOTIFICATION_DETAILS_FAILURE: {
      return {
        ...state,
        notificationDetails: {
          ...state.notificationDetails,
          loader: false
        }
      };
    }

    case actions.SET_SEARCH_NOTIFICATION_VALUE:
      return {
        ...state,
        notificationDetails: {
          ...state.notificationDetails,
          searchNotifications: action.searchValue
        }
      };

    case actions.SET_ANALYTICS_VIEW_DETAILS: {
      const {isView, details} = action;
      return {
        ...state,
        analyticsViewDetails: {
          ...state.analyticsViewDetails,
          view: [true, false].includes(isView)
            ? isView
            : state.analyticsViewDetails.view,
          details: isView ? details : undefined
        }
      };
    }

    case actions.FETCH_NOTIFICATION_TRACKERS_LIST:
      return {
        ...state,
        analyticsViewDetails: {
          ...state.analyticsViewDetails,
          loader: true
        }
      };

    case actions.FETCH_NOTIFICATION_TRACKERS_LIST_SUCCESS: {
      const {data, meta} = action.payload;
      return {
        ...state,
        analyticsViewDetails: {
          ...state.analyticsViewDetails,
          loader: false,
          trackerList: data,
          trackerDetails: meta
        }
      };
    }

    case actions.FETCH_NOTIFICATION_TRACKERS_LIST_FAILURE:
      return {
        ...state,
        analyticsViewDetails: {
          ...state.analyticsViewDetails,
          loader: false
        }
      };

    case actions.NOTIFICATION_REPORT_FAILURE: {
      return {
        ...state,
        reportsDetails: {
          ...state.reportsDetails,
          loader: false
        }
      };
    }

    case actions.DOWNLOAD_EXCEL_TRACKER_FILE:
      return {
        ...state,
        reportsDetails: {
          ...state.reportsDetails,
          loader: true
        }
      };

    case actions.REPORTS_NOTIFICATION_SOCKET_RESPONSE:
      const { data } = action.payload,
        { attributes } = data;
      return {
        ...state,
        reportsDetails: {
          ...state.reportsDetails,
          loader: false,
          reportsData: attributes
        }
      };
    case actions.SET_EMAIL_MESSAGE_CONTENT:
      return {
        ...state,
        notificationDetails: {
          ...state.notificationDetails,
          emailEditorValue: action.payload
        }
      };

    default:
      return state;
  }
}

const actions = {
  NOTIFICATION_CREATE: 'NOTIFICATION_CREATE',
  NOTIFICATION_CREATE_SUCCESS: 'NOTIFICATION_CREATE_SUCCESS',
  NOTIFICATION_CREATE_FAILURE: 'NOTIFICATION_CREATE_FAILURE',
  FETCH_NOTIFICATION_DETAILS: 'FETCH_NOTIFICATION_DETAILS',
  FETCH_NOTIFICATION_DETAILS_SUCCESS: 'FETCH_NOTIFICATION_DETAILS_SUCCESS',
  FETCH_NOTIFICATION_DETAILS_FAILURE: 'FETCH_NOTIFICATION_DETAILS_FAILURE',
  SET_SEARCH_NOTIFICATION_VALUE: 'SET_SEARCH_NOTIFICATION_VALUE',
  SET_ANALYTICS_VIEW_DETAILS: 'SET_ANALYTICS_VIEW_DETAILS',
  FETCH_NOTIFICATION_TRACKERS_LIST: 'FETCH_NOTIFICATION_TRACKERS_LIST',
  FETCH_NOTIFICATION_TRACKERS_LIST_SUCCESS:
    'FETCH_NOTIFICATION_TRACKERS_LIST_SUCCESS',
  FETCH_NOTIFICATION_TRACKERS_LIST_FAILURE:
    'FETCH_NOTIFICATION_TRACKERS_LIST_FAILURE',
  DOWNLOAD_EXCEL_TRACKER_FILE: 'DOWNLOAD_EXCEL_TRACKER_FILE',
  FETCH_NOTIFICATION_RECEIVER_COUNT: 'FETCH_NOTIFICATION_RECEIVER_COUNT',
  NOTIFICATION_REPORT_FAILURE: 'NOTIFICATION_REPORT_FAILURE',
  REPORTS_NOTIFICATION_SOCKET_RESPONSE: 'REPORTS_NOTIFICATION_SOCKET_RESPONSE',
  SET_EMAIL_MESSAGE_CONTENT: 'SET_EMAIL_MESSAGE_CONTENT'
};

export default actions;

import { convertIntoPlural, convertIntoSingular, updateBrowserHistory } from '../shared/helper';
import moment from 'moment';
import { dateFormatDisplay, dateFormatWitTime } from './constants';
import {Button, DatePicker, Input, Select} from 'antd';
import React from 'react';
import { contactInformation } from './constants';
import { debounce } from 'lodash';
import { store } from '../redux/store';
import actions from '../redux/memberList/actions';
import { memberListActionNames } from './memberList/constant';

const { Search } = Input,
  { Option } = Select,
  { RangePicker } = DatePicker;

export function onClickNavigation(e, url) {
  e.preventDefault();
  updateBrowserHistory(url);
}

export const dateConverter = value =>
  (value ? moment(value).format(dateFormatDisplay) : '');

export const dateWithTimeConverter = value =>
  (value ? moment(value).format(dateFormatWitTime) : '');

export const ContentHeader = props => {
  const {
    onClick,
    isHavingPermissions,
    title,
    onSearch,
    searchValue,
    memberList,
    view,
    statusSearchField,
    keyValues,
    selectOptions,
    employeeId,
    isAnalyticsLeave,
    ui,
    searchGrievance,
    dateFilter,
    dateFilterChange,
    isUserAudit
  } = props,
  showCreateNew = !!ui?.['employees']?.['profile']?.['analytics']?.['create_new'];

  const statusSearch = debounce(value => {
    store.dispatch({
      type: actions.SET_DISCIPLINE_STATUS_SEARCH_VALUE,
      searchStatusValue: value,
      page: 1
    });
    store.dispatch({
      type: actions[`${memberListActionNames[keyValues?.keyName]}_FETCH_TABLE_DETAILS`],
      page: 1,
      searchStatusValue: value,
      slug: employeeId,
      keyValues
    });
  },500)

  const singularValue = [
    'LODI',
    'Mailing List Category',
    'Annuity',
    'PESH',
    'Class Action Grievance'
  ];

  const getHeaderTitle = () => {
    if(!!ui?.['employees']?.['profile']?.['analytics']?.['singular_headers']){
      return convertIntoSingular(title);
    }
    if(singularValue.includes(title)){
      return title;
    }
    return convertIntoPlural(title);
  }

  return (
    <div className={'container-header'}>
      {!!dateFilter && (
        <div className={'audit-container-header'}>
          <div className={'date-filter'}>
            <RangePicker
              format={'MM-DD-YYYY'}
              onChange={(_, dateString) => dateFilterChange(dateString)}
            />
          </div>
        </div>
      )}
      <div className={'search-container'}>
        <h2>
          {getHeaderTitle()}
        </h2>
        {statusSearchField && (
          <div className={'filter'}>
            <Select
              showSearch
              placeholder={'Select'}
              onChange={(value) => statusSearch(value)}
              optionFilterProp={'children'}
              notFoundContent={'No results found'}
              allowClear={true}
            >
              {selectOptions?.disciplineChargesOptions?.map((property, index) => (
                <Option value={property.key} key={index}>
                  {property.value}
                </Option>
              ))}
            </Select>
          </div>
        )}
        {ui?.['employee_grievances']?.['is_filter']?.includes('grievance') && (
          <Search
            type={'number'}
            placeholder={'Grievance Case #'}
            className={'search-widget'}
            onChange={(value) => searchGrievance(value)}
          />)}
      </div>
      <div>
        {!memberList && (
          <Search
            placeholder="Search"
            className={'search-widget'}
            onChange={event => onSearch(event.target.value)}
            value={searchValue ? searchValue : undefined}
          />
        )}
        {isHavingPermissions && !view && !isUserAudit && (
          <Button type="primary" onClick={onClick}>
            {(isAnalyticsLeave && !showCreateNew) ? 'Request' : 'New'}
          </Button>
        )}
      </div>
    </div>
  );
};

export function getContactInformationValues(key, details, path) {
  if (details) {
    let value = '';
    contactInformation.forEach(data => {
      if (data.key === key) {
        details.forEach(editData => {
          let attributes = editData.attributes;
          if (
            data.contact_for === attributes.contact_for &&
            data.contact_type === attributes.contact_type
          ) {
            if (path) {
              value = attributes[path];
            } else {
              value = attributes.value;
            }
          }
        });
      }
    });
    return value;
  } else {
    return undefined;
  }
}

export const ssnFormatter = value => {
  if(!value){
    return ({
      numValue: '',
      number: ''
    });
  }
  const digitsOnly = value?.replace(/\D/g, '');
  const AAA = digitsOnly?.substring(0, 3);
  const GG = digitsOnly?.substring(3, 5);
  const SSSS = digitsOnly?.substring(5, 9);

  let numValue = '';
  if (digitsOnly?.length > 5) {
    numValue = `${AAA}-${GG}-${SSSS}`;
  } else if (digitsOnly?.length > 3) {
    numValue = `${AAA}-${GG}`;
  } else if (digitsOnly?.length > 0) {
    numValue = `${AAA}`;
  }

  return { numValue, number: digitsOnly };
};

export const noOfItemsOptions = (n) => Array.from(
  { length: n }, (_, index) => ({ key: `${index + 1}`, value: `${index + 1}` })
)

import React, { Component } from 'react';
import { Layout } from 'antd';
import Sidebar from '../../components/sidebar';
import Header from '../../components/header';
import AppRouter from '../../routes/appRouter';
import sharedActions from '../../redux/shared/actions';
import { store } from '../../redux/store';
import { connect } from 'react-redux';
import Loader from '../../components/shared/loader';
import {
  getModuleDetails,
  convertIntoHypenValues,
  getSubDomain
} from '../../shared/helper';
import IdleTimer from 'react-idle-timer';
import moment from 'moment';
import authActions from '../../redux/auth/actions';
import { isEmpty, startCase } from 'lodash';

const { Content } = Layout;
let lastMinuteTime = false,
  tokenTime = false,
  isLogout = false,
  interval;
localStorage.setItem(`${getSubDomain()}isTimedOut`, '0');

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMenu: this.setCurrentMenu(),
      path: ''
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { pathname } = window.location,
      { path } = this.state,
      { loginTime } = this.props;
    if (loginTime !== prevProps.loginTime) {
      tokenTime = loginTime;
      lastMinuteTime = undefined;
      isLogout = false;
      this.idleTimer.reset();
    }
    if (path !== pathname) {
      this.setState({ path: pathname });
      window.isSuperUser = undefined;
      this.setState({ currentMenu: this.setCurrentMenu() });
    }
  }

  setCurrentMenu = () => {
    const { pathname } = window.location,
      { superUserJson } = this.props;
    let menuKey = '',
      title = '';
    if (startCase(pathname.substring(1).split('/')?.[0]) === getModuleDetails('ui', 'employees')) {
      title = getModuleDetails('ui', 'employees');
      menuKey = '1';
    } else if (startCase(pathname.substring(1)) === getModuleDetails('ui', 'reports')) {
      title = getModuleDetails('ui', 'reports');
      menuKey = '2';
    } else if (
      !isEmpty(superUserJson) &&
      startCase(pathname.substring(1)) ===  convertIntoHypenValues(
        superUserJson['schema']['super_users']['key_name'],
      )
    ) {
      menuKey = '8';
      title = superUserJson['schema']['super_users']['key_name'];
    } else if (
      startCase(pathname.substring(1)) === getModuleDetails('schema', 'users')
    ) {
      menuKey = '3';
      title = getModuleDetails('schema', 'users');
    } else if (
      startCase(pathname.substring(1)) === getModuleDetails('ui', 'settings')
    ) {
      menuKey = '4';
      title = getModuleDetails('ui', 'settings');
    } else if (startCase(pathname.substring(1)) === 'Profile') {
      menuKey = '5';
      title = 'My Profile';
    } else if (
      startCase(pathname.substring(1)) === getModuleDetails('ui', 'notification')
    ) {
      menuKey = '7';
      title = getModuleDetails('ui', 'notification');
    } else if (startCase(pathname.substring(1).split('/')?.[0]) === getModuleDetails('ui', 'contact_persons')
    ) {
      menuKey = '6';
      title = getModuleDetails('ui', 'contact_persons');
    }
    else if (startCase(pathname.substring(1).split('/')?.[0]) === getModuleDetails('ui', 'change_requests')
    ) {
      menuKey = '9';
      title = getModuleDetails('ui', 'change_requests');
    } else if (startCase(pathname.substring(1)) === getModuleDetails('ui', 'employee_grievances')) {
      menuKey = 'employee_grievances';
      title = getModuleDetails('ui', 'employee_grievances');
    } else if (startCase(pathname.substring(1)) === getModuleDetails('ui', 'user_audit')) {
      menuKey = 'user_audit';
      title = getModuleDetails('ui', 'user_audit');
    }
    else if (startCase(pathname.substring(1)) === getModuleDetails('ui', 'maillogs')) {
      menuKey = 'maillogs';
      title = getModuleDetails('ui', 'maillogs');
    }
    store.dispatch({
      type: sharedActions.SET_BREADCRUMB_DETAILS,
      mainTitle: title
    });
    return menuKey;
  };

  onAction = e => {
    localStorage.setItem(
      `${getSubDomain()}isTimedOut`,
      this.idleTimer.isIdle() === '1' ? '1' : '0',
    );
  };

  onActive = e => {
    localStorage.setItem(
      `${getSubDomain()}isTimedOut`,
      this.idleTimer.isIdle() === '1' ? '1' : '0',
    );
  };

  onIdle = e => {
    localStorage.setItem(
      `${getSubDomain()}isTimedOut`,
      this.idleTimer.isIdle() ? '1' : '0',
    );
  };

  componentDidMount() {
    const { loginTime } = this.props;
    tokenTime = loginTime;
    isLogout = false;
    localStorage.setItem(`${getSubDomain()}isTimedOut`, '0');
    interval = setInterval(function() {
      const diff = moment(new Date()).diff(moment(tokenTime)),
        diffDuration = moment.duration(diff);
      let isTimedOut = localStorage.getItem(`${getSubDomain()}isTimedOut`);
      if (
        diffDuration.minutes() === 30 &&
        isTimedOut === '0' &&
        lastMinuteTime !== diffDuration.minutes()
      ) {
        store.dispatch({
          type: authActions.REFRESH_AUTH_TOKEN
        });
        lastMinuteTime = diffDuration.minutes();
      } else {
        if (diffDuration.minutes() === 30 && isTimedOut === '1' && !isLogout) {
          store.dispatch({
            type: authActions.LOGOUT
          });
          isLogout = true;
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(interval);
    localStorage.setItem(`${getSubDomain()}isTimedOut`, '0');
  }

  render() {
    const { userPermissionsLists, match, saasData } = this.props,
      { url } = match,
      { currentMenu } = this.state;
    if (userPermissionsLists && userPermissionsLists.length) {
      return (
        <Layout style={{ height: '100%' }}>
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            element={document}
            onIdle={this.onIdle}
            onAction={this.onAction}
            startOnMount={true}
            onActive={this.onActive}
            timeout={1770000}
          />
          <Sidebar currentMenu={currentMenu} />
          <Layout>
            <Header />
            <Content
              style={{
                margin: '24px 16px 0',
                overflow: 'initial',
                background: '#fff',
                padding: '20px'
              }}
            >
              <div className={'layout-content'}>
                <AppRouter url={url} saasData={saasData} />
              </div>
            </Content>
          </Layout>
        </Layout>
      );
    }
    return <Loader />;
  }
}

function mapStateToProps(state) {
  const {
    userPermissionsLists,
    saasData,
    loginTime,
    superUserJson
  } = state.Auth;
  return {
    userPermissionsLists,
    saasData,
    loginTime,
    superUserJson
  };
}

export default connect(mapStateToProps, sharedActions)(App);

import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { message } from 'antd';
import {
  getPromise,
  postPromise,
  deletePromise,
  patchPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
  showErrorMessages
} from '../../shared/helper';
import settings from '../../settings';
import { store } from '../../redux/store';

export function* createContactList(params) {
  const { keyValues } = params,
    { apiKey } = keyValues;
  try {
    const url = `${settings.ROOT_URL}${apiKey}?category=contact_person`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.CREATE_CONTACT_LIST_SUCCESS,
      payload: data
    })
    message.success(
      `${keyValues?.messageKey} created successfully`
    );
    updateBrowserHistory(`${data.data.attributes.slug}/edit`);
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error, 'employees');
  }
}

export function* updateContactList(params) {
  const { keyValues, slug } = params,
    { apiKey } = keyValues;
  try {
    const url = `${settings.ROOT_URL}${apiKey}/${slug}?category=contact_person`,
      data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.UPDATE_CONTACT_LIST_SUCCESS,
      payload: data
    })
    message.success(
      `${keyValues.messageKey} updated successfully`
    );
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error, 'employees');
  }
}

export function* fetchTableDetails(params) {
  const { keyValues } = params,
    { apiKey } = keyValues;
  const searchValue = params.searchValue
    ? params.searchValue
    : store.getState().ContactList.searchValue
  try {
    const url = `${settings.ROOT_URL}${apiKey}?category=contact_person${
      params.page ? `&page=${params.page}` : ''}${searchValue ? `&search_text=${searchValue}&` : ''}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.GET_CONTACT_LIST_SUCCESS,
      payload: data
    });
    yield put({
      type: actions['GET_CONTACT_DETAILS_SUCCESS'],
      payload: data,
      keyValues
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions.GET_CONTACT_LIST_FAILURE
    });
    updateBrowserHistory('/404');
  }
}

export function* deleteContactList(params) {
  const { keyValues, pagination, slug } = params,
    { apiKey } = keyValues;
  try {
    let url = `${settings.ROOT_URL}${apiKey}/${slug}`;
    yield call(() => deletePromise(url));
    yield put({
      type: actions.DELETE_CONTACT_LIST_SUCCESS
    });
    message.success(
      `${keyValues.messageKey.split(' ')[0]} deleted successfully`
    );
    yield put({
      type: actions.GET_CONTACT_LIST,
      keyValues,
      page: (pagination.total - 1 % pagination.defaultPageSize) === 0 ?
        pagination.current > 1 && pagination.current - 1 : pagination.current
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.DELETE_CONTACT_LIST_FAILURE
    });
    showErrorMessages(error, keyValues['keyName']);
  }
}

export function* getContactDetails(params) {
  const { keyValues } = params,
    { apiKey } = keyValues;
  try {
    const url = `${settings.ROOT_URL}${apiKey}/${params.slug}?${
      apiKey === 'employees' ? 'category=contact_person&' : ''}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions['GET_CONTACT_DETAILS_SUCCESS'],
      payload: data,
      keyValues
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions['GET_CONTACT_DETAILS_FAILURE'],
      keyValues
    });
    showErrorMessages(error);
    updateBrowserHistory('/404');
  }
}

export function* createStatusDetail(params) {
  const { keyValues, employeeId } = params,
    { keyName, apiKey, messageKey } = keyValues;
  try {
    let url = `${settings.ROOT_URL}${apiKey}`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions['EMPLOYEES_DEPARTMENTS_CREATE_DETAILS_SUCCESS'],
      payload: data.data,
      keyValues
    });
    yield put({
      type: actions.SET_PROFILE_OTHERS_MODAL_VISIBLE,
      [keyName]: false
    });
    yield put({
      type: actions.FETCH_EMPLOYEES_DEPARTMENTS_DETAILS,
      slug: employeeId,
      keyValues: keyValues
    });
    message.success(`${messageKey} created successfully`);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions['EMPLOYEES_DEPARTMENTS_CREATE_DETAILS_FAILURE'],
      keyValues
    });
    showErrorMessages(error, keyValues['keyName']);
  }
}

export function* createFacilityDetail(params) {
  const { keyValues, employeeId } = params,
    { keyName, apiKey, messageKey } = keyValues;
  try {
    let url = `${settings.ROOT_URL}${apiKey}`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions['EMPLOYEES_FACILITY_CREATE_DETAILS_SUCCESS'],
      payload: data.data,
      keyValues
    });
    yield put({
      type: actions.SET_PROFILE_OTHERS_MODAL_VISIBLE,
      [keyName]: false
    });
    yield put({
      type: actions.FETCH_EMPLOYEES_FACILITY_DETAILS,
      slug: employeeId,
      keyValues: keyValues
    });
    message.success(`${messageKey} created successfully`);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions['EMPLOYEES_FACILITY_CREATE_DETAILS_FAILURE'],
      keyValues
    });
    showErrorMessages(error, keyValues['keyName']);
  }
}
export function* deleteStatusDetails(params) {
  const { keyValues, employeeId } = params,
    { apiKey, messageKey } = keyValues;
  try {
    let url = `${settings.ROOT_URL}${apiKey}/${params.slug}`;
    yield call(() => deletePromise(url));
    message.success(`${messageKey} deleted successfully`);
    yield put({
      type: actions['EMPLOYEES_DEPARTMENTS_DELETE_DETAILS_SUCCESS'],
      slug: params.slug,
      keyValues
    });
    yield put({
      type: actions.FETCH_EMPLOYEES_DEPARTMENTS_DETAILS,
      slug: employeeId,
      keyValues: keyValues
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions['EMPLOYEES_DEPARTMENTS_DELETE_DETAILS_FAILURE'],
      keyValues
    });
    showErrorMessages(error, keyValues['keyName']);
  }
}

export function* deleteFacilityDetails(params) {
  const { keyValues, employeeId } = params,
    { apiKey, messageKey } = keyValues;
  try {
    let url = `${settings.ROOT_URL}${apiKey}/${params.slug}`;
    yield call(() => deletePromise(url));
    message.success(`${messageKey} deleted successfully`);
    yield put({
      type: actions['EMPLOYEES_FACILITY_DELETE_DETAILS_SUCCESS'],
      slug: params.slug,
      keyValues
    });
    yield put({
      type: actions.FETCH_EMPLOYEES_FACILITY_DETAILS,
      slug: employeeId,
      keyValues: keyValues
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions['EMPLOYEES_FACILITY_DELETE_DETAILS_FAILURE'],
      keyValues
    });
    showErrorMessages(error, keyValues['keyName']);
  }

}

export function* updateStatusDetail(params) {
  const { keyValues, slug, employeeId } = params,
    { apiKey,keyName  } = keyValues;
  try {
    const url = `${settings.ROOT_URL}${apiKey}/${slug}`,
      data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.EMPLOYEES_DEPARTMENTS_EDIT_DETAILS_SUCCESS,
      payload: data
    })
    yield put({
      type: actions.FETCH_EMPLOYEES_DEPARTMENTS_DETAILS,
      slug: employeeId,
      keyValues: keyValues
    });
    yield put({
      type: actions.SET_PROFILE_OTHERS_MODAL_VISIBLE,
      [keyName]: false
    });
    message.success(
      `${keyValues.messageKey} updated successfully`
    );
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.EMPLOYEES_DEPARTMENTS_EDIT_DETAILS_FAILURE
    })
    showErrorMessages(error, 'employees');
  }
}

export function* updateFacilityDetail(params) {
  const { keyValues, slug, employeeId } = params,
    { apiKey,keyName  } = keyValues;
  try {
    const url = `${settings.ROOT_URL}${apiKey}/${slug}`,
      data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions.EMPLOYEES_FACILITY_EDIT_DETAILS_SUCCESS,
      payload: data
    })
    yield put({
      type: actions.FETCH_EMPLOYEES_FACILITY_DETAILS,
      slug: employeeId,
      keyValues: keyValues
    });
    yield put({
      type: actions.SET_PROFILE_OTHERS_MODAL_VISIBLE,
      [keyName]: false
    });
    message.success(
      `${keyValues.messageKey} updated successfully`
    );
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.EMPLOYEES_FACILITY_EDIT_DETAILS_FAILURE
    })
    showErrorMessages(error, 'employees');
  }
}


export function* fetchDepartmentDetails(params) {
  const { keyValues } = params,
    { apiKey } = keyValues;
  try {
    const url = `${settings.ROOT_URL}${apiKey}?employee_id=${params.slug}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_EMPLOYEES_DEPARTMENTS_DETAILS_SUCCESS,
      payload: data
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions.FETCH_EMPLOYEES_DEPARTMENTS_DETAILS_FAILURE
    });
    updateBrowserHistory('/404');
  }
}

export function* fetchFacilityDetails(params) {
  const { keyValues } = params,
    { apiKey } = keyValues;
  try {
    const url = `${settings.ROOT_URL}${apiKey}?employee_id=${params.slug}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_EMPLOYEES_FACILITY_DETAILS_SUCCESS,
      payload: data
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions.FETCH_EMPLOYEES_FACILITY_DETAILS_FAILURE
    });
    updateBrowserHistory('/404');
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CONTACT_LIST, fetchTableDetails),
    takeEvery(actions.CREATE_CONTACT_LIST, createContactList),
    takeEvery(actions.UPDATE_CONTACT_LIST, updateContactList),
    takeEvery(actions.DELETE_CONTACT_LIST, deleteContactList),
    takeEvery(actions.GET_CONTACT_DETAILS, getContactDetails),
    takeEvery(actions.EMPLOYEES_DEPARTMENTS_DELETE_DETAILS, deleteStatusDetails),
    takeEvery(actions.EMPLOYEES_FACILITY_DELETE_DETAILS, deleteFacilityDetails),
    takeEvery(actions.FETCH_EMPLOYEES_DEPARTMENTS_DETAILS, fetchDepartmentDetails),
    takeEvery(actions.FETCH_EMPLOYEES_FACILITY_DETAILS, fetchFacilityDetails),
    takeEvery(actions.EMPLOYEES_DEPARTMENTS_EDIT_DETAILS, updateStatusDetail),
    takeEvery(actions.EMPLOYEES_FACILITY_EDIT_DETAILS, updateFacilityDetail),
    takeEvery(actions.EMPLOYEES_DEPARTMENTS_CREATE_DETAILS, createStatusDetail),
    takeEvery(actions.EMPLOYEES_FACILITY_CREATE_DETAILS, createFacilityDetail)
  ]);
}
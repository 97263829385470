import './style.scss';
import React, { Component } from 'react';
import { Form, Select } from 'antd';
import { startCase } from 'lodash';

class InputSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
        data,
        fieldName,
        onChange,
        label,
        placeholder,
        errorMessage,
        initialValue,
        required,
        allowClear,
        disabled,
        isHavingAdditionalOption
      } = this.props,
      Option = Select.Option,
      { getFieldDecorator } = this.props.form;
    return (
      <Form.Item
        label={label}
        className={'form-item-customize'}
        required={required}
      >
        {getFieldDecorator(fieldName, {
          initialValue: initialValue ? initialValue : undefined,
          rules: [
            {
              required: required,
              message: errorMessage
            }
          ]
        })(
          <Select
            showSearch
            placeholder={placeholder ? placeholder : 'Select'}
            onChange={onChange}
            optionFilterProp={'children'}
            notFoundContent={'No results found'}
            allowClear={allowClear}
            disabled={disabled}
          >
            {data &&
              data.map((property, index) => (
                <Option
                  value={property.key}
                  key={index}
                >
                  {property.value}
                </Option>
              ))}
            {isHavingAdditionalOption?.length &&
            isHavingAdditionalOption?.map((property, index) => (
              <Option
                value={property}
                key={property}
              >
                {startCase(property)}
              </Option>
            ))}
          </Select>,
        )}
      </Form.Item>
    );
  }
}

export default InputSelect;

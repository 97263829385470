import actions from './actions';
import { selectOptionsActionNames } from '../../components/constants';

const initState = {
  navbarToggledStatus: false,
  isMobile: false,
  breadcrumbDetails: {
    mainTitle: '',
    subTitle: '',
    infoTitle: ''
  },
  profileImage: {
    avatar: undefined,
    removeAvatar: false,
    imageUrl: undefined
  },
  fileUpload: {
    file: undefined,
    fileUrl: undefined
  },
  selectOptions: {},
  profileDetails: {
    avatar: undefined,
    username: undefined,
    userId: undefined
  },
  tableName: undefined
};

export default function sharedReducer(state = initState, action) {
  const { selectOptionsKeyName, keyName } = action.keyValues
    ? action.keyValues
    : '';
  switch (action.type) {
    case actions.SET_NAVBAR_TOGGLED_STATUS:
      return {
        ...state,
        navbarToggledStatus: action.payload
      };
    case actions.SET_IS_MOBILE_OR_NOT:
      return {
        ...state,
        isMobile: action.payload
      };
    case actions.SET_BREADCRUMB_DETAILS:
      return {
        ...state,
        breadcrumbDetails: {
          mainTitle: action.mainTitle
            ? action.mainTitle
            : state.breadcrumbDetails.mainTitle,
          subTitle: action.subTitle,
          infoTitle: action.infoTitle
        }
      };
    case actions.UPLOAD_PROFILE_AVATAR:
      return {
        ...state,
        profileImage: {
          ...state.profileImage,
          avatar: action.payload,
          removeAvatar: false,
          imageUrl: action.imageUrl
        }
      };
    case actions.REMOVE_PROFILE_AVATAR:
      return {
        ...state,
        profileImage: {
          ...state.profileImage,
          removeAvatar: true,
          avatar: undefined,
          imageUrl: undefined
        }
      };
    case actions.UPLOAD_FILE:
      return {
        ...state,
        fileUpload: {
          ...state.fileUpload,
          file: action.payload,
          fileUrl: action.fileUrl
        }
      };

    case actions[
      `${selectOptionsActionNames[keyName]}_FETCH_SELECT_OPTIONS_SUCCESS`
    ]:
      return {
        ...state,
        selectOptions: {
          ...state.selectOptions,
          [`${selectOptionsKeyName}`]: action.payload
        }
      };

    case actions[`${selectOptionsActionNames[keyName]}_APPEND_SELECT_OPTIONS`]:
      return {
        ...state,
        selectOptions: {
          ...state.selectOptions,
          [`${selectOptionsKeyName}`]: state.selectOptions[
            `${selectOptionsKeyName}`
          ].concat(action.payload)
        }
      };
    case actions[
      `${selectOptionsActionNames[keyName]}_FETCH_SELECT_OPTIONS_FAILURE`
    ]:
      return {
        ...state,
        selectOptions: {
          ...state.selectOptions,
          [`${selectOptionsKeyName}`]: []
        }
      };
    case actions.CLEAR_SELECT_OPTIONS:
      return {
        ...state,
        selectOptions: initState.selectOptions
      };
    case actions.CLEAR_PROFILE_AVATAR:
      return {
        ...state,
        profileImage: initState.profileImage
      };
    case actions.SET_PROFILE_DETAILS:
      const {
        avatar,
        username,
        id
      } = action.payload.data.attributes;
      return {
        ...state,
        profileDetails: {
          avatar,
          username,
          userId: id
        }
      };
    case actions.SET_TABLE_NAME_FOR_WIDTH_ADJUSTMENTS:
      return {
        ...state,
        tableName: action.tableName
      };
    case actions.LEGISLATIVE_DETAILS_FETCH_SELECT_OPTIONS_SUCCESS:
      return {
        ...state,
        selectOptions: {
          ...state.selectOptions,
          [`${selectOptionsKeyName}`]: action.payload
        }
      };
    default:
      return state;
  }
}

const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  AUTHORIZATION_SUCCESS: 'AUTHORIZATION_SUCCESS',
  AUTHORIZATION_FAILURE: 'AUTHORIZATION_FAILURE',
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  FETCH_USER_PERMISSIONS: 'FETCH_USER_PERMISSIONS',
  FETCH_USER_PERMISSIONS_SUCCESS: 'FETCH_USER_PERMISSIONS_SUCCESS',
  FETCH_USER_PERMISSIONS_FAILURE: 'FETCH_USER_PERMISSIONS_FAILURE',
  GET_SAAS_DATA: 'GET_SAAS_DATA',
  GET_SAAS_DATA_SUCCESS: 'GET_SAAS_DATA_SUCCESS',
  GET_SAAS_DATA_FAILURE: 'GET_SAAS_DATA_FAILURE',
  REFRESH_AUTH_TOKEN: 'REFRESH_AUTH_TOKEN',
  REFRESH_AUTH_TOKEN_SUCCESS: 'REFRESH_AUTH_TOKEN_SUCCESS',
  REFRESH_AUTH_TOKEN_FAILURE: 'REFRESH_AUTH_TOKEN_FAILURE',
};

export default actions;

const actions = {
  FETCH_USER_TABLE_DETAILS: 'FETCH_USER_TABLE_DETAILS',
  FETCH_USER_TABLE_DETAILS_SUCCESS: 'FETCH_USER_TABLE_DETAILS_SUCCESS',
  FETCH_USER_TABLE_DETAILS_FAILURE: 'FETCH_USER_TABLE_DETAILS_FAILURE',
  CREATE_USER: 'CREATE_USER',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  DELETE_USER_DATA_IN_TABLE: 'DELETE_USER_DATA_IN_TABLE',
  DELETE_USER_DATA_IN_TABLE_SUCCESS: 'DELETE_USER_DATA_IN_TABLE_SUCCESS',
  DELETE_USER_DATA_IN_TABLE_FAILURE: 'DELETE_USER_DATA_IN_TABLE_FAILURE',
  GET_USER_DETAILS: 'GET_USER_DETAILS',
  CLEAR_USER_DETAILS: 'CLEAR_USER_DETAILS',
  SEARCH_USER_DETAILS: 'SEARCH_USER_DETAILS',
  FETCH_USER_PROFILE_DETAILS: 'FETCH_USER_PROFILE_DETAILS',
  FETCH_USER_PROFILE_DETAILS_SUCCESS: 'FETCH_USER_PROFILE_DETAILS_SUCCESS',
  FETCH_USER_PROFILE_DETAILS_FAILURE: 'FETCH_USER_PROFILE_DETAILS_FAILURE',
  UPDATE_USER_PROFILE_DETAILS: 'UPDATE_USER_PROFILE_DETAILS',
  UPDATE_USER_PROFILE_DETAILS_SUCCESS: 'UPDATE_USER_PROFILE_DETAILS_SUCCESS',
  UPDATE_USER_PROFILE_DETAILS_FAILURE: 'UPDATE_USER_PROFILE_DETAILS_FAILURE',
};

export default actions;

import asyncComponent from '../shared/asyncFunction';
import { convertIntoHypenValues, getModuleDetails } from '../shared/helper';
import { store } from '../redux/store';
import { isEmpty } from 'lodash';

export function getUsersRoutePath() {
  const { superUserJson } = store.getState().Auth;
  let superUsersData = {};
  if (!isEmpty(superUserJson)) {
    superUsersData = {
      path: convertIntoHypenValues(
        superUserJson['schema']['super_users']['key_name'],
      ),
      component: asyncComponent(() => import('../containers/superUsers')),
    };
  }
  return [
    {
      path: 'profile',
      component: asyncComponent(() => import('../containers/users/profile')),
    },
    {
      path: convertIntoHypenValues(getModuleDetails('schema', 'users')),
      component: asyncComponent(() => import('../containers/users')),
    },
    superUsersData,
  ];
}

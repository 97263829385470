import actions from './actions';

const initState = {
  changeRequestsDetails: {
    loader: false,
    tableLists: [],
    tableDetails: undefined,
    viewRequestDetails: false,
    changeRequestId: undefined,
    editData: undefined,
    searchValue: undefined,
  },
};

export default function changeRequestReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_CHANGE_REQUESTS_DETAILS:
      return {
        ...state,
        changeRequestsDetails: {
          ...state.changeRequestsDetails,
          loader: true,
          tableDetails: action.pagination,
        },
      };
    case actions.FETCH_CHANGE_REQUESTS_DETAILS_SUCCESS:
      return {
        ...state,
        changeRequestsDetails: {
          ...state.changeRequestsDetails,
          loader: false,
          tableLists: action.payload.data,
          tableDetails: action.payload.meta,
        },
      };
    case actions.FETCH_CHANGE_REQUESTS_DETAILS_FAILURE:
      return {
        ...state,
        changeRequestsDetails: {
          ...state.changeRequestsDetails,
          loader: false,
        },
      };
    case actions.SET_CHANGE_REQUEST_DETAILS_TO_VIEW:
      return {
        ...state,
        changeRequestsDetails: {
          ...state.changeRequestsDetails,
          viewRequestDetails: action.editData?.attributes?.status !== 'completed' ? action.isView : false,
          changeRequestId: action.changeRequestId,
          editData: action.editData,
          modalVisible: false,
        },
      };
    case actions.UPDATE_CHANGE_REQUESTS_DETAILS:
      return {
        ...state,
        changeRequestsDetails: {
          ...state.changeRequestsDetails,
          acceptLoader: action.acceptLoader,
          declineLoader: action.declineLoader,
        },
      };
    case actions.UPDATE_CHANGE_REQUESTS_DETAILS_SUCCESS:
      return {
        ...state,
        changeRequestsDetails: {
          ...state.changeRequestsDetails,
          acceptLoader: false,
          declineLoader: false,
          modalVisible: false,
        },
      };
    case actions.SET_MODAL_VISIBLE:
      return {
        ...state,
        changeRequestsDetails: {
          ...state.changeRequestsDetails,
          modalVisible: action.payload,
        },
      };
    case actions.SET_MODAL_LOADER:
      return {
        ...state,
        changeRequestsDetails: {
          ...state.changeRequestsDetails,
          modalLoader: action.modalLoader,
        },
      };
    case actions.GET_CHANGE_REQUESTS_LIST_SUCCESS:
      return {
        ...state,
        changeRequestsDetails: {
          ...state.changeRequestsDetails,
          editData: action.payload,
        },
      };
    case actions.CHANGE_REQUEST_CLEAR_DETAILS:
      return initState;
    default:
      return state;
  }
};
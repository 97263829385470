import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../shared/asyncFunction';
import { getMemberListRoutePath } from './memberList';
import { getReportsRoutePath } from './reports';
import { getUsersRoutePath } from './users';
import { getSettingsRoutePath } from './setting';
import { getNotificationRoutePath } from './notification';
import { getChangeRequestRoutePath } from './changeRequests'
import { getContactListRoutePath } from './contactList'
import {
  memberTabs,
  reportTabs,
  userTabs,
  settingsTabs,
  notificationTabs,
  changeRequestsTabs,
  contactListTabs,
  grievanceListTabs,
  mailLog,
  userAudit
} from '../shared/constants';
import { checkTabsAccessPermission } from '../shared/helper';
import { cloneDeep } from 'lodash';
import { getGrievanceRoutePath } from './grievance';
import { getMailLogRoutePath } from './mailLog';
import { getUserAuditRoutePath } from "./userAudit";

class AppRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routes: []
    };
  }

  componentDidMount() {
    const routes = this.state.routes,
      profilePage = cloneDeep(getUsersRoutePath()).splice(0, 1);
    routes.push(...profilePage);
    if (checkTabsAccessPermission(memberTabs)) {
      routes.push(...getMemberListRoutePath());
    }
    if (checkTabsAccessPermission(grievanceListTabs)) {
      routes.push(...getGrievanceRoutePath());
    }
    if (checkTabsAccessPermission(reportTabs)) {
      routes.push(...getReportsRoutePath());
    }
    if (checkTabsAccessPermission(userTabs)) {
      routes.push(...getUsersRoutePath());
    }
    if (checkTabsAccessPermission(settingsTabs)) {
      routes.push(...getSettingsRoutePath());
    }
    if (checkTabsAccessPermission(notificationTabs)) {
      routes.push(...getNotificationRoutePath());
    }
    if (changeRequestsTabs) {
      routes.push(...getChangeRequestRoutePath());
    }
    if(checkTabsAccessPermission(contactListTabs)) {
      routes.push(...getContactListRoutePath())
    }
    if(checkTabsAccessPermission(mailLog)){
      routes.push(...getMailLogRoutePath())
    }
    if(checkTabsAccessPermission(userAudit)) {
      routes.push(...getUserAuditRoutePath())
    }
    this.setState({
      routes
    });
  }

  render() {
    const { url } = this.props,
      { routes } = this.state;
    return (
      <Fragment>
        <Switch>
          {routes.map((singleRoute, index) => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <Route
                exact={exact !== false}
                key={index}
                path={`${url}${path}`}
                {...otherProps}
              />
            );
          })}
          <Route
            path="*"
            component={asyncComponent(() => import('../containers/pages/404'))}
          />
        </Switch>
      </Fragment>
    );
  }
}

export default AppRouter;

import actions from './actions';
import { cloneDeep, findIndex } from 'lodash';
import { settingsActionNames } from '../../components/settings/constant';

const commonValues = {
  loader: false,
  tableLists: [],
  tableDetails: undefined,
  modalLoader: false,
  modalVisible: false,
  deleteLoader: false,
  editData: undefined,
  searchValue: ''
};

const initState = {
  benefits: commonValues,
  departments: commonValues,
  offices: commonValues,
  pacfs: commonValues,
  ranks: commonValues,
  firearmStatuses: commonValues,
  employmentStatuses: commonValues,
  officerStatuses: commonValues,
  maritalStatuses: commonValues,
  positions: commonValues,
  paymentTypes: commonValues,
  meetingTypes: commonValues,
  disciplineSettings: commonValues,
  grievances: commonValues,
  genders: commonValues,
  units: commonValues,
  sections: commonValues,
  titles: commonValues,
  affiliations: commonValues,
  tourOfDuties: commonValues,
  facilities: commonValues,
  platoons: commonValues,
  disciplineCharges: commonValues,
  disciplineStatuses: commonValues,
  grievanceCharges: commonValues,
  grievanceStatuses: commonValues,
  delegateSeries: commonValues,
  analyticsConfiguration: commonValues
};

export default function settingsReducer(state = initState, action) {
  const { keyName } = action.keyValues ? action.keyValues : '';
  switch (action.type) {
    case actions[
      `${settingsActionNames[keyName]}_SETTINGS_FETCH_TABLE_DETAILS`
    ]:
      return {
        ...state,
        [`${keyName}`]: {
          ...state[`${keyName}`],
          loader: true
        }
      };

    case actions[
      `${settingsActionNames[keyName]}_SETTINGS_FETCH_TABLE_DETAILS_SUCCESS`
    ]:
      return {
        ...state,
        [`${keyName}`]: {
          ...state[`${keyName}`],
          loader: false,
          selectOptions: action.selectOptions,
          tableLists: action.payload.data,
          tableDetails: action.payload.meta
        }
      };

    case actions[
      `${settingsActionNames[keyName]}_SETTINGS_FETCH_TABLE_DETAILS_FAILURE`
    ]:
      return {
        ...state,
        [`${keyName}`]: {
          ...state[`${keyName}`],
          loader: false
        }
      };

    case actions[`${settingsActionNames[keyName]}_SETTINGS_CREATE`]:
      return {
        ...state,
        [`${keyName}`]: {
          ...state[`${keyName}`],
          modalLoader: true
        }
      };

    case actions[`${settingsActionNames[keyName]}_SETTINGS_CREATE_SUCCESS`]:
      return {
        ...state,
        [`${keyName}`]: {
          ...state[`${keyName}`],
          editData: action.payload,
          modalLoader: false,
          modalVisible: action.modalVisible
        }
      };

    case actions[`${settingsActionNames[keyName]}_SETTINGS_CREATE_FAILURE`]:
      return {
        ...state,
        [`${keyName}`]: {
          ...state[`${keyName}`],
          modalLoader: false
        }
      };

    case actions[`${settingsActionNames[keyName]}_SETTINGS_UPDATE`]:
      return {
        ...state,
        [`${keyName}`]: {
          ...state[`${keyName}`],
          modalLoader: true
        }
      };

    case actions[`${settingsActionNames[keyName]}_SETTINGS_UPDATE_SUCCESS`]:
      let tableLists = cloneDeep(state[`${keyName}`].tableLists);
      let positionOfIndex = findIndex(tableLists, function(object) {
        return object.attributes.slug === action.payload.attributes.slug;
      });
      tableLists.splice(positionOfIndex, 1, action.payload);
      return {
        ...state,
        [`${keyName}`]: {
          ...state[`${keyName}`],
          modalLoader: false,
          tableLists,
          modalVisible: action.modalVisible
        }
      };

    case actions[`${settingsActionNames[keyName]}_SETTINGS_UPDATE_FAILURE`]:
      return {
        ...state,
        [`${keyName}`]: {
          ...state[`${keyName}`],
          modalLoader: false
        }
      };

    case actions[`${settingsActionNames[keyName]}_SETTINGS_DETAILS`]:
      return {
        ...state,
        [`${keyName}`]: {
          ...state[`${keyName}`],
          modalVisible: [false, true].includes(action.modalVisible)
            ? action.modalVisible
            : state[`${keyName}`].modalVisible,
          editData: action.editData ? action.editData : undefined
        }
      };

    case actions[
      `${settingsActionNames[keyName]}_SETTINGS_DELETE_DATA_IN_TABLE`
    ]:
      return {
        ...state,
        [`${keyName}`]: {
          ...state[`${keyName}`],
          deleteLoader: true
        }
      };

    case actions[
      `${settingsActionNames[keyName]}_SETTINGS_DELETE_DATA_IN_TABLE_SUCCESS`
    ]:
      return {
        ...state,
        [`${keyName}`]: {
          ...state[`${keyName}`],
          deleteLoader: false,
          modelVisible: false
        }
      };

    case actions[
      `${settingsActionNames[keyName]}_SETTINGS_DELETE_DATA_IN_TABLE_FAILURE`
    ]:
      return {
        ...state,
        [`${keyName}`]: {
          ...state[`${keyName}`],
          deleteLoader: false
        }
      };

    case actions[`${settingsActionNames[keyName]}_SETTINGS_SEARCH_VALUE_SET`]:
      return {
        ...state,
        [`${keyName}`]: {
          ...state[`${keyName}`],
          searchValue: action.searchValue
        }
      };

    case actions.SETTINGS_CLEAR_DETAILS:
      return initState;
    default:
      return state;
  }
}

const actions = {
  FETCH_GRIEVANCE_DETAILS: 'FETCH_GRIEVANCE_DETAILS',
  FETCH_GRIEVANCE_DETAILS_SUCCESS: 'FETCH_GRIEVANCE_DETAILS_SUCCESS',
  FETCH_GRIEVANCE_DETAILS_FAILURE: 'FETCH_GRIEVANCE_DETAILS_FAILURE',
  GRIEVANCE_TAB_DETAILS: 'GRIEVANCE_TAB_DETAILS',
  CREATE_GRIEVANCE_DETAILS: 'CREATE_GRIEVANCE_DETAILS',
  CREATE_GRIEVANCE_DETAILS_SUCCESS: 'CREATE_GRIEVANCE_DETAILS_SUCCESS',
  CREATE_GRIEVANCE_DETAILS_FAILURE: 'CREATE_GRIEVANCE_DETAILS_FAILURE',
  UPDATE_GRIEVANCE_DETAILS: 'UPDATE_GRIEVANCE_DETAILS',
  UPDATE_GRIEVANCE_DETAILS_SUCCESS: 'UPDATE_GRIEVANCE_DETAILS_SUCCESS',
  UPDATE_GRIEVANCE_DETAILS_FAILURE: 'UPDATE_GRIEVANCE_DETAILS_FAILURE',
  CREATE_GRIEVANCE_STEP_DETAILS: 'CREATE_GRIEVANCE_STEP_DETAILS',
  CREATE_GRIEVANCE_STEP_DETAILS_SUCCESS: 'CREATE_GRIEVANCE_STEP_DETAILS_SUCCESS',
  CREATE_GRIEVANCE_STEP_DETAILS_FAILURE: 'CREATE_GRIEVANCE_STEP_DETAILS_FAILURE',
  UPDATE_GRIEVANCE_STEP_DETAILS: 'UPDATE_GRIEVANCE_STEP_DETAILS',
  UPDATE_GRIEVANCE_STEP_DETAILS_SUCCESS: 'UPDATE_GRIEVANCE_STEP_DETAILS_SUCCESS',
  UPDATE_GRIEVANCE_STEP_DETAILS_FAILURE: 'UPDATE_GRIEVANCE_STEP_DETAILS_FAILURE',
  DELETE_GRIEVANCE_DETAILS: 'DELETE_GRIEVANCE_DETAILS',
  DELETE_GRIEVANCE_DETAILS_SUCCESS: 'DELETE_GRIEVANCE_DETAILS_SUCCESS',
  DELETE_GRIEVANCE_DETAILS_FAILURE: 'DELETE_GRIEVANCE_DETAILS_FAILURE',
  SHOW_GRIEVANCE_MODAL_VISIBLE: 'SHOW_GRIEVANCE_MODAL_VISIBLE',
  SET_SEARCH_CASE_NUMBER_IN_GRIEVANCE: 'SET_SEARCH_CASE_NUMBER_IN_GRIEVANCE'
};
export default actions;
import actions from './actions';

const commonValues = {
  loader: false,
  tableLists: [],
  tableDetails: undefined,
  modalLoader: false,
  modalVisible: false,
  deleteLoader: false,
  editData: undefined,
  tableIncluded: [],
  searchNumberValue: null
};

const initState = {
  employeeGrievances: {
    grievances: commonValues,
    grievanceSteps: commonValues
  }
};

export default function employeeGrievancesReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_GRIEVANCE_DETAILS: {
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances.grievances,
            loader: true
          }
        }
      }
    }
    case actions.FETCH_GRIEVANCE_DETAILS_SUCCESS: {
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances['grievances'],
            loader: false,
            tableLists: action.payload.data,
            tableDetails: action.payload.meta,
            tableIncluded: action.payload.included?.length
              ? action.payload.included
              : []
          }
        }
      }
    }
    case actions.FETCH_GRIEVANCE_DETAILS_FAILURE: {
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances.grievances,
            loader: false
          }
        }
      }
    }
    case actions['GRIEVANCE_TAB_DETAILS']:
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances.grievances,
            modalVisible: [false, true].includes(action.modalVisible)
              ? action.modalVisible
              : state.grievances.modalVisible,
            editData: action.editData ? action.editData : undefined
          }
        }
      };
    case actions.CREATE_GRIEVANCE_DETAILS:
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances.grievances,
            loader: true
          }
        }
      }
    case actions.CREATE_GRIEVANCE_DETAILS_SUCCESS:
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances.grievances,
            editData: action.payload.data,
            modalVisible: false,
            loader: false
          }
        }
      };
    case actions.CREATE_GRIEVANCE_DETAILS_FAILURE:
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances.grievances,
            loader: false
          }
        }
      }
    case actions.UPDATE_GRIEVANCE_DETAILS:
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances.grievances,
            loader: true
          }
        }
      }
    case actions.UPDATE_GRIEVANCE_DETAILS_SUCCESS:
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances.grievances,
            editData: action.payload.data,
            loader: false,
            modalVisible: false
          }
        }
      };
    case actions.UPDATE_GRIEVANCE_DETAILS_FAILURE:
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances.grievances,
            loader: false
          }
        }
      }
    case actions.CREATE_GRIEVANCE_STEP_DETAILS:
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances.grievances,
            loader: true
          }
        }
      }
    case actions.CREATE_GRIEVANCE_STEP_DETAILS_SUCCESS:
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances.grievances,
            editData: action.payload.data,
            modalVisible: false,
            loader: false
          }
        }
      };
    case actions.CREATE_GRIEVANCE_STEP_DETAILS_FAILURE:
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances.grievances,
            loader: false
          }
        }
      }
    case actions.UPDATE_GRIEVANCE_STEP_DETAILS:
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances.grievances,
            loader: true
          }
        }
      }
    case actions.UPDATE_GRIEVANCE_STEP_DETAILS_SUCCESS:
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances.grievances,
            editData: action.payload.data,
            loader: false,
            modalVisible: false
          }
        }
      };
    case actions.UPDATE_GRIEVANCE_STEP_DETAILS_FAILURE:
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances.grievances,
            loader: false
          }
        }
      }
    case actions.DELETE_GRIEVANCE_DETAILS:
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances.grievances,
            deleteLoader: false
          }
        }
      }
    case actions.DELETE_GRIEVANCE_DETAILS_SUCCESS:
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances.grievances,
            deleteLoader: false
          }
        }
      }
    case actions.DELETE_GRIEVANCE_DETAILS_FAILURE:
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            ...state.employeeGrievances.grievances,
            deleteLoader: false
          }
        }
      }
    case actions.SHOW_GRIEVANCE_MODAL_VISIBLE:
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            modalVisible: action.payload
          }
        }
      }
    case actions.SET_SEARCH_CASE_NUMBER_IN_GRIEVANCE:
      return {
        ...state,
        employeeGrievances: {
          ...state.employeeGrievances,
          grievances: {
            searchNumberValue: action.searchCaseNoValue
          }
        }
      };
    default:
      return state;
  }
}

import actions from './actions';
import { getSubDomain } from '../../shared/helper';

const initState = {
  loginLoader: false,
  forgotPasswordLoader: false,
  resetPasswordLoader: false,
  isLoggedIn: false,
  validatingAuthToken: false,
  permissionsLoader: false,
  userPermissionsLists: [],
  saasLoader: false,
  saasData: false,
  isShowErrorPage: false,
  isSubDomainInvalid: false,
  loginTime: undefined,
  isSuperAccount: false,
  allowedAccounts: [],
  superUserJson: undefined,
  roleName: undefined
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.CHECK_AUTHORIZATION:
      return {
        ...state,
        validatingAuthToken: true,
        isSubDomainInvalid: false,
        loginTime: undefined
      };
    case actions.AUTHORIZATION_SUCCESS:
      return {
        ...state,
        validatingAuthToken: false,
        isLoggedIn: true,
        isSubDomainInvalid: false,
        loginTime: new Date(localStorage.getItem(`${getSubDomain()}LoginTime`)),
        isSuperAccount: action.payload.attributes.super_account,
        allowedAccounts: action.payload.attributes.allowed_accounts,
        superUserJson: action.payload.attributes.saas_json,
        roleName: action.payload.attributes.role_name
      };
    case actions.AUTHORIZATION_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        validatingAuthToken: false,
        isSubDomainInvalid: action.isSubDomainInvalid,
        loginTime: undefined
      };
    case actions.LOGIN:
      return {
        ...state,
        loginLoader: true,
        isLoggedIn: false
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        loginLoader: false,
        isLoggedIn: true,
        loginTime: new Date(localStorage.getItem(`${getSubDomain()}LoginTime`))
      };
    case actions.LOGIN_FAILURE:
      return {
        ...state,
        loginLoader: false,
        isLoggedIn: false
      };
    case actions.FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordLoader: true
      };
    case actions.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordLoader: false
      };
    case actions.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordLoader: false
      };
    case actions.RESET_PASSWORD:
      return {
        ...state,
        resetPasswordLoader: true
      };
    case actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordLoader: false
      };
    case actions.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordLoader: false
      };
    case actions.LOGOUT_SUCCESS:
      localStorage.setItem(`${getSubDomain()}LoginTime`, '');
      return initState;
    case actions.FETCH_USER_PERMISSIONS:
      return {
        ...state,
        permissionsLoader: true
      };
    case actions.FETCH_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionsLoader: false,
        userPermissionsLists: action.payload && action.payload.data,
        isShowErrorPage: state.isShowErrorPage
      };
    case actions.FETCH_USER_PERMISSIONS_FAILURE:
      return {
        ...state,
        permissionsLoader: false,
        isShowErrorPage: true
      };
    case actions.GET_SAAS_DATA:
      return {
        ...state,
        saasLoader: true
      };
    case actions.GET_SAAS_DATA_SUCCESS:
      const { payload } = action;
      return {
        ...state,
        saasLoader: false,
        saasData: payload,
        isShowErrorPage: state.isShowErrorPage
      };
    case actions.GET_SAAS_DATA_FAILURE:
      return {
        ...state,
        saasLoader: false,
        isShowErrorPage: true
      };
    case actions.REFRESH_AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        loginTime: new Date(localStorage.getItem(`${getSubDomain()}LoginTime`))
      };
    default:
      return state;
  }
}

import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import {
  unAuthorisedRedirection,
  showErrorMessages,
  getPromise
} from '../../shared/helper';
import settings from '../../settings';

export function* fetchUserAuditDetails(params) {
  const { page } = params;
  const searchValue = params?.searchValue
    ? params.searchValue
    : '';
  const dateFilter = !!params?.dateFilter?.length
    ? params.dateFilter
    : '';

  try {
    const url = `${settings.ROOT_URL}user_audits?page=${page}${
      searchValue ? `&search_text=${searchValue}` : ''}${!!dateFilter.length ? 
      `&search_date_from=${dateFilter[0]}&search_date_to=${dateFilter[1]}` : ''}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_USER_AUDIT_DETAILS_SUCCESS,
      payload: data
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_USER_AUDIT_DETAILS_FAILURE
    });
    showErrorMessages(error);
  }
}

export function* fetchUserDetails(params) {
  const { slug } = params;
  try {
    const url = `${settings.ROOT_URL}user_audits/${slug}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_USER_DETAILS_SUCCESS,
      payload: data
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_USER_DETAILS_FAILURE
    });
    showErrorMessages(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_USER_AUDIT_DETAILS, fetchUserAuditDetails),
    takeEvery(actions.FETCH_USER_DETAILS, fetchUserDetails)
  ]);
}

const actions = {
  FETCH_MAIL_LOG_DETAILS: 'FETCH_MAIL_LOG_DETAILS',
  FETCH_MAIL_LOG_DETAILS_SUCCESS: 'FETCH_MAIL_LOG_DETAILS_SUCCESS',
  FETCH_MAIL_LOG_DETAILS_FAILURE: 'FETCH_MAIL_LOG_DETAILS_FAILURE',
  SET_MAIL_LOG_MODAL_VISIBLE: 'SET_MAIL_LOG_MODAL_VISIBLE',
  CREATE_MAIL_LOG_DETAILS: 'CREATE_MAIL_LOG_DETAILS',
  CREATE_MAIL_LOG_DETAILS_SUCCESS: 'CREATE_MAIL_LOG_DETAILS_SUCCESS',
  CREATE_MAIL_LOG_DETAILS_FAILURE: 'CREATE_MAIL_LOG_DETAILS_FAILURE',
  UPDATE_MAIL_LOG_DETAILS: 'UPDATE_MAIL_LOG_DETAILS',
  UPDATE_MAIL_LOG_DETAILS_SUCCESS: 'UPDATE_MAIL_LOG_DETAILS_SUCCESS',
  UPDATE_MAIL_LOG_DETAILS_FAILURE: 'UPDATE_MAIL_LOG_DETAILS_FAILURE',
  DELETE_MAIL_LOG_DETAILS : 'DELETE_MAIL_LOG_DETAILS',
  DELETE_MAIL_LOG_DETAILS_SUCCESS : 'DELETE_MAIL_LOG_DETAILS_SUCCESS',
  DELETE_MAIL_LOG_DETAILS_FAILURE : 'DELETE_MAIL_LOG_DETAILS_FAILURE'
};

export default actions;

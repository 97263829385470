export const memberSelectOptionsDefaultKeys = [
  'genders',
  'units',
  'maritalStatuses',
  'affiliation',
  'tourOfDuty',
  'platoons'
];

export const statusComponentActionNames = {
  employeeOffices: 'COMMANDS',
  employeeFacilities: 'FACILITIES',
  employeeRanks: 'RANKS',
  employeeEmploymentStatuses: 'EMPLOYMENT_STATUSES',
  employeeOfficerStatuses: 'OFFICER_STATUSES',
  employeePositions: 'POSITIONS',
  delegateAssignments: 'DELEGATE_ASSIGNMENTS',
  employeeDepartments: 'DEPARTMENTS',
  employeeSections: 'SECTIONS',
  employeeTitles: 'TITLES',
  genders: 'GENDERS',
  maritalStatuses: 'MARITAL_STATUSES',
  units: 'UNITS',
  firearmStatuses: 'FIREARM_STATUSES',
  employeePacfs: 'EMPLOYEE_PACF'
};

export const statusComponentDefaultActions = [
  'FETCH_DETAILS',
  'FETCH_DETAILS_SUCCESS',
  'FETCH_DETAILS_FAILURE',
  'CREATE',
  'CREATE_SUCCESS',
  'CREATE_FAILURE',
  'UPDATE',
  'UPDATE_SUCCESS',
  'UPDATE_FAILURE',
  'DELETE_DETAILS',
  'DELETE_DETAILS_SUCCESS',
  'DELETE_DETAILS_FAILURE',
  'DETAILS'
];

export const statusComponentKeyValues = {
  employeeRanks: {
    apiKey: 'employee_ranks',
    keyType: 'employee_rank',
    keyName: 'employeeRanks'
  },
  employeeOffices: {
    apiKey: 'employee_offices',
    keyType: 'employee_office',
    keyName: 'employeeOffices'
  },
  employeeFacilities: {
    apiKey: 'employee_facilities',
    keyType: 'employee_facility',
    keyName: 'employeeFacilities'
  },
  employeePositions: {
    apiKey: 'employee_positions',
    keyType: 'employee_position',
    keyName: 'employeePositions'
  },
  employeeEmploymentStatuses: {
    apiKey: 'employee_employment_statuses',
    keyType: 'employee_employment_status',
    keyName: 'employeeEmploymentStatuses'
  },
  employeeOfficerStatuses: {
    apiKey: 'employee_officer_statuses',
    keyType: 'employee_officer_status',
    keyName: 'employeeOfficerStatuses'
  },
  delegateAssignments: {
    apiKey: 'delegate_assignments',
    keyType: 'delegate_assignment',
    keyName: 'delegateAssignments'
  },
  employeeDepartments: {
    apiKey: 'employee_departments',
    keyType: 'employee_department',
    keyName: 'employeeDepartments'
  },
  employeeSections: {
    apiKey: 'employee_sections',
    keyType: 'employee_section',
    keyName: 'employeeSections'
  },
  employeeTitles: {
    apiKey: 'employee_titles',
    keyType: 'employee_title',
    keyName: 'employeeTitles'
  }
};

export const memberListActionNames = {
  memberList: 'MEMBER_LIST',
  firearmStatuses: 'FIREARM_STATUSES',
  firearmRangeScores: 'FIREARM_RANGE_SCORES',
  pacfs: 'EMPLOYEE_PACF',
  awards: 'AWARDS',
  meetingTypes: 'UNIONS',
  benefits: 'BENEFITS',
  beneficiaries: 'BENEFICIARIES',
  benefitCoverages: 'BENEFIT_COVERAGES',
  benefitDisbursements: 'BENEFIT_DISBURSEMENTS',
  uploads: 'UPLOADS',
  disciplineSettings: 'EMPLOYEE_DISCIPLINES',
  grievances: 'EMPLOYEE_GRIEVANCES',
  disabilities: 'DISABILITY',
  compTime: 'COMPTIME',
  overTime: 'OVERTIME',
  personal: 'PERSONAL',
  sickBank: 'SICK_BANK',
  vacation: 'VACATION',
  ava: 'AVA',
  employeeLodis: 'EMPLOYEE_LODIS',
  workersComp: 'WORKERS_COMP',
  affiliation: 'AFFILIATION',
  tourOfDuty: 'TOUR_OF_DUTY',
  disciplineSteps: 'EMPLOYEE_DISCIPLINE_STEPS',
  grievanceSteps: 'EMPLOYEE_GRIEVANCE_STEPS',
  lifeInsurance: 'LIFE_INSURANCE',
  dependent: 'DEPENDENTS',
  lodisTab: 'EMPLOYEE_LODIS_TAB',
  analyticsConfiguration: 'ANALYTICS_CONFIGURATION',
  employees: 'MAILING_ADDRESS',
  peshes: 'PESH',
  assaults: 'ASSAULTS',
  notesSection: 'NOTES_SECTION'
};

export const memberListDefaultActions = [
  'FETCH_TABLE_DETAILS',
  'FETCH_TABLE_DETAILS_SUCCESS',
  'FETCH_TABLE_DETAILS_FAILURE',
  'CREATE',
  'CREATE_SUCCESS',
  'CREATE_FAILURE',
  'UPDATE',
  'UPDATE_SUCCESS',
  'UPDATE_FAILURE',
  'DELETE_DATA_IN_TABLE',
  'DELETE_DATA_IN_TABLE_SUCCESS',
  'DELETE_DATA_IN_TABLE_FAILURE',
  'GET_DETAILS',
  'GET_DETAILS_SUCCESS',
  'GET_DETAILS_FAILURE',
  'TAB_DETAILS'
];

export const memberListKeyValues = {
  memberList: {
    apiKey: 'employees',
    keyType: 'employee',
    keyName: 'memberList',
    permissionKeys: ['readEmployee', 'writeEmployee']
  },
  notesSection: {
    apiKey: 'notes_section',
    keyType: 'notes_section',
    keyName: 'notesSection',
    permissionKeys: ['writePlacardOnly', ''],
    messageKey: ''
  },
  firearmStatuses: {
    apiKey: 'employee_firearm_statuses',
    keyType: 'employee_firearm_status',
    keyName: 'firearmStatuses',
    permissionKeys: ['readEmployeeFirearmStatus', 'writeEmployeeFirearmStatus']
  },
  firearmRangeScores: {
    apiKey: 'firearm_range_scores',
    keyType: 'firearm_range_score',
    keyName: 'firearmRangeScores'
  },
  pacfs: {
    apiKey: 'employee_pacfs',
    keyType: 'employee_pacf',
    keyName: 'pacfs',
    permissionKeys: ['readEmployeePaf', 'writeEmployeePaf']
  },
  awards: {
    apiKey: 'awards',
    keyType: 'award',
    keyName: 'awards',
    permissionKeys: ['readEmployeeAward', 'writeEmployeeAward']
  },
  meetingTypes: {
    apiKey: 'employee_meeting_types',
    keyType: 'employee_meeting_type',
    keyName: 'meetingTypes',
    permissionKeys: ['readEmployeeMeetingType', 'writeEmployeeMeetingType']
  },
  benefits: {
    apiKey: 'employee_benefits',
    keyType: 'employee_benefit',
    keyName: 'benefits',
    permissionKeys: ['readEmployeeBenefit', 'writeEmployeeBenefit']
  },
  beneficiaries: {
    apiKey: 'beneficiaries',
    keyType: 'beneficiary',
    keyName: 'beneficiaries'
  },
  benefitCoverages: {
    apiKey: 'benefit_coverages',
    keyType: 'benefit_coverage',
    keyName: 'benefitCoverages'
  },
  benefitDisbursements: {
    apiKey: 'benefit_disbursements',
    keyType: 'benefit_disbursement',
    keyName: 'benefitDisbursements'
  },
  disabilities: {
    apiKey: 'disabilities',
    keyType: 'disability',
    keyName: 'disabilities'
  },
  uploads: {
    apiKey: 'uploads',
    keyType: 'upload',
    keyName: 'uploads',
    permissionKeys: ['readEmployeeUpload', 'writeEmployeeUpload']
  },
  disciplineSettings: {
    apiKey: 'employee_discipline_settings',
    keyType: 'employee_discipline_setting',
    keyName: 'disciplineSettings',
    permissionKeys: ['readEmployeeDiscipline', 'writeEmployeeDiscipline']
  },
  disciplineSteps: {
    apiKey: 'employee_discipline_steps',
    keyType: 'employee_discipline_step',
    keyName: 'disciplineSteps'
  },
  grievances: {
    apiKey: 'employee_grievances',
    keyType: 'employee_grievance',
    keyName: 'grievances',
    permissionKeys: ['readEmployeeGrievance', 'writeEmployeeGrievance']
  },
  grievanceSteps: {
    apiKey: 'employee_grievance_steps',
    keyType: 'employee_grievance_step',
    keyName: 'grievanceSteps'
  },
  compTime: {
    apiKey: 'leaves',
    keyType: 'leave',
    keyName: 'compTime',
    leaveType: 'comp_time'
  },
  overTime: {
    apiKey: 'leaves',
    keyType: 'leave',
    keyName: 'overTime',
    leaveType: 'overtime'
  },
  personal: {
    apiKey: 'leaves',
    keyType: 'leave',
    keyName: 'personal',
    leaveType: 'personal'
  },
  sickBank: {
    apiKey: 'leaves',
    keyType: 'leave',
    keyName: 'sickBank',
    leaveType: 'sick'
  },
  ava: {
    apiKey: 'leaves',
    keyType: 'leave',
    keyName: 'ava',
    leaveType: 'ava'
  },
  vacation: {
    apiKey: 'leaves',
    keyType: 'leave',
    keyName: 'vacation',
    leaveType: 'vacation'
  },
  employeeLodis: {
    apiKey: 'lodis',
    keyType: 'lodi',
    keyName: 'employeeLodis',
    lodiType: 'lodi'
  },
  workersComp: {
    apiKey: 'lodis',
    keyType: 'lodi',
    keyName: 'workersComp',
    lodiType: 'workers_comp'
  },
  employeeAnalytics: {
    title: 'Analytics',
    permissionKeys: ['readEmployeeAnalytics', 'writeEmployeeAnalytics']
  },
  lifeInsurance: {
    apiKey: 'life_insurances',
    keyType: 'life_insurance',
    keyName: 'lifeInsurance'
  },
  dependent: {
    apiKey: 'dependents',
    keyType: 'dependent',
    keyName: 'dependent'
  },
  lodisTab: {
    apiKey: 'lodi_request_tabs',
    keyType: 'lodi_request_tab',
    keyName: 'lodisTab'
  },
  changeRequests: {
    apiKey: 'change_requests',
    keyType: 'change_requests',
    keyName: 'changeRequests',
    permissionKeys: ['writeChangeRequest', 'readChangeRequest']
  },
  analyticsConfiguration: {
    apiKey: 'analytics',
    keyType: 'analytics_configurations',
    keyName: 'analyticsConfiguration',
    permissionKeys: ['writeAnalyticsConfiguration', 'readAnalyticsConfiguration']
  },
  legislativeAddresses: {
    apiKey: 'legislative_addresses',
    keyType: 'legislative_addresses',
    keyName: 'Legislation',
    permissionKeys: ['writeLegislativeDetails', 'readLegislativeDetails']
  },
  peshes: {
    apiKey: 'peshes',
    keyType: 'pesh',
    keyName: 'peshes',
    permissionKeys: ['writePesh', 'readPesh']
  },
  assaults: {
    apiKey: 'assaults',
    keyType: 'assault',
    keyName: 'assaults',
    permissionKeys: ['writeAssault', 'readAssault']
  },
  mailLog: {
    apiKey: 'maillogs',
    keyType: 'maillog',
    keyName: 'mailLog',
    permissionKeys: ['readMailLog', 'writeMailLog']
  },
  userAudit: {
    apiKey: 'userAudit',
    keyType: 'userAudit',
    keyName: 'userAudit',
    permissionKeys: ['readUserAudit', 'writeUserAudit']
  }
};

export const legislativeList = [
  'county',
  'congress',
  'assembly',
  'senate',
  'council',
  'comptroller',
  'executive',
  'district_attorney',
  'attorney'
]

export const searchType = [
  { value: 'general', label: 'General' },
  { value: 'specific', label: 'Specific' }
]
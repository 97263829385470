import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import {
  showErrorMessages,
  getPromise,
  unAuthorisedRedirection
} from '../../shared/helper';
import { selectOptionsActionNames, selectOptionsKeyValues } from '../../components/constants';
import { setKeyValue } from '../../shared/helper';

export function* temporary(params) {}

export function* fetchSelectOptions(params) {
  const { keyValues, searchValue, page, getMoreOptions } = params,
    { keyName, selectOptionsApiKey } = keyValues;
  try {
    const url = `${settings.ROOT_URL}${selectOptionsApiKey}?${
      params.employeeId ? `employee_id=${params.employeeId}&` : ''}${
      keyValues?.category ? `category=${keyValues.category}&` : ''}${
        searchValue
          ? `search_text=${searchValue}`
          : `${page ? `page=${page}` : 'page=1'}&per_page=1000`
      }`,
      outputData = yield call(() => getPromise(url)),
      data = outputData.data;
    let selectOptions = [];
    if (data.city || data.state || data.zipcode || data.county) {
      const city = setKeyValue(data.city),
        state = setKeyValue(data.state),
        zipcode = setKeyValue(data.zipcode),
        county = setKeyValue(data.county);
      selectOptions.push({ city: city, state: state, zipcode: zipcode, county: county });
    } else if (keyName === 'deniedReasons') {
      selectOptions = data.map(data => ({
        key: data.id,
        value: data.attributes.name || data.attributes.insufficient_reason,
        insufficient_reason: data.attributes.insufficient_reason || false
      }))
    } else {
      selectOptions = data.map(data => ({
        key: data.attributes.id,
        value: data.attributes.name,
        departmentId: data.attributes.department_id
          ? data.attributes.department_id
          : undefined,
        sectionId: data.attributes.section_id
          ? data.attributes.section_id
          : undefined,
        title_code: data.attributes.title_code
          ? data.attributes.title_code
          : undefined
      }));
    }
    if (getMoreOptions) {
      yield put({
        type:
          actions[`${selectOptionsActionNames[keyName]}_APPEND_SELECT_OPTIONS`],
        payload: selectOptions,
        keyValues
      });
    } else {
      yield put({
        type:
          actions[
            `${selectOptionsActionNames[keyName]}_FETCH_SELECT_OPTIONS_SUCCESS`
          ],
        payload: selectOptions,
        keyValues
      });
    }
    if (
      keyName === 'employeePositions'  &&
      selectOptions.find(data => data.value.toLowerCase() === 'delegate')
    ) {
      yield put({
        type: actions[
          `${selectOptionsActionNames['delegateSeries']}_FETCH_SELECT_OPTIONS`
        ],
        keyValues: selectOptionsKeyValues.delegateSeries
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type:
        actions[
          `${selectOptionsActionNames[keyName]}_FETCH_SELECT_OPTIONS_FAILURE`
        ],
      keyValues
    });
    showErrorMessages(error);
  }
}

export function* fetchLegislativeSelectOptions(params) {
  const { keyValues } = params,
    { keyName,selectOptionsApiKey } = keyValues;
  try {
    const url = `${settings.ROOT_URL}${selectOptionsApiKey}`,
      data = yield call(() => getPromise(url));

    yield put({
      type:
          actions[
              `${selectOptionsActionNames[keyName]}_FETCH_SELECT_OPTIONS_SUCCESS`
          ],
      payload: data?.data,
      keyValues
    });
  }
  catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type:
          actions[
              `${selectOptionsActionNames[keyName]}_FETCH_SELECT_OPTIONS_FAILURE`
          ],
      keyValues
    });
    showErrorMessages(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_NAVBAR_TOGGLED_STATUS, temporary),

    takeEvery(actions.POSITIONS_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(actions.COMMANDS_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(actions.RANKS_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(
      actions.EMPLOYMENT_STATUSES_FETCH_SELECT_OPTIONS,
      fetchSelectOptions,
    ),
    takeEvery(
      actions.OFFICER_STATUSES_FETCH_SELECT_OPTIONS,
      fetchSelectOptions,
    ),
    takeEvery(
      actions.DELEGATE_ASSIGNMENTS_FETCH_SELECT_OPTIONS,
      fetchSelectOptions,
    ),
    takeEvery(
      actions.DELEGATE_EMPLOYEES_FETCH_SELECT_OPTIONS,
      fetchSelectOptions,
    ),
    takeEvery(actions.DEPARTMENTS_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(actions.SECTIONS_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(actions.TITLES_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(actions.UNITS_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(actions.GENDERS_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(
      actions.MARITAL_STATUSES_FETCH_SELECT_OPTIONS,
      fetchSelectOptions,
    ),
    takeEvery(
      actions.FIREARM_STATUSES_FETCH_SELECT_OPTIONS,
      fetchSelectOptions,
    ),
    takeEvery(actions.EMPLOYEE_PACF_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(actions.BENEFITS_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(actions.PAYMENT_TYPES_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(
      actions.EMPLOYEE_DISCIPLINES_FETCH_SELECT_OPTIONS,
      fetchSelectOptions,
    ),
    takeEvery(
      actions.EMPLOYEE_GRIEVANCES_FETCH_SELECT_OPTIONS,
      fetchSelectOptions,
    ),
    takeEvery(actions.EMPLOYEES_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(actions.ADDRESS_DETAILS_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(actions.UNION_MEETINGS_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(actions.DISCIPLINES_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(actions.GRIEVANCES_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(actions.USER_ROLES_LIST_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(actions.AFFILIATION_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(actions.TOUR_OF_DUTY_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(actions.PLATOONS_FETCH_SELECT_OPTIONS, fetchSelectOptions),
    takeEvery(
      actions.EMPLOYEE_DISCIPLINE_CHARGES_FETCH_SELECT_OPTIONS,
      fetchSelectOptions,
    ),
    takeEvery(
      actions.EMPLOYEE_DISCIPLINE_STATUSES_FETCH_SELECT_OPTIONS,
      fetchSelectOptions,
    ),
    takeEvery(
      actions.EMPLOYEE_GRIEVANCE_CHARGES_FETCH_SELECT_OPTIONS,
      fetchSelectOptions,
    ),
    takeEvery(
      actions.EMPLOYEE_GRIEVANCE_STATUSES_FETCH_SELECT_OPTIONS,
      fetchSelectOptions,
    ),
    takeEvery(
      actions.DELEGATE_SERIES_FETCH_SELECT_OPTIONS,
      fetchSelectOptions,
    ),
    takeEvery(
      actions.DENIED_REASONS_FETCH_SELECT_OPTIONS,
      fetchSelectOptions,
    ),
    takeEvery(
      actions.STAFF_MEMBER_EMPLOYEE_FETCH_SELECT_OPTIONS,
      fetchSelectOptions,
    ),
    takeEvery(
      actions.FACILITIES_FETCH_SELECT_OPTIONS,
      fetchSelectOptions,
    ),
    takeEvery(
      actions.LEGISLATIVE_DETAILS_FETCH_SELECT_OPTIONS,
      fetchLegislativeSelectOptions,
    )
  ]);
}

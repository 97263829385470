import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings/index';
import {
  getPromise,
  postPromise,
  patchPromise,
  deletePromise,
  unAuthorisedRedirection,
  showErrorMessages, 
  convertIntoPlural
} from '../../shared/helper';
import { message } from 'antd';
import { settingsActionNames } from '../../components/settings/constant';
import { store } from '../store';

export function* fetchTableDetails(params) {
  const { keyValues } = params,
    { keyName, apiKey } = keyValues;
  let { searchValue } = store.getState().Settings[`${keyName}`];
  if (params.searchValue) {
    searchValue = params.searchValue;
  }
  try {
    let url = `${settings.ROOT_URL}${apiKey}?page=${params.page}${
      params.perPage && params.perPage !== ''
        ? `&per_page=${params.perPage}`
        : ''
    }${
      searchValue ? `&search_text=${searchValue}` : ''
    }`;
    const data = yield call(() => getPromise(url));

    const selectOptions =
      params.perPage && params.perPage !== ''
        ? data.data.map(data => ({
          key: data.attributes.id,
          value: data.attributes.name
        }))
        : '';

    yield put({
      type:
        actions[
          `${settingsActionNames[keyName]}_SETTINGS_FETCH_TABLE_DETAILS_SUCCESS`
        ],
      payload: data,
      selectOptions: selectOptions,
      keyValues
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type:
        actions[
          `${settingsActionNames[keyName]}_SETTINGS_FETCH_TABLE_DETAILS_FAILURE`
        ],
      keyValues
    });
    showErrorMessages(error, keyValues['keyName']);
  }
}

export function* createData(params) {
  const { keyValues, currentPage } = params,
    { keyName, apiKey, messageKey } = keyValues;
  try {
    let url = `${settings.ROOT_URL}${apiKey}`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions[`${settingsActionNames[keyName]}_SETTINGS_CREATE_SUCCESS`],
      payload: data.data,
      keyValues,
      modalVisible: false
    });
    store.dispatch({
      type:
        actions[`${settingsActionNames[keyName]}_SETTINGS_FETCH_TABLE_DETAILS`],
      page: currentPage,
      keyValues
    });
    message.success(`${messageKey === 'Annual Due' ? 
      convertIntoPlural(messageKey) : messageKey} created successfully`);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions[`${settingsActionNames[keyName]}_SETTINGS_CREATE_FAILURE`],
      keyValues
    });
    showErrorMessages(error, keyValues['keyName']);
  }
}

export function* updateData(params) {
  const { keyValues } = params,
    { keyName, apiKey, messageKey } = keyValues;
  try {
    let url = `${settings.ROOT_URL}${apiKey}/${params.slug}`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions[`${settingsActionNames[keyName]}_SETTINGS_UPDATE_SUCCESS`],
      payload: data.data,
      keyValues,
      modalVisible: false
    });
    message.success(`${messageKey === 'Annual Due' ?
      convertIntoPlural(messageKey) : messageKey} updated successfully`);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions[`${settingsActionNames[keyName]}_SETTINGS_UPDATE_FAILURE`],
      keyValues
    });
    showErrorMessages(error, keyValues['keyName']);
  }
}

export function* deleteData(params) {
  const { keyValues, pagination, slug } = params,
    { keyName, apiKey, messageKey } = keyValues,
    { current } = pagination;
  try {
    let url = `${settings.ROOT_URL}${apiKey}/${slug}`;
    yield call(() => deletePromise(url));
    message.success(`${messageKey === 'Annual Due' ?
      convertIntoPlural(messageKey) : messageKey} deleted successfully`);
    yield put({
      type:
        actions[
          `${settingsActionNames[keyName]}_SETTINGS_DELETE_DATA_IN_TABLE_SUCCESS`
        ],
      keyValues
    });
    store.dispatch({
      type:
        actions[`${settingsActionNames[keyName]}_SETTINGS_FETCH_TABLE_DETAILS`],
      page: current,
      keyValues
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type:
        actions[
          `${settingsActionNames[keyName]}_SETTINGS_DELETE_DATA_IN_TABLE_FAILURE`
        ],
      keyValues
    });
    showErrorMessages(error, keyValues['keyName']);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.BENEFIT_SETTINGS_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.BENEFIT_SETTINGS_CREATE, createData),
    takeEvery(actions.BENEFIT_SETTINGS_UPDATE, updateData),
    takeEvery(actions.BENEFIT_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(
      actions.DEPARTMENT_SETTINGS_FETCH_TABLE_DETAILS,
      fetchTableDetails,
    ),
    takeEvery(actions.DEPARTMENT_SETTINGS_CREATE, createData),
    takeEvery(actions.DEPARTMENT_SETTINGS_UPDATE, updateData),
    takeEvery(actions.DEPARTMENT_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(actions.COMMAND_SETTINGS_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.COMMAND_SETTINGS_CREATE, createData),
    takeEvery(actions.COMMAND_SETTINGS_UPDATE, updateData),
    takeEvery(actions.COMMAND_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(actions.PAF_SETTINGS_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.PAF_SETTINGS_CREATE, createData),
    takeEvery(actions.PAF_SETTINGS_UPDATE, updateData),
    takeEvery(actions.PAF_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(actions.RANK_SETTINGS_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.RANK_SETTINGS_CREATE, createData),
    takeEvery(actions.RANK_SETTINGS_UPDATE, updateData),
    takeEvery(actions.RANK_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(
      actions.FIREARM_STATUS_SETTINGS_FETCH_TABLE_DETAILS,
      fetchTableDetails,
    ),
    takeEvery(actions.FIREARM_STATUS_SETTINGS_CREATE, createData),
    takeEvery(actions.FIREARM_STATUS_SETTINGS_UPDATE, updateData),
    takeEvery(actions.FIREARM_STATUS_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(
      actions.EMPLOYMENT_STATUS_SETTINGS_FETCH_TABLE_DETAILS,
      fetchTableDetails,
    ),
    takeEvery(actions.EMPLOYMENT_STATUS_SETTINGS_CREATE, createData),
    takeEvery(actions.EMPLOYMENT_STATUS_SETTINGS_UPDATE, updateData),
    takeEvery(
      actions.EMPLOYMENT_STATUS_SETTINGS_DELETE_DATA_IN_TABLE,
      deleteData,
    ),

    takeEvery(
      actions.OFFICER_STATUS_SETTINGS_FETCH_TABLE_DETAILS,
      fetchTableDetails,
    ),
    takeEvery(actions.OFFICER_STATUS_SETTINGS_CREATE, createData),
    takeEvery(actions.OFFICER_STATUS_SETTINGS_UPDATE, updateData),
    takeEvery(actions.OFFICER_STATUS_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(
      actions.MARITAL_STATUS_SETTINGS_FETCH_TABLE_DETAILS,
      fetchTableDetails,
    ),
    takeEvery(actions.MARITAL_STATUS_SETTINGS_CREATE, createData),
    takeEvery(actions.MARITAL_STATUS_SETTINGS_UPDATE, updateData),
    takeEvery(actions.MARITAL_STATUS_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(actions.POSITION_SETTINGS_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.POSITION_SETTINGS_CREATE, createData),
    takeEvery(actions.POSITION_SETTINGS_UPDATE, updateData),
    takeEvery(actions.POSITION_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(actions.FACILITIES_SETTINGS_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.FACILITIES_SETTINGS_CREATE, createData),
    takeEvery(actions.FACILITIES_SETTINGS_UPDATE, updateData),
    takeEvery(actions.FACILITIES_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(actions.PAYMENT_SETTINGS_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.PAYMENT_SETTINGS_CREATE, createData),
    takeEvery(actions.PAYMENT_SETTINGS_UPDATE, updateData),
    takeEvery(actions.PAYMENT_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(
      actions.DELEGATE_MEETING_SETTINGS_FETCH_TABLE_DETAILS,
      fetchTableDetails,
    ),
    takeEvery(actions.DELEGATE_MEETING_SETTINGS_CREATE, createData),
    takeEvery(actions.DELEGATE_MEETING_SETTINGS_UPDATE, updateData),
    takeEvery(
      actions.DELEGATE_MEETING_SETTINGS_DELETE_DATA_IN_TABLE,
      deleteData,
    ),

    takeEvery(
      actions.DISCIPLINE_SETTINGS_FETCH_TABLE_DETAILS,
      fetchTableDetails,
    ),
    takeEvery(actions.DISCIPLINE_SETTINGS_CREATE, createData),
    takeEvery(actions.DISCIPLINE_SETTINGS_UPDATE, updateData),
    takeEvery(actions.DISCIPLINE_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(
      actions.GRIEVANCE_SETTINGS_FETCH_TABLE_DETAILS,
      fetchTableDetails,
    ),
    takeEvery(actions.GRIEVANCE_SETTINGS_CREATE, createData),
    takeEvery(actions.GRIEVANCE_SETTINGS_UPDATE, updateData),
    takeEvery(actions.GRIEVANCE_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(actions.GENDER_SETTINGS_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.GENDER_SETTINGS_CREATE, createData),
    takeEvery(actions.GENDER_SETTINGS_UPDATE, updateData),
    takeEvery(actions.GENDER_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(actions.UNIT_SETTINGS_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.UNIT_SETTINGS_CREATE, createData),
    takeEvery(actions.UNIT_SETTINGS_UPDATE, updateData),
    takeEvery(actions.UNIT_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(actions.SECTION_SETTINGS_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.SECTION_SETTINGS_CREATE, createData),
    takeEvery(actions.SECTION_SETTINGS_UPDATE, updateData),
    takeEvery(actions.SECTION_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(actions.TITLE_SETTINGS_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.TITLE_SETTINGS_CREATE, createData),
    takeEvery(actions.TITLE_SETTINGS_UPDATE, updateData),
    takeEvery(actions.TITLE_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(
      actions.AFFILIATION_SETTINGS_FETCH_TABLE_DETAILS,
      fetchTableDetails,
    ),
    takeEvery(actions.AFFILIATION_SETTINGS_CREATE, createData),
    takeEvery(actions.AFFILIATION_SETTINGS_UPDATE, updateData),
    takeEvery(actions.AFFILIATION_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(
      actions.TOUR_OF_DUTY_SETTINGS_FETCH_TABLE_DETAILS,
      fetchTableDetails,
    ),
    takeEvery(actions.TOUR_OF_DUTY_SETTINGS_CREATE, createData),
    takeEvery(actions.TOUR_OF_DUTY_SETTINGS_UPDATE, updateData),
    takeEvery(actions.TOUR_OF_DUTY_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(actions.PLATOONS_SETTINGS_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.PLATOONS_SETTINGS_CREATE, createData),
    takeEvery(actions.PLATOONS_SETTINGS_UPDATE, updateData),
    takeEvery(actions.PLATOONS_SETTINGS_DELETE_DATA_IN_TABLE, deleteData),

    takeEvery(
      actions.DISCIPLINE_CHARGES_SETTINGS_FETCH_TABLE_DETAILS,
      fetchTableDetails,
    ),
    takeEvery(actions.DISCIPLINE_CHARGES_SETTINGS_CREATE, createData),
    takeEvery(actions.DISCIPLINE_CHARGES_SETTINGS_UPDATE, updateData),
    takeEvery(
      actions.DISCIPLINE_CHARGES_SETTINGS_DELETE_DATA_IN_TABLE,
      deleteData,
    ),

    takeEvery(
      actions.DISCIPLINE_STATUSES_SETTINGS_FETCH_TABLE_DETAILS,
      fetchTableDetails,
    ),
    takeEvery(actions.DISCIPLINE_STATUSES_SETTINGS_CREATE, createData),
    takeEvery(actions.DISCIPLINE_STATUSES_SETTINGS_UPDATE, updateData),
    takeEvery(
      actions.DISCIPLINE_STATUSES_SETTINGS_DELETE_DATA_IN_TABLE,
      deleteData,
    ),

    takeEvery(
      actions.GRIEVANCE_CHARGES_SETTINGS_FETCH_TABLE_DETAILS,
      fetchTableDetails,
    ),
    takeEvery(actions.GRIEVANCE_CHARGES_SETTINGS_CREATE, createData),
    takeEvery(actions.GRIEVANCE_CHARGES_SETTINGS_UPDATE, updateData),
    takeEvery(
      actions.GRIEVANCE_CHARGES_SETTINGS_DELETE_DATA_IN_TABLE,
      deleteData,
    ),

    takeEvery(
      actions.GRIEVANCE_STATUSES_SETTINGS_FETCH_TABLE_DETAILS,
      fetchTableDetails,
    ),
    takeEvery(actions.GRIEVANCE_STATUSES_SETTINGS_CREATE, createData),
    takeEvery(actions.GRIEVANCE_STATUSES_SETTINGS_UPDATE, updateData),
    takeEvery(
      actions.GRIEVANCE_STATUSES_SETTINGS_DELETE_DATA_IN_TABLE,
      deleteData,
    ),
    takeEvery(
      actions.DELEGATE_SERIES_SETTINGS_FETCH_TABLE_DETAILS,
      fetchTableDetails,
    ),
    takeEvery(actions.DELEGATE_SERIES_SETTINGS_CREATE, createData),
    takeEvery(actions.DELEGATE_SERIES_SETTINGS_UPDATE, updateData),
    takeEvery(
      actions.DELEGATE_SERIES_SETTINGS_DELETE_DATA_IN_TABLE,
      deleteData,
    ),

    takeEvery(
      actions.ANALYTICS_CONFIGURATIONS_SETTINGS_FETCH_TABLE_DETAILS,
      fetchTableDetails,
    ),
    takeEvery(actions.ANALYTICS_CONFIGURATIONS_SETTINGS_CREATE, createData),
    takeEvery(actions.ANALYTICS_CONFIGURATIONS_SETTINGS_UPDATE, updateData),
    takeEvery(
      actions.ANALYTICS_CONFIGURATIONS_SETTINGS_DELETE_DATA_IN_TABLE,
      deleteData,
    )
  ]);
}

import asyncComponent from '../shared/asyncFunction';
import { convertIntoHypenValues, getModuleDetails } from '../shared/helper';

export function getNotificationRoutePath() {
  return [
    {
      path: convertIntoHypenValues(getModuleDetails('ui', 'notification')),
      component: asyncComponent(() => import('../containers/notifications')),
    },
  ];
}

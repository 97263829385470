import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import {
  unAuthorisedRedirection,
  postPromise,
  showErrorMessages,
} from '../../shared/helper';
import settings from '../../settings';

export function* createReport(params) {
  try {
    let url = `${settings.ROOT_URL}reports`;
    yield call(() => postPromise(url, params.payload));
  } catch (error) {
    yield put({
      type: actions.GENERATE_REPORTS_FAILURE,
      reportTab: params.reportTab,
    });
    unAuthorisedRedirection(error);
    showErrorMessages(error, 'reports');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GENERATE_REPORTS, createReport)]);
}

const actions = {
  GET_CONTACT_LIST: 'GET_CONTACT_LIST',
  GET_CONTACT_LIST_SUCCESS: 'GET_CONTACT_LIST_SUCCESS',
  GET_CONTACT_LIST_FAILURE: 'GET_CONTACT_LIST_FAILURE',
  RESET_CONTACT_LIST: 'RESET_CONTACT_LIST',
  CREATE_CONTACT_LIST: 'CREATE_CONTACT_LIST',
  CREATE_CONTACT_LIST_SUCCESS: 'CREATE_CONTACT_LIST_SUCCESS',
  CREATE_CONTACT_LIST_FAILURE: 'CREATE_CONTACT_LIST_FAILURE',
  UPDATE_CONTACT_LIST: 'UPDATE_CONTACT_LIST',
  UPDATE_CONTACT_LIST_SUCCESS: 'UPDATE_CONTACT_LIST_SUCCESS',
  UPDATE_CONTACT_LIST_FAILURE: 'UPDATE_CONTACT_LIST_FAILURE',
  DELETE_CONTACT_LIST: 'DELETE_CONTACT_LIST',
  DELETE_CONTACT_LIST_SUCCESS: 'DELETE_CONTACT_LIST_SUCCESS',
  DELETE_CONTACT_LIST_FAILURE: 'DELETE_CONTACT_LIST_FAILURE',
  GET_CONTACT_DETAILS: 'GET_CONTACT_DETAILS',
  GET_CONTACT_DETAILS_SUCCESS: 'GET_CONTACT_DETAILS_SUCCESS',
  GET_CONTACT_DETAILS_FAILURE: 'GET_CONTACT_DETAILS_FAILURE',
  SET_SEARCH_VALUE_IN_CONTACT: 'SET_SEARCH_VALUE_IN_CONTACT',
  RESET_CONTACT_EDIT_DATA: 'RESET_CONTACT_EDIT_DATA',
  SET_PROFILE_OTHERS_MODAL_VISIBLE: 'SET_PROFILE_OTHERS_MODAL_VISIBLE',
  FETCH_EMPLOYEES_DEPARTMENTS_DETAILS: 'FETCH_EMPLOYEES_DEPARTMENTS_DETAILS',
  FETCH_EMPLOYEES_DEPARTMENTS_DETAILS_SUCCESS: 'FETCH_EMPLOYEES_DEPARTMENTS_DETAILS_SUCCESS',
  FETCH_EMPLOYEES_DEPARTMENTS_DETAILS_FAILURE: 'FETCH_EMPLOYEES_DEPARTMENTS_DETAILS_FAILURE',
  EMPLOYEES_DEPARTMENTS_DELETE_DETAILS: 'EMPLOYEES_DEPARTMENTS_DELETE_DETAILS',
  EMPLOYEES_DEPARTMENTS_DELETE_DETAILS_SUCCESS: 'EMPLOYEES_DEPARTMENTS_DELETE_DETAILS_SUCCESS',
  EMPLOYEES_DEPARTMENTS_DELETE_DETAILS_FAILURE: 'EMPLOYEES_DEPARTMENTS_DELETE_DETAILS_FAILURE',
  EMPLOYEES_DEPARTMENTS_EDIT_DETAILS: 'EMPLOYEES_DEPARTMENTS_EDIT_DETAILS',
  EMPLOYEES_DEPARTMENTS_EDIT_DETAILS_SUCCESS: 'EMPLOYEES_DEPARTMENTS_EDIT_DETAILS_SUCCESS',
  EMPLOYEES_DEPARTMENTS_EDIT_DETAILS_FAILURE: 'EMPLOYEES_DEPARTMENTS_EDIT_DETAILS_FAILURE',
  EMPLOYEES_DEPARTMENTS_CREATE_DETAILS: 'EMPLOYEES_DEPARTMENTS_CREATE_DETAILS',
  EMPLOYEES_DEPARTMENTS_CREATE_DETAILS_SUCCESS: 'EMPLOYEES_DEPARTMENTS_CREATE_DETAILS_SUCCESS',
  EMPLOYEES_DEPARTMENTS_CREATE_DETAILS_FAILURE: 'EMPLOYEES_DEPARTMENTS_CREATE_DETAILS_FAILURE',
  SET_SELECTED_EMPLOYEE_DETAIL: 'SET_SELECTED_EMPLOYEE_DETAIL',
  SET_EMPLOYEE_DETAIL_DATA: 'SET_EMPLOYEE_DETAIL_DATA',
  SET_EMPLOYEE_DETAIL_EDIT_DATA: 'SET_EMPLOYEE_DETAIL_EDIT_DATA',
  FETCH_EMPLOYEES_FACILITY_DETAILS: ' FETCH_EMPLOYEES_FACILITY_DETAILS',
  FETCH_EMPLOYEES_FACILITY_DETAILS_SUCCESS: ' FETCH_EMPLOYEES_FACILITY_DETAILS_SUCCESS',
  FETCH_EMPLOYEES_FACILITY_DETAILS_FAILURE: ' FETCH_EMPLOYEES_FACILITY_DETAILS_FAILURE',
  EMPLOYEES_FACILITY_EDIT_DETAILS: 'EMPLOYEES_FACILITY_EDIT_DETAILS',
  EMPLOYEES_FACILITY_EDIT_DETAILS_SUCCESS: 'EMPLOYEES_FACILITY_EDIT_DETAILS_SUCCESS',
  EMPLOYEES_FACILITY_EDIT_DETAILS_FAILURE: 'EMPLOYEES_FACILITY_EDIT_DETAILS_FAILURE',
  EMPLOYEES_FACILITY_CREATE_DETAILS: 'EMPLOYEES_FACILITY_CREATE_DETAILS',
  EMPLOYEES_FACILITY_CREATE_DETAILS_SUCCESS: 'EMPLOYEES_FACILITY_CREATE_DETAILS_SUCCESS',
  EMPLOYEES_FACILITY_CREATE_DETAILS_FAILURE: 'EMPLOYEES_FACILITY_CREATE_DETAILS_FAILURE',
  EMPLOYEES_FACILITY_DELETE_DETAILS: 'EMPLOYEES_FACILITY_DELETE_DETAILS',
  EMPLOYEES_FACILITY_DELETE_DETAILS_SUCCESS: 'EMPLOYEES_FACILITY_DELETE_DETAILS_SUCESS',
  EMPLOYEES_FACILITY_DELETE_DETAILS_FAILURE: 'EMPLOYEES_FACILITY_DELETE_DETAILS_FAILURE',
  SET_EMPLOYEE_FACILITY_DETAIL_EDIT_DATA: 'SET_EMPLOYEE_FACILITY_DETAIL_EDIT_DATA'
}

export default actions;
import asyncComponent from '../shared/asyncFunction';
import { convertIntoHypenValues, getModuleDetails } from '../shared/helper';

export function getUserAuditRoutePath() {
  return [
    {
      path: convertIntoHypenValues(getModuleDetails('ui', 'user_audit')),
      component: asyncComponent(() => import('../containers/userAudit'))
    }
  ];
}
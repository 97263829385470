import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import {
  unAuthorisedRedirection,
  showErrorMessages,
  getPromise, postPromise, deletePromise, patchPromise
} from '../../shared/helper';
import settings from '../../settings';
import {store} from '../store';
import {message} from 'antd';
import {memberListKeyValues} from '../../components/memberList/constant';

const apiKey = memberListKeyValues?.mailLog?.apiKey

export function* fetchMailLogDetails(params) {
  const { page } = params;
  const dateFilter = !!params?.dateFilter?.length
    ? params.dateFilter
    : '';

  try {
    const url = `${settings.ROOT_URL}${apiKey}?page=${page}${
      !!dateFilter.length ? `&search_date_from=${dateFilter[0]}&search_date_to=${dateFilter[1]}` : ''}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_MAIL_LOG_DETAILS_SUCCESS,
      payload: data
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_MAIL_LOG_DETAILS_FAILURE
    });
    showErrorMessages(error);
  }
}

export function* createMailLogDetails(params) {
  try {
    const url = `${settings.ROOT_URL}${apiKey}`;
    const response = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions.CREATE_MAIL_LOG_DETAILS_SUCCESS,
      payload: response
    });
    store.dispatch({
      type: actions.FETCH_MAIL_LOG_DETAILS,
      payload: true,
      page: store.getState()?.MailLog?.mailLogDetails?.currentPage || 1
    });
    message.success('Mail Log created successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions.CREATE_MAIL_LOG_DETAILS_FAILURE
    });
  }
}
export function* updateMailLogDetails(params) {
  try {
    const url = `${settings.ROOT_URL}/${apiKey}/${params.id}`;
    const response = yield call(() => patchPromise(url, params?.payload));
    yield put({
      type: actions.UPDATE_MAIL_LOG_DETAILS_SUCCESS,
      payload: response
    });
    store.dispatch({
      type: actions.FETCH_MAIL_LOG_DETAILS,
      payload: true,
      page: store.getState()?.MailLog?.mailLogDetails?.currentPage || 1
    });
    message.success('Mail Log updated successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions.UPDATE_MAIL_LOG_DETAILS_FAILURE
    });
  }
}

export function* deleteMailLogDetails(params) {
  const {page, id} = params;
  try {
    const url = `${settings.ROOT_URL}${apiKey}/${id}`;
    yield call(() => deletePromise(url));
    yield put({
      type: actions.DELETE_MAIL_LOG_DETAILS_SUCCESS
    });
    store.dispatch({
      type: actions.FETCH_MAIL_LOG_DETAILS,
      payload: true,
      page: page
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions.DELETE_MAIL_LOG_DETAILS_FAILURE
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_MAIL_LOG_DETAILS, fetchMailLogDetails),
    takeEvery(actions.CREATE_MAIL_LOG_DETAILS, createMailLogDetails),
    takeEvery(actions.UPDATE_MAIL_LOG_DETAILS, updateMailLogDetails),
    takeEvery(actions.DELETE_MAIL_LOG_DETAILS, deleteMailLogDetails)
  ]);
}

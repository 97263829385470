import asyncComponent from '../shared/asyncFunction';
import { convertIntoHypenValues, getModuleDetails } from '../shared/helper';

export function getMailLogRoutePath() {
  return [
    {
      path: convertIntoHypenValues(getModuleDetails('ui', 'maillogs')),
      component: asyncComponent(() => import('../containers/mailLog'))
    }
  ];
}
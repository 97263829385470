const actions = {
  FETCH_USER_AUDIT_DETAILS: 'FETCH_USER_AUDIT_DETAILS',
  FETCH_USER_AUDIT_DETAILS_SUCCESS: 'FETCH_USER_AUDIT_DETAILS_SUCCESS',
  FETCH_USER_AUDIT_DETAILS_FAILURE: 'FETCH_USER_AUDIT_DETAILS_FAILURE',
  SET_USER_AUDIT_MODAL_VISIBLE: 'SET_USER_AUDIT_MODAL_VISIBLE',
  FETCH_USER_DETAILS: 'FETCH_USER_DETAILS',
  FETCH_USER_DETAILS_SUCCESS: 'FETCH_USER_DETAILS_SUCCESS',
  FETCH_USER_DETAILS_FAILURE: 'FETCH_USER_DETAILS_FAILURE'
};

export default actions;

export const formItemLayoutModal = {
  labelCol: {
    xs: { span: 24 },
    md: { span: 8 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

export const dateFormat = 'MM/DD/YYYY';
export const timeFormat = 'h:mm A';
export const ApiDateFormat = 'YYYY-MM-DD';
export const dateFormatDisplay = 'MM-DD-YYYY';
export const dateFormatWitTime = 'MM-DD-YYYY, h:mm:ss a';
export const statusDateFormat = 'MMMM YYYY';
export const yearRangeDateFormat = 'MMM YYYY';
export const statusDateFormatWithDate = 'DD MMMM, YYYY';

export const columnLayoutModal = {
  md: { span: 12 },
  sm: { span: 12 },
  xs: { span: 24 }
};

export const radioButtonLists = [
  { key: true, value: 'Yes' },
  { key: false, value: 'No' }
];

export const radioButtonListsAll = [
  { key: 'all', value: 'All' },
  { key: true, value: 'Yes' },
  { key: false, value: 'No' }
];

export const lifeInsuranceRadioButtonLists = [
  { key: 'all_age', value: 'Consolidate All Age' },
  { key: 'group_by_age', value: 'Group By Age' }
];

export const selectOptionsDefaultActions = [
  'FETCH_SELECT_OPTIONS',
  'FETCH_SELECT_OPTIONS_SUCCESS',
  'FETCH_SELECT_OPTIONS_FAILURE'
];

export const acceptedFileFormat =
  'application/msword,' +
  'application/pdf,' +
  'application/rtf,' +
  'application/text,' +
  'application/vnd.ms-excel,' +
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,' +
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
  'image/png,' +
  'image/tiff,' +
  'image/jpg,' +
  'image/jpeg,' +
  'image/tif' +
  'text/plain';

export const acceptedSmsAttachmentFormat =
  'image/png,' +
  'image/jpg,' +
  'image/jpeg,' +
  'image/gif';

export const selectOptionsActionNames = {
  employeeOffices: 'COMMANDS',
  employeeRanks: 'RANKS',
  employeeEmploymentStatuses: 'EMPLOYMENT_STATUSES',
  employeeOfficerStatuses: 'OFFICER_STATUSES',
  employeeFacilities: 'FACILITIES',
  employeePositions: 'POSITIONS',
  delegateAssignments: 'DELEGATE_ASSIGNMENTS',
  delegateEmployees: 'DELEGATE_EMPLOYEES',
  employeeDepartments: 'DEPARTMENTS',
  employeeSections: 'SECTIONS',
  employeeTitles: 'TITLES',
  genders: 'GENDERS',
  maritalStatuses: 'MARITAL_STATUSES',
  units: 'UNITS',
  firearmStatuses: 'FIREARM_STATUSES',
  employeePacfs: 'EMPLOYEE_PACF',
  benefits: 'BENEFITS',
  paymentTypes: 'PAYMENT_TYPES',
  employeeDisciplines: 'EMPLOYEE_DISCIPLINES',
  employeeGrievances: 'EMPLOYEE_GRIEVANCES',
  userAudit: 'USER_AUDIT',
  employees: 'EMPLOYEES',
  contactPersons: 'EMPLOYEES',
  addressDetails: 'ADDRESS_DETAILS',
  unionMeetings: 'UNION_MEETINGS',
  disciplines: 'DISCIPLINES',
  grievances: 'GRIEVANCES',
  userRolesList: 'USER_ROLES_LIST',
  affiliation: 'AFFILIATION',
  tourOfDuty: 'TOUR_OF_DUTY',
  platoons: 'PLATOONS',
  employeeDisciplineCharges: 'EMPLOYEE_DISCIPLINE_CHARGES',
  employeeDisciplineStatuses: 'EMPLOYEE_DISCIPLINE_STATUSES',
  employeeGrievanceCharges: 'EMPLOYEE_GRIEVANCE_CHARGES',
  employeeGrievanceStatuses: 'EMPLOYEE_GRIEVANCE_STATUSES',
  delegateSeries: 'DELEGATE_SERIES',
  deniedReasons: 'DENIED_REASONS',
  staffMemberEmployee: 'STAFF_MEMBER_EMPLOYEE',
  legislativeAddresses: 'LEGISLATIVE_DETAILS',
  peshes: 'PESH',
  assaults: 'ASSAULTS'
};

export const selectOptionsKeyValues = {
  employeeRanks: {
    keyName: 'employeeRanks',
    selectOptionsApiKey: 'ranks',
    selectOptionsKeyName: 'rankStatusesOptions'
  },
  employeeOffices: {
    keyName: 'employeeOffices',
    selectOptionsApiKey: 'offices',
    selectOptionsKeyName: 'commandStatusesOptions'
  },
  employeeFacilities: {
    keyName: 'employeeFacilities',
    selectOptionsApiKey: 'facilities',
    selectOptionsKeyName: 'facilityStatusesOptions'
  },
  employeePositions: {
    keyName: 'employeePositions',
    selectOptionsApiKey: 'positions',
    selectOptionsKeyName: 'positionStatusesOptions'
  },
  employeeEmploymentStatuses: {
    keyName: 'employeeEmploymentStatuses',
    selectOptionsApiKey: 'employment_statuses',
    selectOptionsKeyName: 'employmentStatusesOptions'
  },
  employeeOfficerStatuses: {
    keyName: 'employeeOfficerStatuses',
    selectOptionsApiKey: 'officer_statuses',
    selectOptionsKeyName: 'officerStatusesOptions'
  },
  delegateAssignments: {
    keyName: 'delegateAssignments',
    selectOptionsApiKey: 'meeting_types',
    selectOptionsKeyName: 'delegateAssignmentsOptions'
  },
  delegateEmployees: {
    keyName: 'delegateEmployees',
    selectOptionsApiKey: 'employees/delegate_employees',
    selectOptionsKeyName: 'delegateEmployeeOptions'
  },
  employeeDepartments: {
    keyName: 'employeeDepartments',
    selectOptionsApiKey: 'departments',
    selectOptionsKeyName: 'departmentStatusesOptions'
  },
  employeeSections: {
    keyName: 'employeeSections',
    selectOptionsApiKey: 'sections',
    selectOptionsKeyName: 'sectionStatusesOptions'
  },
  employeeTitles: {
    keyName: 'employeeTitles',
    selectOptionsApiKey: 'titles',
    selectOptionsKeyName: 'titleStatusesOptions'
  },
  genders: {
    keyName: 'genders',
    selectOptionsApiKey: 'genders',
    selectOptionsKeyName: 'genderOptions'
  },
  units: {
    keyName: 'units',
    selectOptionsApiKey: 'units',
    selectOptionsKeyName: 'unitsOptions'
  },
  maritalStatuses: {
    keyName: 'maritalStatuses',
    selectOptionsApiKey: 'marital_statuses',
    selectOptionsKeyName: 'maritalStatusOptions'
  },
  firearmStatuses: {
    keyName: 'firearmStatuses',
    selectOptionsApiKey: 'firearm_statuses',
    selectOptionsKeyName: 'firearmStatusesOptions'
  },
  employeePacfs: {
    keyName: 'employeePacfs',
    selectOptionsApiKey: 'pacfs',
    selectOptionsKeyName: 'employeePacfsOptions'
  },
  benefits: {
    keyName: 'benefits',
    selectOptionsApiKey: 'benefits',
    selectOptionsKeyName: 'benefitsOptions'
  },
  paymentTypes: {
    keyName: 'paymentTypes',
    selectOptionsApiKey: 'payment_types',
    selectOptionsKeyName: 'paymentTypesOptions'
  },
  employeeDisciplines: {
    keyName: 'employeeDisciplines',
    selectOptionsApiKey: 'discipline_settings',
    selectOptionsKeyName: 'employeeDisciplinesOptions'
  },
  employeeGrievances: {
    keyName: 'employeeGrievances',
    selectOptionsApiKey: 'grievances',
    selectOptionsKeyName: 'employeeGrievancesOptions'
  },
  employees: {
    keyName: 'employees',
    selectOptionsApiKey: 'employee_dropdown_data',
    selectOptionsKeyName: 'employeesOptions',
    category: 'employee'
  },
  contactPersons: {
    keyName: 'employees',
    selectOptionsApiKey: 'employee_dropdown_data',
    selectOptionsKeyName: 'contactPersonOptions',
    category: 'contact_person'
  },
  addressDetails: {
    keyName: 'addressDetails',
    selectOptionsApiKey: 'address_fields_data',
    selectOptionsKeyName: 'addressDetailsOptions'
  },
  unionMeetings: {
    keyName: 'unionMeetings',
    selectOptionsApiKey: 'meeting_types',
    selectOptionsKeyName: 'unionMeetingsOptions'
  },
  disciplines: {
    keyName: 'disciplines',
    selectOptionsApiKey: 'discipline_settings',
    selectOptionsKeyName: 'disciplinesOptions'
  },
  grievances: {
    keyName: 'grievances',
    selectOptionsApiKey: 'grievances',
    selectOptionsKeyName: 'grievancesOptions'
  },
  userRolesList: {
    keyName: 'userRolesList',
    selectOptionsApiKey: 'roles_list',
    selectOptionsKeyName: 'userRolesOptions'
  },
  affiliation: {
    keyName: 'affiliation',
    selectOptionsApiKey: 'affiliations',
    selectOptionsKeyName: 'affiliationOptions'
  },
  tourOfDuty: {
    keyName: 'tourOfDuty',
    selectOptionsApiKey: 'tour_of_duties',
    selectOptionsKeyName: 'tourOfDutyOptions'
  },
  platoons: {
    keyName: 'platoons',
    selectOptionsApiKey: 'platoons',
    selectOptionsKeyName: 'platoonsOptions'
  },
  employeeDisciplineCharges: {
    keyName: 'employeeDisciplineCharges',
    selectOptionsApiKey: 'discipline_charges',
    selectOptionsKeyName: 'disciplineChargesOptions'
  },
  employeeDisciplineStatuses: {
    keyName: 'employeeDisciplineStatuses',
    selectOptionsApiKey: 'discipline_statuses',
    selectOptionsKeyName: 'disciplineStatusesOptions'
  },
  employeeGrievanceCharges: {
    keyName: 'employeeGrievanceCharges',
    selectOptionsApiKey: 'grievance_charges',
    selectOptionsKeyName: 'grievanceChargesOptions'
  },
  employeeGrievanceStatuses: {
    keyName: 'employeeGrievanceStatuses',
    selectOptionsApiKey: 'grievance_statuses',
    selectOptionsKeyName: 'grievanceStatusesOptions'
  },
  delegateSeries: {
    keyName: 'delegateSeries',
    selectOptionsApiKey: 'delegate_series',
    selectOptionsKeyName: 'delegateSeriesOptions'
  },
  deniedReasons: {
    keyName: 'deniedReasons',
    selectOptionsApiKey: 'denied_reasons',
    selectOptionsKeyName: 'deniedReasonsOptions'
  },
  staffMemberEmployee: {
    keyName: 'staffMemberEmployee',
    selectOptionsApiKey: 'staff_member_employee',
    selectOptionsKeyName: 'staffMemberOptions'
  },
  legislativeAddresses: {
    keyName: 'legislativeAddresses',
    selectOptionsApiKey: 'legislative_addresses',
    selectOptionsKeyName: 'legislativeAddresses'
  },
  peshes: {
    keyName: 'peshes',
    selectOptionsApiKey: 'peshes',
    selectOptionsKeyName: 'peshes'
  }
};

export const contactInformation = [
  { key: 'workPhone', contact_for: 'work', contact_type: 'phone' },
  { key: 'personalPhone', contact_for: 'personal', contact_type: 'phone' },
  { key: 'homePhone', contact_for: 'home', contact_type: 'phone' },
  { key: 'workEmail', contact_for: 'work', contact_type: 'email' },
  { key: 'personalEmail', contact_for: 'personal', contact_type: 'email' },
  { key: 'colleagueEmergency', contact_for: 'colleague', contact_type: 'emergency',
    contact_name: 'colleague_emergency_name', contact_relationship: 'colleague_emergency_relationship' },
  { key: 'personalEmergency', contact_for: 'personal', contact_type: 'emergency',
    contact_name: 'personal_emergency_name', contact_relationship: 'personal_emergency_relationship' }
];

export const formItemLayoutProfile = {
  labelCol: {
    lg: { span: 6 },
    md: { span: 24 },
    sm: { span: 24 },
    xs: { span: 24 }
  },
  wrapperCol: {
    lg: { span: 18 },
    md: { span: 24 },
    sm: { span: 24 },
    xs: { span: 24 }
  }
};

export const columnLayoutProfile = {
  lg: 8,
  md: 24,
  sm: 24,
  xs: 24
};

export const columnLayoutProfileWithSuffix = {
  lg: 6,
  md: 24,
  sm: 24,
  xs: 24
};

export const columnLayoutProfileContact = {
  xl: 8,
  lg: 24,
  md: 24,
  sm: 24,
  xs: 24
};

export const emailToLists = [
  { key: 'work', value: 'Work' },
  { key: 'personal', value: 'Personal' },
  { key: 'both', value: 'Both' }
];

export const emailToListsWithBackup = [
  { key: 'personal', value: 'Personal' },
  { key: 'work', value: 'Backup' },
  { key: 'both', value: 'Both' }
];

export const emailToListsAlternative = [
  { key: 'work', value: 'Personal' },
  { key: 'personal', value: 'Alternate' },
  { key: 'both', value: 'Both' }
];

export const userNameRegex = /^\S*$/;

export const userAuditCheckFieldsValue = [
  'Same as Mailing Address',
  'Registered to Vote',
  'Do Not Mail',
  'Attended',
  'Plaque Ordered',
  'Janus Opt Out',
  'Veteran Status',
  'Settled',
  'Pending',
  'Win',
  'Loss',
  'Check # Void',
  'Received Application',
  'Unexpire',
  'Email Opt Out',
  'Sms Opt Out'
];

export const userAuditDateValue = [
  'Discarded At',
  'Filed OLR',
  'Deleted',
  'Date',
  'Reset Password Sent At',
  'Password Changed At'
];

export const defaultBenefitsOrder = [
  'others',
  'benefits',
  'benefitDetails',
  'disability',
  'beneficiaries',
  'lifeInsurance'
]
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import settings from '../../settings';
import {
  getPromise,
  postPromise,
  deletePromise,
  patchPromise,
  unAuthorisedRedirection,
  updateBrowserHistory,
  showErrorMessages,
  toCamelCase
} from '../../shared/helper';
import { message } from 'antd';
import { store } from '../store';
import {
  memberListActionNames,
  memberListKeyValues,
  statusComponentActionNames,
  statusComponentKeyValues
} from '../../components/memberList/constant';
import { fetchTableListsMemberList } from '../../components/memberList/helpers';
import sharedActions from '../shared/actions';
import { selectOptionsKeyValues } from '../../components/constants';
import changeRequestActions from '../../redux/changeRequests/actions';

export function* addMemberDetail(params) {
  const { keyValues, routePath } = params,
    { keyName, apiKey } = keyValues;
  try {
    const url = `${settings.ROOT_URL}${apiKey}?${
      apiKey === 'employees' ? 'category=employee' : ''}`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions[`${memberListActionNames[keyName]}_CREATE_SUCCESS`],
      keyValues
    });
    message.success(
      `${data.message ? data.message : memberListKeyValues[keyName].messageKey} 
       ${data?.message ? '' : 'created successfully'}`
    );
    updateBrowserHistory(`${routePath}/${data.data.attributes.slug}/edit`);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions[`${memberListActionNames[keyName]}_CREATE_FAILURE`],
      keyValues
    });
    showErrorMessages(error, 'employees');
  }
}

export function* updateMemberDetail(params) {
  const { keyValues, updateBeneficiaryAddress } = params,
    { keyName, apiKey } = keyValues;
  try {
    const url = `${settings.ROOT_URL}${apiKey}/${params.slug}?${
      apiKey === 'employees' ? 'category=employee&' : ''}${
        updateBeneficiaryAddress ? 'update_beneficiary_address=true' : ''}`,
      data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions[`${memberListActionNames[keyName]}_UPDATE_SUCCESS`],
      keyValues
    });
    yield put({
      type: actions['MEMBER_DETAILS_PROFILE_UPDATE_SUCCESS'],
      payload: data?.data
    });
    store.dispatch({
      type: actions['SET_NOTES_ACTIVE'],
      payload: false
    });
    message.success(
      `${data?.message ? data?.message : memberListKeyValues[keyName].messageKey} 
      ${data?.message ? '' : 'updated successfully'}`
    );
  } catch (error) {
    yield put({
      type: actions[`${memberListActionNames[keyName]}_UPDATE_FAILURE`],
      keyValues
    });
    unAuthorisedRedirection(error);
    showErrorMessages(error, 'employees');
  }
}

export function* getMemberDetails(params) {
  const { keyValues } = params,
    { keyName, apiKey } = keyValues;
  try {
    const url = `${settings.ROOT_URL}${apiKey}/${params.slug}?${
      apiKey === 'employees' ? 'category=employee&' : ''}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions[`${memberListActionNames[keyName]}_GET_DETAILS_SUCCESS`],
      payload: data,
      keyValues
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions[`${memberListActionNames[keyName]}_GET_DETAILS_FAILURE`],
      keyValues
    });
    showErrorMessages(error);
    updateBrowserHistory('/404');
  }
}

export function* fetchNotesSection(params) {
  const { keyValues } = params,
    { keyName, apiKey } = keyValues;
  const searchValue = params.searchValue
    ? params.searchValue
    : store.getState().MemberList.memberDetails.profile.notesSection.searchValue;
  try {
    const url = `${settings.ROOT_URL}${apiKey}?id=${params.slug}&${
      searchValue ? `search_text=${searchValue}&` : ''}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions[`${memberListActionNames[keyName]}_GET_DETAILS_SUCCESS`],
      payload: data,
      keyValues
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions[`${memberListActionNames[keyName]}_GET_DETAILS_FAILURE`],
      keyValues
    });
    showErrorMessages(error);
    updateBrowserHistory('/404');
  }
}

export function* updateAllowedFields(params) {
  const { keyValues } = params,
    { keyName } = keyValues;

  try {
    const url = `${settings.ROOT_URL}update_allowed_fields?id=${params.slug}`,
      data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions[`${memberListActionNames[keyName]}_UPDATE_SUCCESS`],
      keyValues,
      payload: data
    });
    store.dispatch({
      type: actions['SET_NOTES_ACTIVE'],
      payload: false
    });
    message.success(
      `${data?.message ? data?.message : memberListKeyValues[keyName].messageKey} 
      ${data?.message ? '' : 'updated successfully'}`
    );
  } catch (error) {
    yield put({
      type: actions[`${memberListActionNames[keyName]}_UPDATE_FAILURE`],
      keyValues
    });
    unAuthorisedRedirection(error);
    showErrorMessages(error, 'employees');
  }
}

export function* fetchStatusDetails(params) {
  const { keyValues } = params,
    { keyName, apiKey } = keyValues;
  try {
    let url = `${settings.ROOT_URL}${apiKey}?employee_id=${params.slug}`;
    const data = yield call(() => getPromise(url));
    yield put({
      type:
        actions[`${statusComponentActionNames[keyName]}_FETCH_DETAILS_SUCCESS`],
      payload: data,
      keyValues
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type:
        actions[`${statusComponentActionNames[keyName]}_FETCH_DETAILS_FAILURE`],
      keyValues
    });
    showErrorMessages(error, keyValues['keyName']);
  }
}

export function* createStatusDetail(params) {
  const {
      keyValues,
      closeActive,
      identificationKey,
      activeStatusToBeClose,
      employeeId
    } = params,
    { keyName, apiKey, messageKey } = keyValues,
    { saasData } = store.getState().Auth,
    { profile } = store.getState().MemberList.memberDetails,
    { basicProfileDetails } = profile,
    { schema } = saasData;
  try {
    let url = `${settings.ROOT_URL}${apiKey}`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions[`${statusComponentActionNames[keyName]}_CREATE_SUCCESS`],
      payload: data.data,
      keyValues
    });
    yield put({
      type: actions.SET_PROFILE_OTHERS_MODAL_VISIBLE,
      [keyName]: false
    });
    yield put({
      type: actions[`${statusComponentActionNames[keyName]}_FETCH_DETAILS`],
      slug: params.employeeId,
      keyValues: keyValues
    });
    if (
      keyName === 'employeeEmploymentStatuses' &&
      schema?.['employee_benefits']?.['benefit_customization']
    ) {
      yield put({
        type: sharedActions['BENEFITS_FETCH_SELECT_OPTIONS'],
        employeeId: params.employeeId,
        keyValues: selectOptionsKeyValues['benefits']
      });
      yield put({
        type: actions['MEMBER_LIST_GET_DETAILS'],
        slug: basicProfileDetails?.editData?.attributes?.slug,
        keyValues: memberListKeyValues['memberList']
      });
      yield put({
        type: actions['BENEFITS_FETCH_TABLE_DETAILS'],
        slug: basicProfileDetails?.editData?.id,
        keyValues: memberListKeyValues['benefits'],
        page: 1
      });
    }
    if (
      closeActive &&
      identificationKey === 'employeeEmploymentStatuses' &&
      activeStatusToBeClose
    ) {
      activeStatusToBeClose.map(item =>
        store.dispatch({
          type:
            actions[
              `${statusComponentActionNames[toCamelCase(item)]}_FETCH_DETAILS`
            ],
          slug: employeeId,
          keyValues: statusComponentKeyValues[toCamelCase(item)]
        })
      );
    }
    message.success(`${messageKey} created successfully`);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions[`${statusComponentActionNames[keyName]}_CREATE_FAILURE`],
      keyValues
    });
    showErrorMessages(error, keyValues['keyName']);
  }
}

export function* updateStatusDetail(params) {
  const {
      keyValues,
      closeActive,
      identificationKey,
      activeStatusToBeClose,
      employeeId
    } = params,
    { keyName, apiKey, messageKey } = keyValues,
    { saasData } = store.getState().Auth,
    { profile } = store.getState().MemberList.memberDetails,
    { basicProfileDetails } = profile,
    { schema } = saasData;
  try {
    let url = `${settings.ROOT_URL}${apiKey}/${params.slug}`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions[`${statusComponentActionNames[keyName]}_UPDATE_SUCCESS`],
      payload: data.data,
      slug: params.slug,
      keyValues
    });
    yield put({
      type: actions.SET_PROFILE_OTHERS_MODAL_VISIBLE,
      [keyName]: false
    });
    yield put({
      type: actions[`${statusComponentActionNames[keyName]}_FETCH_DETAILS`],
      slug: params.employeeId,
      keyValues: keyValues
    });
    if (
      closeActive &&
      identificationKey === 'employeeEmploymentStatuses' &&
      activeStatusToBeClose
    ) {
      activeStatusToBeClose.map(item =>
        store.dispatch({
          type:
            actions[
              `${statusComponentActionNames[toCamelCase(item)]}_FETCH_DETAILS`
            ],
          slug: employeeId,
          keyValues: statusComponentKeyValues[toCamelCase(item)]
        })
      );
    }
    if (
      keyName === 'employeeEmploymentStatuses' &&
      schema?.['employee_benefits']?.['benefit_customization']
    ) {
      yield put({
        type: sharedActions['BENEFITS_FETCH_SELECT_OPTIONS'],
        employeeId: params.employeeId,
        keyValues: selectOptionsKeyValues['benefits']
      });
      yield put({
        type: actions['MEMBER_LIST_GET_DETAILS'],
        slug: basicProfileDetails?.editData?.attributes?.slug,
        keyValues: memberListKeyValues['memberList']
      });
      yield put({
        type: actions['BENEFITS_FETCH_TABLE_DETAILS'],
        slug: basicProfileDetails?.editData?.id,
        keyValues: memberListKeyValues['benefits'],
        page: 1
      });
    }
    message.success(`${messageKey} updated successfully`);
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions[`${statusComponentActionNames[keyName]}_UPDATE_FAILURE`],
      keyValues
    });
    showErrorMessages(error, keyValues['keyName']);
  }
}

export function* deleteStatusDetails(params) {
  const { keyValues } = params,
    { keyName, apiKey, messageKey } = keyValues,
    { saasData } = store.getState().Auth,
    { profile } = store.getState().MemberList.memberDetails,
    { basicProfileDetails } = profile,
    { schema } = saasData;
  try {
    let url = `${settings.ROOT_URL}${apiKey}/${params.slug}`;
    yield call(() => deletePromise(url));
    message.success(`${messageKey} deleted successfully`);
    yield put({
      type:
        actions[
          `${statusComponentActionNames[keyName]}_DELETE_DETAILS_SUCCESS`
        ],
      slug: params.slug,
      keyValues
    });
    if (
      keyName === 'employeeEmploymentStatuses' &&
      schema?.['employee_benefits']?.['benefit_customization']
    ) {
      yield put({
        type: sharedActions['BENEFITS_FETCH_SELECT_OPTIONS'],
        employeeId: params.employeeId,
        keyValues: selectOptionsKeyValues['benefits']
      });
      yield put({
        type: actions['MEMBER_LIST_GET_DETAILS'],
        slug: basicProfileDetails?.editData?.attributes?.slug,
        keyValues: memberListKeyValues['memberList']
      });
      yield put({
        type: actions['BENEFITS_FETCH_TABLE_DETAILS'],
        slug: basicProfileDetails?.editData?.id,
        keyValues: memberListKeyValues['benefits'],
        page: 1
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type:
        actions[
          `${statusComponentActionNames[keyName]}_DELETE_DETAILS_FAILURE`
        ],
      keyValues
    });
    showErrorMessages(error, keyValues['keyName']);
  }
}

export function* fetchTableDetails(params) {
  const {
      keyValues,
      employmentStatusValue,
      isFromChangeRequest,
      analyticType,
      durationFrom,
      durationTo,
      officerStatuses,
      selectedSearchType
    } = params,
    { keyName, apiKey } = keyValues;
  const searchValue = params.searchValue
      ? params.searchValue
      : store.getState().MemberList.memberDetails.memberList.searchValue,
    employmentStatus = employmentStatusValue
      ? employmentStatusValue
      : store.getState().MemberList.memberDetails.memberList
        .employmentStatusValue;
  const searchCaseNoValue = params.searchCaseNoValue
    ? params.searchCaseNoValue
    : store.getState().MemberList.memberDetails.memberList.searchCaseNoValue;
  const searchStatusValue = params.searchStatusValue
    ? params.searchStatusValue
    : store.getState().MemberList.memberDetails.disciplineSettings.searchStatusValue;
  const searchNumberValue = params?.searchNumberValue
    ? params.searchNumberValue
    : store.getState().MemberList.memberDetails.memberList.searchNumberValue;
  const searchSsnValue = params?.searchSsnValue
    ? params.searchSsnValue
    : store.getState().MemberList.memberDetails.memberList.searchSsnValue;
  const selectedStatuses = params.selectedStatuses
    ? params.selectedStatuses : store.getState().MemberList.memberDetails.memberList.selectedStatuses;
  const searchDob = params?.searchDob
    ? params.searchDob
    : store.getState().MemberList.memberDetails.memberList.searchDob;
  try {
    const url = `${settings.ROOT_URL}${selectedSearchType === 'specific' ? 'exact_search' : apiKey}?${
      params.slug ? `employee_id=${params.slug}&` : ''
    }${apiKey === 'employees' ? 'category=employee&' : ''}${
      params.analyticType
        ? `analytics_type=${params.analyticType?.leaveType}&`
        : ''
    }${durationFrom ? `duration_from=${durationFrom}&` : ''}${
      durationTo ? `duration_to=${durationTo}&` : ''
    }${searchValue ? `search_text=${searchValue}&` : ''}${
      searchStatusValue ? `employee_id=${params.slug}&search_for_status=${searchStatusValue}&` : ''}${
      searchCaseNoValue ? `search_case_number=${searchCaseNoValue}&` : ''}${
      searchNumberValue ? `search_number=${searchNumberValue}&` : ''}${
      searchSsnValue ? `exact_search_text=${searchSsnValue}&` : ''}${
      searchDob ? `birthday=${searchDob}&` : ''}${
      selectedStatuses ? `staff_member_only=${selectedStatuses}&` : ''}${
      employmentStatus ? `employment_status=${employmentStatus}&` : ''
    }${params.leaveType ? `leave_type=${params.leaveType}&` : ''}${
      params.page ? `page=${params.page}` : ''
    }${officerStatuses ? `&officer_status=${officerStatuses}` : ''}${
      keyValues.lodiType ? `&lodi_type=${keyValues.lodiType}` : ''}${
      // eslint-disable-next-line no-nested-ternary
      params.employeeBenefitId
        ? `&employee_benefit_id=${params.employeeBenefitId}`
        : params.insuranceTypeId
          ? `&life_insurance_id=${params.insuranceTypeId}`
          : ''
    }`;
    const urls = `${settings.ROOT_URL}${apiKey}/get_benefit_claims?employee_id=${params.slug}`
    const data = yield call(() => getPromise(params?.isCommonBenefitDisbursements ? urls : url));
    if (keyName === 'analyticsConfiguration') {
      yield put({
        type:
          actions[
            `${
              memberListActionNames[analyticType?.keyName]
            }_FETCH_TABLE_DETAILS_SUCCESS`
          ],
        payload: data,
        keyValues: analyticType
      });
    } else {
      yield put({
        type:
          actions[
            `${memberListActionNames[keyName]}_FETCH_TABLE_DETAILS_SUCCESS`
          ],
        payload: data,
        keyValues,
        leaveType: params.analyticType?.keyName
      });
    }
    if (isFromChangeRequest) {
      yield put({
        type: changeRequestActions['SET_MODAL_LOADER'],
        modalLoader: false
      });
    }
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type:
        actions[
          `${memberListActionNames[keyName]}_FETCH_TABLE_DETAILS_FAILURE`
        ],
      keyValues
    });
    showErrorMessages(error, 'employees');
  }
}

export function* addTableDetails(params) {
  const {
      keyValues,
      profile,
      employeeBenefitId,
      currentPage,
      insuranceId,
      dependents,
      analyticType,
      isAnalyticsConfiguration,
      viewAnalyticsDetails,
      analyticsConfigurationDetails
    } = params,
    { keyName, apiKey } = keyValues,
    { basicProfileDetails } = profile;
  try {
    const url = `${settings.ROOT_URL}${apiKey}`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions[`${memberListActionNames[keyName]}_CREATE_SUCCESS`],
      payload: data.data,
      keyValues
    });
    if (!isAnalyticsConfiguration) {
      fetchTableListsMemberList(
        params.additionalKeyValues ? params.additionalKeyValues : keyValues,
        profile,
        employeeBenefitId,
        0,
        currentPage,
        insuranceId
      );
    }
    if (isAnalyticsConfiguration && viewAnalyticsDetails) {
      yield put({
        type: actions['GET_ANALYTIC_CONFIGURATION_DATA_IN_TABLE'],
        keyValues: memberListKeyValues['analyticsConfiguration'],
        slug: basicProfileDetails?.editData?.id,
        analyticType: memberListKeyValues[analyticType].leaveType,
        id: analyticsConfigurationDetails?.selectedData?.id
      });
      yield put({
        type: actions[`${memberListActionNames[keyName]}_FETCH_TABLE_DETAILS`],
        keyValues: memberListKeyValues[keyName],
        slug: basicProfileDetails?.editData?.id,
        leaveType: memberListKeyValues[analyticType].leaveType,
        durationFrom:
          analyticsConfigurationDetails?.selectedData?.attributes
            ?.duration_from,
        durationTo:
          analyticsConfigurationDetails?.selectedData?.attributes?.duration_to
      });
    } else if (isAnalyticsConfiguration && !viewAnalyticsDetails) {
      yield put({
        type:
          actions[
            `${memberListActionNames['analyticsConfiguration']}_FETCH_TABLE_DETAILS`
          ],
        keyValues: memberListKeyValues['analyticsConfiguration'],
        slug: basicProfileDetails?.editData?.id,
        analyticType: memberListKeyValues[analyticType]
      });
    }
    if(apiKey === 'benefit_coverages') {
      store.dispatch({
        type: actions.FETCH_BENEFIT_COVERAGES_STATUSES,
        employeeBenefitId,
        slug: basicProfileDetails?.editData?.id
      })
    }
    if(apiKey === 'benefit_coverages'  || apiKey === 'benefit_disbursements') {
      yield put({
        type: actions['BENEFITS_FETCH_TABLE_DETAILS'],
        page: currentPage,
        keyValues: memberListKeyValues['benefits'],
        slug: basicProfileDetails?.editData?.id,
        employeeBenefitId: employeeBenefitId
      });
      yield put({
        type: actions['BENEFIT_COVERAGES_FETCH_TABLE_DETAILS'],
        page: currentPage,
        keyValues: memberListKeyValues['benefitCoverages'],
        slug: basicProfileDetails?.editData?.id,
        employeeBenefitId: employeeBenefitId
      });
    }
    if (dependents) {
      yield put({
        type: actions['LIFE_INSURANCE_FETCH_TABLE_DETAILS'],
        page: currentPage,
        keyValues: memberListKeyValues['lifeInsurance'],
        slug: basicProfileDetails?.editData?.id
      });
    }
    message.success(
      `${memberListKeyValues[keyName].messageKey} created successfully`
    );
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions[`${memberListActionNames[keyName]}_CREATE_FAILURE`],
      keyValues
    });
    showErrorMessages(error, 'employees');
  }
}

export function* updateTableDetails(params) {
  const {
      keyValues,
      profile,
      employeeBenefitId,
      currentPage,
      dependents,
      analyticsConfigurationDetails,
      isAnalyticsConfiguration,
      viewAnalyticsDetails,
      analyticType
    } = params,
    { keyName, apiKey } = keyValues,
    { basicProfileDetails } = profile;
  try {
    let url = `${settings.ROOT_URL}${apiKey}/${params.slug}`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions[`${memberListActionNames[keyName]}_UPDATE_SUCCESS`],
      payload: data.data,
      slug: params.slug,
      keyValues
    });
    if (
      [
        'employeeLodis',
        'firearmStatuses',
        'firearmRangeScores',
        'compTime',
        'overTime',
        'personal',
        'sickBank',
        'vacation',
        'disciplineSettings',
        'grievances',
        'workersComp',
        'beneficiaries',
        'assaults'
      ].includes(
        params.additionalKeyValues
          ? params.additionalKeyValues.keyName
          : keyValues.keyName
      ) &&
      !isAnalyticsConfiguration
    )
      fetchTableListsMemberList(
        params.additionalKeyValues ? params.additionalKeyValues : keyValues,
        profile,
        employeeBenefitId,
        0,
        currentPage
      );
    if (isAnalyticsConfiguration && viewAnalyticsDetails) {
      yield put({
        type: actions['GET_ANALYTIC_CONFIGURATION_DATA_IN_TABLE'],
        keyValues: memberListKeyValues['analyticsConfiguration'],
        slug: basicProfileDetails?.editData?.id,
        analyticType: memberListKeyValues[analyticType].leaveType,
        id: analyticsConfigurationDetails?.selectedData?.id
      });
      yield put({
        type: actions[`${memberListActionNames[keyName]}_FETCH_TABLE_DETAILS`],
        keyValues: memberListKeyValues[keyName],
        slug: basicProfileDetails?.editData?.id,
        leaveType: memberListKeyValues[analyticType].leaveType,
        durationFrom:
          analyticsConfigurationDetails?.selectedData?.attributes
            ?.duration_from,
        durationTo:
          analyticsConfigurationDetails?.selectedData?.attributes?.duration_to
      });
    } else if (isAnalyticsConfiguration && !viewAnalyticsDetails) {
      yield put({
        type:
          actions[
            `${memberListActionNames['analyticsConfiguration']}_FETCH_TABLE_DETAILS`
          ],
        keyValues: memberListKeyValues['analyticsConfiguration'],
        slug: basicProfileDetails?.editData?.id,
        analyticType: memberListKeyValues[analyticType]
      });
    }
    if(apiKey === 'benefit_coverages') {
      store.dispatch({
        type: actions.FETCH_BENEFIT_COVERAGES_STATUSES,
        employeeBenefitId,
        slug: basicProfileDetails?.editData?.id
      })
    }
    if(apiKey === 'benefit_coverages'  || apiKey === 'benefit_disbursements') {
      yield put({
        type: actions['BENEFITS_FETCH_TABLE_DETAILS'],
        page: currentPage,
        keyValues: memberListKeyValues['benefits'],
        slug: basicProfileDetails?.editData?.id,
        employeeBenefitId: employeeBenefitId
      });
      yield put({
        type: actions['BENEFIT_COVERAGES_FETCH_TABLE_DETAILS'],
        page: currentPage,
        keyValues: memberListKeyValues['benefitCoverages'],
        slug: basicProfileDetails?.editData?.id,
        employeeBenefitId: employeeBenefitId
      });
    }
    if (dependents) {
      yield put({
        type: actions['LIFE_INSURANCE_FETCH_TABLE_DETAILS'],
        page: currentPage,
        keyValues: memberListKeyValues['lifeInsurance'],
        slug: basicProfileDetails?.editData?.id
      });
    }
    message.success(
      `${memberListKeyValues[keyName].messageKey} updated successfully`
    );
    yield put({
      type: 'CLEAR_PROFILE_AVATAR'
    });
  } catch (error) {
    yield put({
      type: actions[`${memberListActionNames[keyName]}_UPDATE_FAILURE`],
      keyValues
    });
    unAuthorisedRedirection(error);
    showErrorMessages(error, 'employees');
  }
}

export function* deleteTableDetails(params) {
  const {
      keyValues,
      pagination,
      slug,
      profile,
      employeeBenefitId,
      insuranceTypeId,
      dependents,
      analyticsConfigurationDetails,
      isAnalyticsConfiguration,
      viewAnalyticsDetails,
      analyticType
    } = params,
    { keyName, apiKey } = keyValues,
    { basicProfileDetails } = profile;
  try {
    const url = `${settings.ROOT_URL}${apiKey}/${slug}`;
    yield call(() => deletePromise(url));
    yield put({
      type:
        actions[
          `${memberListActionNames[keyName]}_DELETE_DATA_IN_TABLE_SUCCESS`
        ],
      keyValues
    });
    if (!isAnalyticsConfiguration || keyName === 'beneficiaries') {
      fetchTableListsMemberList(
        keyValues,
        profile,
        employeeBenefitId,
        pagination,
        null,
        insuranceTypeId
      );
    }
    if (isAnalyticsConfiguration && viewAnalyticsDetails) {
      yield put({
        type: actions['GET_ANALYTIC_CONFIGURATION_DATA_IN_TABLE'],
        keyValues: memberListKeyValues['analyticsConfiguration'],
        slug: basicProfileDetails?.editData?.id,
        analyticType: memberListKeyValues[analyticType]?.leaveType,
        id: analyticsConfigurationDetails?.selectedData?.id
      });
      yield put({
        type: actions[`${memberListActionNames[keyName]}_FETCH_TABLE_DETAILS`],
        keyValues: memberListKeyValues[keyName],
        slug: basicProfileDetails?.editData?.id,
        leaveType: memberListKeyValues[analyticType]?.leaveType,
        durationFrom:
          analyticsConfigurationDetails?.selectedData?.attributes
            ?.duration_from,
        durationTo:
          analyticsConfigurationDetails?.selectedData?.attributes?.duration_to
      });
    } else if (isAnalyticsConfiguration && !viewAnalyticsDetails) {
      yield put({
        type:
          actions[
            `${memberListActionNames['analyticsConfiguration']}_FETCH_TABLE_DETAILS`
          ],
        keyValues: memberListKeyValues['analyticsConfiguration'],
        slug: basicProfileDetails?.editData?.id,
        analyticType: memberListKeyValues[analyticType]
      });
    }
    if(apiKey === 'benefit_coverages') {
      store.dispatch({
        type: actions.FETCH_BENEFIT_COVERAGES_STATUSES,
        employeeBenefitId,
        slug: basicProfileDetails?.editData?.id
      })
    }
    if (dependents) {
      yield put({
        type: actions['LIFE_INSURANCE_FETCH_TABLE_DETAILS'],
        page: 1,
        keyValues: memberListKeyValues['lifeInsurance'],
        slug: basicProfileDetails?.editData?.id
      });
    }
    message.success(
      `${memberListKeyValues[keyName].messageKey} deleted successfully`
    );
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type:
        actions[
          `${memberListActionNames[keyName]}_DELETE_DATA_IN_TABLE_FAILURE`
        ],
      keyValues
    });
    showErrorMessages(error, 'employees');
  }
}

export function* titleCodeBasedCreation(params) {
  const { value, employeeID, name } = params;
  try {
    let url = `${settings.ROOT_URL}title_code_update?title_code=${value}&id=${employeeID}`;
    yield call(() => postPromise(url, params.payload));
    const fetchValues = [
      'employeeDepartments',
      'employeeSections',
      'employeeTitles'
    ];
    fetchValues.map(data =>
      store.dispatch({
        type: `${statusComponentActionNames[data]}_FETCH_DETAILS`,
        slug: employeeID,
        keyValues: statusComponentKeyValues[data]
      })
    );
    message.success(`${name} associations was created successfully`);
    yield put({
      type: actions.TITLE_CODE_BASED_CREATION_SUCCESS
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions.TITLE_CODE_BASED_CREATION_FAILURE
    });
  }
}

export function* sendLoginInformation(params) {
  try {
    const url = `${settings.ROOT_URL}/login_credentials`,
      response = yield call(() => postPromise(url, params.payload));
    message.success(response?.message);
    yield put({
      type: actions['SEND_LOGIN_INFORMATION_SUCCESS'],
      payload: response.data.attributes
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    message.error(error.response.data.errors[0]);
  }
}

export function* getTableList(params) {
  const { keyValues, id } = params,
    { keyName, apiKey } = keyValues;
  try {
    const url = `${settings.ROOT_URL}${apiKey}/?${
      params.slug ? `employee_id=${params.slug}&` : ''
    }${id ? `analytics_configuration_id=${id}&` : ''}${
      params.analyticType ? `analytics_type=${params.analyticType}&` : ''
    }`;
    const data = yield call(() => getPromise(url));
    yield put({
      type: actions['GET_ANALYTIC_CONFIGURATION_DATA_IN_TABLE_SUCCESS'],
      payload: data.data
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions[`${memberListActionNames[keyName]}_GET_DETAILS_FAILURE`],
      keyValues
    });
    showErrorMessages(error);
  }
}

export function* getBenefitCoveragesList(params) {
  const { slug, employeeBenefitId } = params;
  try {
    const url = `${settings.ROOT_URL}benefit_coverages/?${
      slug ? `employee_id=${slug}&` : ''
    }${employeeBenefitId ? `employee_benefit_id=${employeeBenefitId}&` : ''}`;

    const response = yield call(() => getPromise(url));
    const selectOptions = response.data.map(data => ({
      key: data.attributes?.id,
      value: data.attributes?.benefit_coverage_full_name
    }));
    yield put({
      type: actions['FETCH_BENEFIT_COVERAGES_STATUSES_SUCCESS'],
      payload: selectOptions
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
  }
}

export function* getMemberListCount() {
  try {
    const url = `${settings.ROOT_URL}employees/employee_status_detail`;

    const response = yield call(() => getPromise(url));
    yield put({
      type: actions['FETCH_MEMBER_LIST_COUNT_SUCCESS'],
      payload: response.data
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
  }
}

export function* getLegislativeDetails(params) {
  try {
    const url = `${settings.ROOT_URL}legislative_addresses/employee_legislative_address?employee_id=${params.slug}`;
    const response = yield call(() => getPromise(url));
    yield put({
      type: actions['FETCH_LEGISLATIVE_DETAILS_SUCCESS'],
      payload: response.data
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions['FETCH_LEGISLATIVE_DETAILS_FAILURE']
    });
  }
}

export function* getNotesDetails(params) {
  try {
    const url = `${settings.ROOT_URL}poly_notes?notable_type=Employee&notable_id=${params.payload}`;
    const response = yield call(() => getPromise(url));
    yield put({
      type: actions['FETCH_BENEFITS_TAB_NOTES_SUCCESS'],
      payload: response.data
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions['FETCH_BENEFITS_TAB_NOTES_FAILURE']
    });
  }
}

export function* getSingleNotesDetails(params) {
  try {
    const url = `${settings.ROOT_URL}poly_notes?notable_type=Employee&notable_id=${params.id}&user_id=${params.payload}`;
    const response = yield call(() => getPromise(url));
    yield put({
      type: actions['FETCH_BENEFITS_SINGLE_TAB_NOTES_SUCCESS'],
      payload: response.data
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions['FETCH_BENEFITS_SINGLE_TAB_NOTES_FAILURE']
    });
  }
}

export function* addNotesDetails(params) {
  const { profileDetails } = store.getState().Shared,
    { userId } = profileDetails;
  try {
    const { profile } = store.getState().MemberList.memberDetails;
    const url = `${settings.ROOT_URL}poly_notes`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions['ADD_BENEFITS_TAB_NOTES_SUCCESS'],
      payload: data?.data
    });
    yield put({
      type: actions['FETCH_BENEFITS_TAB_NOTES'],
      payload: profile?.basicProfileDetails?.editData?.id,
      id:userId
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions['ADD_BENEFITS_TAB_NOTES_FAILURE']
    });
  }
}

export function* addSingleNotesDetails(params) {
  try {
    const url = `${settings.ROOT_URL}poly_notes`;
    const data = yield call(() => postPromise(url, params.payload));
    yield put({
      type: actions['ADD_BENEFITS_TAB_NOTES_SUCCESS'],
      payload: data?.data,
      isSingleNotes: true
    });
    message.success('Notes Created Successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions['ADD_BENEFITS_TAB_NOTES_FAILURE']
    });
  }
}

export function* updateNotesDetails(params) {
  try {
    const { profile } = store.getState().MemberList.memberDetails;
    let url = `${settings.ROOT_URL}poly_notes/${params?.id}`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions['UPDATE_BENEFITS_TAB_NOTES_SUCCESS'],
      payload: data?.data
    });
    yield put({
      type: actions['FETCH_BENEFITS_TAB_NOTES'],
      payload: profile?.basicProfileDetails?.editData?.id
    });
    message.success('Notes Updated Successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions['UPDATE_BENEFITS_TAB_NOTES_FAILURE']
    });
  }
}
export function* grievanceAutoGeneration(params) {
  try {
    let url = `${settings.ROOT_URL}employee_grievances/get_max_case_number`;
    const data = yield call(() => getPromise(url, params.payload));
    yield put({
      type: actions.GRIEVANCE_AUTO_GENERATION_SUCCESS,
      payload: data?.data?.number
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions.GRIEVANCE_AUTO_GENERATION_FAILURE
    });
  }
}

export function* updateSingleNotesDetails(params) {
  try {
    const { profileDetails } = store.getState().Shared,
      { userId } = profileDetails;
    let url = `${settings.ROOT_URL}poly_notes/${params?.id}?notable_type=Employee&notable_id=${params?.payload?.poly_notes?.notable_id}&user_id=${userId}`;
    const data = yield call(() => patchPromise(url, params.payload));
    yield put({
      type: actions['UPDATE_BENEFITS_TAB_SINGLE_NOTES_SUCCESS'],
      payload: data?.data
    });
    yield put({
      type: actions.FETCH_BENEFITS_SINGLE_TAB_NOTES,
      payload: userId,
      isSingleNotes: true,
      id: params.payload.poly_notes.notable_id
    });
    message.success('Notes Updated Successfully');
  } catch (error) {
    unAuthorisedRedirection(error);
    showErrorMessages(error);
    yield put({
      type: actions['UPDATE_BENEFITS_TAB_SINGLE_NOTES_FAILURE']
    });
  }
}

export function* deleteChargeDetails(params) {
  const { keyValues } = params;
  try {
    let url = `${settings.ROOT_URL}poly_notes/${params.slug}`;
    yield call(() => deletePromise(url));
    yield put({
      type:
        actions['DELETE_EMPLOYESS_CHARGE_SUCCESS'],
      slug: params.slug,
      keyValues
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type:
        actions['DELETE_EMPLOYESS_CHARGE_FAILURE'],
      keyValues
    });
    showErrorMessages(error, keyValues['keyName']);
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.MEMBER_LIST_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.MEMBER_LIST_CREATE, addMemberDetail),
    takeEvery(actions.MEMBER_LIST_DELETE_DATA_IN_TABLE, deleteTableDetails),
    takeEvery(actions.MEMBER_LIST_UPDATE, updateMemberDetail),
    takeEvery(actions.MEMBER_LIST_GET_DETAILS, getMemberDetails),

    takeEvery(actions.POSITIONS_FETCH_DETAILS, fetchStatusDetails),
    takeEvery(actions.COMMANDS_FETCH_DETAILS, fetchStatusDetails),
    takeEvery(actions.RANKS_FETCH_DETAILS, fetchStatusDetails),
    takeEvery(actions.EMPLOYMENT_STATUSES_FETCH_DETAILS, fetchStatusDetails),
    takeEvery(actions.OFFICER_STATUSES_FETCH_DETAILS, fetchStatusDetails),
    takeEvery(actions.DELEGATE_ASSIGNMENTS_FETCH_DETAILS, fetchStatusDetails),
    takeEvery(actions.DEPARTMENTS_FETCH_DETAILS, fetchStatusDetails),
    takeEvery(actions.SECTIONS_FETCH_DETAILS, fetchStatusDetails),
    takeEvery(actions.TITLES_FETCH_DETAILS, fetchStatusDetails),
    takeEvery(actions.FACILITIES_FETCH_DETAILS, fetchStatusDetails),

    takeEvery(actions.POSITIONS_CREATE, createStatusDetail),
    takeEvery(actions.COMMANDS_CREATE, createStatusDetail),
    takeEvery(actions.RANKS_CREATE, createStatusDetail),
    takeEvery(actions.EMPLOYMENT_STATUSES_CREATE, createStatusDetail),
    takeEvery(actions.OFFICER_STATUSES_CREATE, createStatusDetail),
    takeEvery(actions.DELEGATE_ASSIGNMENTS_CREATE, createStatusDetail),
    takeEvery(actions.DEPARTMENTS_CREATE, createStatusDetail),
    takeEvery(actions.SECTIONS_CREATE, createStatusDetail),
    takeEvery(actions.TITLES_CREATE, createStatusDetail),
    takeEvery(actions.FACILITIES_CREATE, createStatusDetail),

    takeEvery(actions.POSITIONS_UPDATE, updateStatusDetail),
    takeEvery(actions.COMMANDS_UPDATE, updateStatusDetail),
    takeEvery(actions.RANKS_UPDATE, updateStatusDetail),
    takeEvery(actions.EMPLOYMENT_STATUSES_UPDATE, updateStatusDetail),
    takeEvery(actions.OFFICER_STATUSES_UPDATE, updateStatusDetail),
    takeEvery(actions.DELEGATE_ASSIGNMENTS_UPDATE, updateStatusDetail),
    takeEvery(actions.DEPARTMENTS_UPDATE, updateStatusDetail),
    takeEvery(actions.SECTIONS_UPDATE, updateStatusDetail),
    takeEvery(actions.TITLES_UPDATE, updateStatusDetail),
    takeEvery(actions.FACILITIES_UPDATE, updateStatusDetail),

    takeEvery(actions.POSITIONS_DELETE_DETAILS, deleteStatusDetails),
    takeEvery(actions.COMMANDS_DELETE_DETAILS, deleteStatusDetails),
    takeEvery(actions.RANKS_DELETE_DETAILS, deleteStatusDetails),
    takeEvery(actions.EMPLOYMENT_STATUSES_DELETE_DETAILS, deleteStatusDetails),
    takeEvery(actions.OFFICER_STATUSES_DELETE_DETAILS, deleteStatusDetails),
    takeEvery(actions.DELEGATE_ASSIGNMENTS_DELETE_DETAILS, deleteStatusDetails),
    takeEvery(actions.DEPARTMENTS_DELETE_DETAILS, deleteStatusDetails),
    takeEvery(actions.SECTIONS_DELETE_DETAILS, deleteStatusDetails),
    takeEvery(actions.TITLES_DELETE_DETAILS, deleteStatusDetails),
    takeEvery(actions.FACILITIES_DELETE_DETAILS, deleteStatusDetails),

    takeEvery(actions.FIREARM_STATUSES_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.FIREARM_STATUSES_CREATE, addTableDetails),
    takeEvery(actions.FIREARM_STATUSES_UPDATE, updateTableDetails),
    takeEvery(
      actions.FIREARM_STATUSES_DELETE_DATA_IN_TABLE,
      deleteTableDetails
    ),

    takeEvery(
      actions.FIREARM_RANGE_SCORES_FETCH_TABLE_DETAILS,
      fetchTableDetails
    ),
    takeEvery(actions.FIREARM_RANGE_SCORES_CREATE, addTableDetails),
    takeEvery(actions.FIREARM_RANGE_SCORES_UPDATE, updateTableDetails),
    takeEvery(
      actions.FIREARM_RANGE_SCORES_DELETE_DATA_IN_TABLE,
      deleteTableDetails
    ),
    takeEvery(actions.EMPLOYEE_PACF_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.EMPLOYEE_PACF_CREATE, addTableDetails),
    takeEvery(actions.EMPLOYEE_PACF_UPDATE, updateTableDetails),
    takeEvery(actions.EMPLOYEE_PACF_DELETE_DATA_IN_TABLE, deleteTableDetails),

    takeEvery(actions.AWARDS_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.AWARDS_CREATE, addTableDetails),
    takeEvery(actions.AWARDS_UPDATE, updateTableDetails),
    takeEvery(actions.AWARDS_DELETE_DATA_IN_TABLE, deleteTableDetails),

    takeEvery(actions.UNIONS_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.UNIONS_CREATE, addTableDetails),
    takeEvery(actions.UNIONS_UPDATE, updateTableDetails),
    takeEvery(actions.UNIONS_DELETE_DATA_IN_TABLE, deleteTableDetails),

    takeEvery(actions.BENEFITS_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.BENEFITS_CREATE, addTableDetails),
    takeEvery(actions.BENEFITS_UPDATE, updateTableDetails),
    takeEvery(actions.BENEFITS_DELETE_DATA_IN_TABLE, deleteTableDetails),

    takeEvery(actions.BENEFICIARIES_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.BENEFICIARIES_CREATE, addTableDetails),
    takeEvery(actions.BENEFICIARIES_UPDATE, updateTableDetails),
    takeEvery(actions.BENEFICIARIES_DELETE_DATA_IN_TABLE, deleteTableDetails),

    takeEvery(actions.DISABILITY_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.DISABILITY_CREATE, addTableDetails),
    takeEvery(actions.DISABILITY_UPDATE, updateTableDetails),
    takeEvery(actions.DISABILITY_DELETE_DATA_IN_TABLE, deleteTableDetails),

    takeEvery(actions.BENEFIT_COVERAGES_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.BENEFIT_COVERAGES_CREATE, addTableDetails),
    takeEvery(actions.BENEFIT_COVERAGES_UPDATE, updateTableDetails),
    takeEvery(
      actions.BENEFIT_COVERAGES_DELETE_DATA_IN_TABLE,
      deleteTableDetails
    ),

    takeEvery(
      actions.BENEFIT_DISBURSEMENTS_FETCH_TABLE_DETAILS,
      fetchTableDetails
    ),
    takeEvery(actions.BENEFIT_DISBURSEMENTS_CREATE, addTableDetails),
    takeEvery(actions.BENEFIT_DISBURSEMENTS_UPDATE, updateTableDetails),
    takeEvery(
      actions.BENEFIT_DISBURSEMENTS_DELETE_DATA_IN_TABLE,
      deleteTableDetails
    ),

    takeEvery(actions.UPLOADS_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.UPLOADS_CREATE, addTableDetails),
    takeEvery(actions.UPLOADS_UPDATE, updateTableDetails),
    takeEvery(actions.UPLOADS_DELETE_DATA_IN_TABLE, deleteTableDetails),

    takeEvery(actions.ASSAULTS_CREATE, addTableDetails),
    takeEvery(actions.ASSAULTS_UPDATE, updateTableDetails),
    takeEvery(actions.ASSAULTS_DELETE_DATA_IN_TABLE, deleteTableDetails),
    takeEvery(actions.ASSAULTS_FETCH_TABLE_DETAILS, fetchTableDetails),

    takeEvery(actions.PESH_CREATE, addTableDetails),
    takeEvery(actions.PESH_UPDATE, updateTableDetails),
    takeEvery(actions.PESH_DELETE_DATA_IN_TABLE, deleteTableDetails),
    takeEvery(actions.PESH_FETCH_TABLE_DETAILS, fetchTableDetails),

    takeEvery(
      actions.EMPLOYEE_DISCIPLINES_FETCH_TABLE_DETAILS,
      fetchTableDetails
    ),
    takeEvery(actions.EMPLOYEE_DISCIPLINES_CREATE, addTableDetails),
    takeEvery(actions.EMPLOYEE_DISCIPLINES_UPDATE, updateTableDetails),
    takeEvery(
      actions.EMPLOYEE_DISCIPLINES_DELETE_DATA_IN_TABLE,
      deleteTableDetails
    ),

    takeEvery(
      actions.EMPLOYEE_GRIEVANCES_FETCH_TABLE_DETAILS,
      fetchTableDetails
    ),
    takeEvery(actions.EMPLOYEE_GRIEVANCES_CREATE, addTableDetails),
    takeEvery(actions.EMPLOYEE_GRIEVANCES_UPDATE, updateTableDetails),
    takeEvery(
      actions.EMPLOYEE_GRIEVANCES_DELETE_DATA_IN_TABLE,
      deleteTableDetails
    ),

    takeEvery(actions.COMPTIME_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.COMPTIME_CREATE, addTableDetails),
    takeEvery(actions.COMPTIME_UPDATE, updateTableDetails),
    takeEvery(actions.COMPTIME_DELETE_DATA_IN_TABLE, deleteTableDetails),

    takeEvery(actions.OVERTIME_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.OVERTIME_CREATE, addTableDetails),
    takeEvery(actions.OVERTIME_UPDATE, updateTableDetails),
    takeEvery(actions.OVERTIME_DELETE_DATA_IN_TABLE, deleteTableDetails),

    takeEvery(actions.PERSONAL_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.PERSONAL_CREATE, addTableDetails),
    takeEvery(actions.PERSONAL_UPDATE, updateTableDetails),
    takeEvery(actions.PERSONAL_DELETE_DATA_IN_TABLE, deleteTableDetails),

    takeEvery(actions.SICK_BANK_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.SICK_BANK_CREATE, addTableDetails),
    takeEvery(actions.SICK_BANK_UPDATE, updateTableDetails),
    takeEvery(actions.SICK_BANK_DELETE_DATA_IN_TABLE, deleteTableDetails),

    takeEvery(actions.VACATION_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.VACATION_CREATE, addTableDetails),
    takeEvery(actions.VACATION_UPDATE, updateTableDetails),
    takeEvery(actions.VACATION_DELETE_DATA_IN_TABLE, deleteTableDetails),

    takeEvery(actions.AVA_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.AVA_CREATE, addTableDetails),
    takeEvery(actions.AVA_UPDATE, updateTableDetails),
    takeEvery(actions.AVA_DELETE_DATA_IN_TABLE, deleteTableDetails),

    takeEvery(actions.WORKERS_COMP_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.WORKERS_COMP_CREATE, addTableDetails),
    takeEvery(actions.WORKERS_COMP_UPDATE, updateTableDetails),
    takeEvery(actions.WORKERS_COMP_DELETE_DATA_IN_TABLE, deleteTableDetails),

    takeEvery(actions.EMPLOYEE_LODIS_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.EMPLOYEE_LODIS_CREATE, addTableDetails),
    takeEvery(actions.EMPLOYEE_LODIS_UPDATE, updateTableDetails),
    takeEvery(actions.EMPLOYEE_LODIS_DELETE_DATA_IN_TABLE, deleteTableDetails),
    takeEvery(actions.TITLE_CODE_BASED_CREATION, titleCodeBasedCreation),
    takeEvery(actions.EMPLOYEE_LODIS_TAB_CREATE, addTableDetails),
    takeEvery(actions.EMPLOYEE_LODIS_TAB_UPDATE, updateTableDetails),

    takeEvery(
      actions.EMPLOYEE_DISCIPLINE_STEPS_FETCH_TABLE_DETAILS,
      fetchTableDetails
    ),
    takeEvery(actions.EMPLOYEE_DISCIPLINE_STEPS_CREATE, addTableDetails),
    takeEvery(actions.EMPLOYEE_DISCIPLINE_STEPS_UPDATE, updateTableDetails),
    takeEvery(
      actions.EMPLOYEE_DISCIPLINE_STEPS_DELETE_DATA_IN_TABLE,
      deleteTableDetails
    ),
    takeEvery(
      actions.EMPLOYEE_GRIEVANCE_STEPS_FETCH_TABLE_DETAILS,
      fetchTableDetails
    ),
    takeEvery(actions.EMPLOYEE_GRIEVANCE_STEPS_CREATE, addTableDetails),
    takeEvery(actions.EMPLOYEE_GRIEVANCE_STEPS_UPDATE, updateTableDetails),
    takeEvery(
      actions.EMPLOYEE_GRIEVANCE_STEPS_DELETE_DATA_IN_TABLE,
      deleteTableDetails
    ),
    takeEvery(actions.LIFE_INSURANCE_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.LIFE_INSURANCE_CREATE, addTableDetails),
    takeEvery(actions.LIFE_INSURANCE_UPDATE, updateTableDetails),
    takeEvery(actions.LIFE_INSURANCE_DELETE_DATA_IN_TABLE, deleteTableDetails),

    takeEvery(actions.DEPENDENTS_FETCH_TABLE_DETAILS, fetchTableDetails),
    takeEvery(actions.DEPENDENTS_CREATE, addTableDetails),
    takeEvery(actions.DEPENDENTS_DELETE_DATA_IN_TABLE, deleteTableDetails),
    takeEvery(actions.DEPENDENTS_UPDATE, updateTableDetails),

    takeEvery(actions.SEND_LOGIN_INFORMATION, sendLoginInformation),

    takeEvery(
      actions.ANALYTICS_CONFIGURATION_FETCH_TABLE_DETAILS,
      fetchTableDetails
    ),
    takeEvery(
      actions.ANALYTICS_CONFIGURATION_DELETE_DATA_IN_TABLE,
      deleteTableDetails
    ),
    takeEvery(actions.GET_ANALYTIC_CONFIGURATION_DATA_IN_TABLE, getTableList),
    takeEvery(actions.FETCH_BENEFIT_COVERAGES_STATUSES, getBenefitCoveragesList),
    takeEvery(
      actions.FETCH_MEMBER_LIST_COUNT, getMemberListCount
    ),
    takeEvery(actions.FETCH_LEGISLATIVE_DETAILS, getLegislativeDetails),
    takeEvery(actions.FETCH_BENEFITS_TAB_NOTES, getNotesDetails),
    takeEvery(actions.FETCH_BENEFITS_SINGLE_TAB_NOTES, getSingleNotesDetails),
    takeEvery(actions.ADD_BENEFITS_TAB_NOTES, addNotesDetails),
    takeEvery(actions.ADD_BENEFITS_TAB_SINGLE_NOTES, addSingleNotesDetails),
    takeEvery(actions.UPDATE_BENEFITS_TAB_SINGLE_NOTES, updateSingleNotesDetails),
    takeEvery(actions.UPDATE_BENEFITS_TAB_NOTES, updateNotesDetails),
    takeEvery(actions.GRIEVANCE_AUTO_GENERATION, grievanceAutoGeneration),

    takeEvery(actions.DELETE_EMPLOYESS_CHARGE, deleteChargeDetails),

    takeEvery(actions.NOTES_SECTION_GET_DETAILS, fetchNotesSection),
    takeEvery(actions.NOTES_SECTION_UPDATE, updateAllowedFields)

  ]);
}

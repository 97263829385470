import './style.scss';
import React, { Component } from 'react';
import { Spin, Icon } from 'antd';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

class Loader extends Component {
  render() {
    return <Spin indicator={antIcon} className={'loader'} />;
  }
}

export default Loader;

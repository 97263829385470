import './style.scss';
import React, { Component } from 'react';
import { Layout, Breadcrumb, Dropdown, Icon, Menu, Avatar, Button } from 'antd';
import { NavLink } from 'react-router-dom';
import actions from '../../redux/shared/actions';
import { store } from '../../redux/store';
import { connect } from 'react-redux';
import authActions from '../../redux/auth/actions';
import {
  checkBeneficiariesPercentage,
  convertIntoHypenValues,
  getModuleDetails,
  updateBrowserHistory
} from '../../shared/helper';
import SuperAccountComponent from './superAccountComponent';

const { Header } = Layout;

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    };
  }

  toggleCollapsed = () => {
    store.dispatch({
      type: actions.SET_NAVBAR_TOGGLED_STATUS,
      payload: !this.props.navbarToggledStatus
    });
  };

  logOut = () => {
    store.dispatch({
      type: authActions.LOGOUT
    });
  };

  navigationForBreadcrumbs = (e, mainTitle, subTitle) => {
    if (subTitle && getModuleDetails('ui', 'employees') === mainTitle) {
      updateBrowserHistory(
        `/${convertIntoHypenValues(mainTitle.toLowerCase())}`,
      );
    } else {
      e.preventDefault();
    }
  };

  render() {
    const {
        breadcrumbDetails,
        isMobile,
        profileDetails,
        allowedAccounts,
        superUserJson,
        profile,
        saasData,
        contactDetails
      } = this.props,
      { mainTitle, subTitle, infoTitle } = breadcrumbDetails,
      { avatar, username } = profileDetails,
      { basicProfileDetails } = profile,
      { ui } = saasData,
      { employees } = ui,
      { basicContactDetails } = contactDetails,
      employmentStatus = basicProfileDetails?.editData?.attributes?.employment_status_name;
    const menu = (
      <Menu>
        <Menu.Item key="0">
          <NavLink to="/profile" onClick={checkBeneficiariesPercentage}>
            My Profile
          </NavLink>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2" onClick={this.logOut}>
          Logout
        </Menu.Item>
      </Menu>
    );
    return (
      <Header>
        <div
          className={`${isMobile ? 'mobile' : ''} breadcrumb ${
            subTitle && getModuleDetails('ui', 'employees') === mainTitle
              ? 'navigation-breadcrumb'
              : ''
          }`}
        >
          <Breadcrumb separator=">">
            <Breadcrumb.Item
              onClick={e =>
                this.navigationForBreadcrumbs(e, mainTitle, subTitle)
              }
            >
              {mainTitle}
            </Breadcrumb.Item>
            <Breadcrumb.Item>{subTitle}</Breadcrumb.Item>
            <Breadcrumb.Item>{infoTitle}</Breadcrumb.Item>
            {mainTitle === employees['key_name'] && subTitle && (
              <Breadcrumb.Item>
                {employees['profile']['additional_details']?.map((item, index) => (
                  <React.Fragment key={index}>
                    {item === 'officer_status_name' && '> '}
                    {basicProfileDetails?.editData?.attributes?.[item]}{' '}
                  </React.Fragment>
                ))}
              </Breadcrumb.Item>
            )}
            {((mainTitle ===  'Member List') && employmentStatus && !!ui?.employees?.show_union_status_top) &&
              <Breadcrumb.Item
                className={'ant-breadcrumb-link employment-status'}
              >
                {employmentStatus}
              </Breadcrumb.Item>
            }
            {basicContactDetails && mainTitle ===  'Contact List' &&
                  <Breadcrumb.Item>{basicContactDetails?.editData?.attributes?.name}</Breadcrumb.Item>
            }
          </Breadcrumb>
        </div>

        {superUserJson.super_account && (
          <SuperAccountComponent allowedAccounts={allowedAccounts} />
        )}
        {isMobile ? (
          <div className={'navbar-toggled'}>
            <Button
              type="primary"
              onClick={this.toggleCollapsed}
              style={{ marginBottom: 16 }}
            >
              <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />
            </Button>
          </div>
        ) : (
          <div className={'profile-header'}>
            <Dropdown overlay={menu} placement="bottomLeft">
              <div>
                <Avatar size="large" src={avatar}>
                  {username && username.charAt(0).toUpperCase()}
                </Avatar>
                <span className={'user-name'}>{username}</span>
                <Icon type="more" />
              </div>
            </Dropdown>
          </div>
        )}
      </Header>
    );
  }
}
function mapStateToProps(state) {
  const {
      navbarToggledStatus,
      isMobile,
      breadcrumbDetails,
      profileDetails
    } = state.Shared,
    { saasData, allowedAccounts, superUserJson } = state.Auth,
    { profile } = state.MemberList.memberDetails,
    { contactDetails } = state.ContactList;
  return {
    navbarToggledStatus,
    isMobile,
    breadcrumbDetails,
    profileDetails,
    saasData,
    allowedAccounts,
    superUserJson,
    profile,
    contactDetails
  };
}

export default connect(mapStateToProps, actions)(Index);

import { store } from '../../redux/store';
import actions from '../../redux/memberList/actions';
import {memberListActionNames} from './constant';

export function onAddMemberList(keyValues) {
  const { keyName } = keyValues;
  store.dispatch({
    type: actions[`${memberListActionNames[keyName]}_TAB_DETAILS`],
    modalVisible: true,
    keyValues
  });
}

export function fetchTableListsMemberList(
  keyValues,
  profile,
  employeeBenefitId,
  pagination,
  currentPage,
  insuranceId,
  type,
  analyticsConfiguration
) {
  const { basicProfileDetails } = profile,
    { keyName, leaveType } = keyValues,
    { editData } = basicProfileDetails;
  store.dispatch({
    type: actions[`${memberListActionNames[keyName]}_FETCH_TABLE_DETAILS`],
    page: pagination ? pagination.current : currentPage ? currentPage : 1,
    slug: editData && editData.attributes.id,
    keyValues,
    leaveType: leaveType,
    employeeBenefitId: employeeBenefitId,
    insuranceTypeId: insuranceId,
    analyticType: type,
    durationFrom: analyticsConfiguration?.selectedData?.attributes?.duration_from,
    durationTo: analyticsConfiguration?.selectedData?.attributes?.duration_to,
    isCommonBenefitDisbursements: keyValues?.isCommonBenefitDisbursements
  });
}

export const reorderDetails = (obj) => {
  const orderedKeys = ['county_name', 'name', 'district', 'website'];
  const orderedObj = {};

  orderedKeys.forEach(key => {
    if (obj.hasOwnProperty(key)) {
      orderedObj[key] = obj[key];
    }
  });

  return orderedObj;
}
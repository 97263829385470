import { getSubDomain } from '../shared/helper';

export default {
  ROOT_URL: `https://${getSubDomain()}.api.myfusesystems.com/`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  ALLOW_CREDENTIALS: true
};

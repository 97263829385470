import React from 'react';
import { Provider } from 'react-redux';
import { store, history } from './redux/store';
import PublicRoutes from './routes/router';
import { ErrorBoundary } from '@appsignal/react';
import Boot from './redux/boot';
import { message } from 'antd';
import Appsignal from '@appsignal/javascript';

message.config({
  duration: 2,
  maxCount: 1,
});

const appsignal = new Appsignal({
  key: 'd0204dfd-613c-441e-b585-45992faa654f',
});

const FallbackComponent = () => <div />;

const DashApp = () => (
  <ErrorBoundary instance={appsignal} fallback={error => <FallbackComponent />}>
    <Provider store={store}>
      <PublicRoutes history={history} />
    </Provider>
  </ErrorBoundary>
);

Boot()
  .then(() => DashApp())
  .catch(error => console.error(error));

export default DashApp;

import { actionsGenerator } from '../../shared/helper';
import {
  selectOptionsActionNames,
  selectOptionsDefaultActions,
} from '../../components/constants';

const actions = {
  ...actionsGenerator(selectOptionsActionNames, selectOptionsDefaultActions),
  UPLOAD_PROFILE_AVATAR: 'UPLOAD_PROFILE_AVATAR',
  REMOVE_PROFILE_AVATAR: 'REMOVE_PROFILE_AVATAR',
  CLEAR_PROFILE_AVATAR: 'CLEAR_PROFILE_AVATAR',
  CLEAR_SELECT_OPTIONS: 'CLEAR_SELECT_OPTIONS',
  SET_NAVBAR_TOGGLED_STATUS: 'SET_NAVBAR_TOGGLED_STATUS',
  SET_IS_MOBILE_OR_NOT: 'SET_IS_MOBILE_OR_NOT',
  SET_BREADCRUMB_DETAILS: 'SET_BREADCRUMB_DETAILS',
  UPLOAD_FILE: 'UPLOAD_FILE',
  SET_PROFILE_DETAILS: 'SET_PROFILE_DETAILS',
  EMPLOYEES_APPEND_SELECT_OPTIONS: 'EMPLOYEES_APPEND_SELECT_OPTIONS',
  SET_TABLE_NAME_FOR_WIDTH_ADJUSTMENTS: 'SET_TABLE_NAME_FOR_WIDTH_ADJUSTMENTS',
  LEGISLATIVE_DETAILS_FETCH_SELECT_OPTIONS: 'LEGISLATIVE_DETAILS_FETCH_SELECT_OPTIONS',
  LEGISLATIVE_DETAILS_FETCH_SELECT_OPTIONS_SUCCESS:'LEGISLATIVE_DETAILS_FETCH_SELECT_OPTIONS_SUCCESS',
  LEGISLATIVE_DETAILS_FETCH_SELECT_OPTIONS_FAILURE:'LEGISLATIVE_DETAILS_FETCH_SELECT_OPTIONS_FAILURE',
  PESH_FETCH_SELECT_OPTIONS: 'PESH_FETCH_SELECT_OPTIONS',
  PESH_FETCH_SELECT_OPTIONS_SUCCESS:'PESH_FETCH_SELECT_OPTIONS_SUCCESS',
  PESH_FETCH_SELECT_OPTIONS_FAILURE:'PESH_FETCH_SELECT_OPTIONS_FAILURE'
};

export default actions;

export const settingsActionNames = {
  benefits: 'BENEFIT',
  departments: 'DEPARTMENT',
  offices: 'COMMAND',
  pacfs: 'PAF',
  ranks: 'RANK',
  firearmStatuses: 'FIREARM_STATUS',
  employmentStatuses: 'EMPLOYMENT_STATUS',
  officerStatuses: 'OFFICER_STATUS',
  maritalStatuses: 'MARITAL_STATUS',
  positions: 'POSITION',
  paymentTypes: 'PAYMENT',
  facilities: 'FACILITIES',
  meetingTypes: 'DELEGATE_MEETING',
  disciplineSettings: 'DISCIPLINE',
  disciplineCharges: 'DISCIPLINE_CHARGES',
  disciplineStatuses: 'DISCIPLINE_STATUSES',
  grievances: 'GRIEVANCE',
  grievanceCharges: 'GRIEVANCE_CHARGES',
  grievanceStatuses: 'GRIEVANCE_STATUSES',
  genders: 'GENDER',
  units: 'UNIT',
  sections: 'SECTION',
  titles: 'TITLE',
  affiliations: 'AFFILIATION',
  tourOfDuties: 'TOUR_OF_DUTY',
  platoons: 'PLATOONS',
  delegateSeries: 'DELEGATE_SERIES',
  analyticsConfiguration: 'ANALYTICS_CONFIGURATIONS'
};

export const settingsDefaultActions = [
  'SETTINGS_FETCH_TABLE_DETAILS',
  'SETTINGS_FETCH_TABLE_DETAILS_SUCCESS',
  'SETTINGS_FETCH_TABLE_DETAILS_FAILURE',
  'SETTINGS_CREATE',
  'SETTINGS_CREATE_SUCCESS',
  'SETTINGS_CREATE_FAILURE',
  'SETTINGS_UPDATE',
  'SETTINGS_UPDATE_SUCCESS',
  'SETTINGS_UPDATE_FAILURE',
  'SETTINGS_DELETE_DATA_IN_TABLE',
  'SETTINGS_DELETE_DATA_IN_TABLE_SUCCESS',
  'SETTINGS_DELETE_DATA_IN_TABLE_FAILURE',
  'SETTINGS_DETAILS',
  'SETTINGS_SEARCH_VALUE_SET'
];

export const settingsKeyValues = {
  benefits: {
    apiKey: 'benefits',
    keyType: 'benefit',
    keyName: 'benefits',
    permissionKeys: ['readBenefit', 'writeBenefit']
  },
  facilities: {
    apiKey: 'facilities',
    keyType: 'facility',
    keyName: 'facilities',
    permissionKeys: ['readFacility', 'writeFacility']
  },
  departments: {
    apiKey: 'departments',
    keyType: 'department',
    keyName: 'departments',
    permissionKeys: ['readDepartment', 'writeDepartment']
  },
  offices: {
    apiKey: 'offices',
    keyType: 'office',
    keyName: 'offices',
    permissionKeys: ['readCommand', 'writeCommand']
  },
  pacfs: {
    apiKey: 'pacfs',
    keyType: 'pacf',
    keyName: 'pacfs',
    permissionKeys: ['readPaf', 'writePaf']
  },
  ranks: {
    apiKey: 'ranks',
    keyType: 'rank',
    keyName: 'ranks',
    permissionKeys: ['readRank', 'writeRank']
  },
  firearmStatuses: {
    apiKey: 'firearm_statuses',
    keyType: 'firearm_status',
    keyName: 'firearmStatuses',
    permissionKeys: ['readFirearmStatus', 'writeFirearmStatus']
  },
  employmentStatuses: {
    apiKey: 'employment_statuses',
    keyType: 'employment_status',
    keyName: 'employmentStatuses',
    permissionKeys: ['readEmploymentStatus', 'writeEmploymentStatus']
  },
  officerStatuses: {
    apiKey: 'officer_statuses',
    keyType: 'officer_status',
    keyName: 'officerStatuses',
    permissionKeys: ['readOfficerStatus', 'writeOfficerStatus']
  },
  maritalStatuses: {
    apiKey: 'marital_statuses',
    keyType: 'marital_status',
    keyName: 'maritalStatuses',
    permissionKeys: ['readMaritalStatus', 'writeMaritalStatus']
  },
  positions: {
    apiKey: 'positions',
    keyType: 'position',
    keyName: 'positions',
    permissionKeys: ['readPosition', 'writePosition']
  },
  paymentTypes: {
    apiKey: 'payment_types',
    keyType: 'payment_type',
    keyName: 'paymentTypes',
    permissionKeys: ['readPaymentTypes', 'writePaymentTypes']
  },
  meetingTypes: {
    apiKey: 'meeting_types',
    keyType: 'meeting_type',
    keyName: 'meetingTypes',
    permissionKeys: ['readMeetingType', 'writeMeetingType']
  },
  disciplineSettings: {
    apiKey: 'discipline_settings',
    keyType: 'discipline_setting',
    keyName: 'disciplineSettings',
    permissionKeys: ['readDisciplines', 'writeDisciplines']
  },
  disciplineCharges: {
    apiKey: 'discipline_charges',
    keyType: 'discipline_charge',
    keyName: 'disciplineCharges',
    permissionKeys: ['readDisciplineCharge', 'writeDisciplineCharge']
  },
  disciplineStatuses: {
    apiKey: 'discipline_statuses',
    keyType: 'discipline_status',
    keyName: 'disciplineStatuses',
    permissionKeys: ['readDisciplineStatus', 'writeDisciplineStatus']
  },
  grievances: {
    apiKey: 'grievances',
    keyType: 'grievance',
    keyName: 'grievances',
    permissionKeys: ['readGrievance', 'writeGrievance']
  },
  grievanceCharges: {
    apiKey: 'grievance_charges',
    keyType: 'grievance_charge',
    keyName: 'grievanceCharges',
    permissionKeys: ['readGrievanceCharge', 'writeGrievanceCharge']
  },
  grievanceStatuses: {
    apiKey: 'grievance_statuses',
    keyType: 'grievance_status',
    keyName: 'grievanceStatuses',
    permissionKeys: ['readGrievanceStatus', 'writeGrievanceStatus']
  },
  genders: {
    apiKey: 'genders',
    keyType: 'gender',
    keyName: 'genders',
    permissionKeys: ['readGender', 'writeGender']
  },
  units: {
    apiKey: 'units',
    keyType: 'unit',
    keyName: 'units',
    permissionKeys: ['readUnit', 'writeUnit']
  },
  sections: {
    apiKey: 'sections',
    keyType: 'section',
    keyName: 'sections',
    permissionKeys: ['readSection', 'writeSection']
  },
  titles: {
    apiKey: 'titles',
    keyType: 'title',
    keyName: 'titles',
    permissionKeys: ['readTitle', 'writeTitle']
  },
  affiliations: {
    apiKey: 'affiliations',
    keyType: 'affiliation',
    keyName: 'affiliations',
    permissionKeys: ['readAffiliations', 'writeAffiliations']
  },
  tourOfDuties: {
    apiKey: 'tour_of_duties',
    keyType: 'tour_of_duty',
    keyName: 'tourOfDuties',
    permissionKeys: ['readTourOfDuties', 'writeTourOfDuties']
  },
  platoons: {
    apiKey: 'platoons',
    keyType: 'platoon',
    keyName: 'platoons',
    permissionKeys: ['readPlatoon', 'writePlatoon']
  },
  delegateSeries: {
    apiKey: 'delegate_series',
    keyType: 'delegate_series',
    keyName: 'delegateSeries',
    permissionKeys: ['readDelegateSeries', 'writeDelegateSeries']
  },
  analyticsConfiguration: {
    apiKey: 'analytics_configurations',
    keyType: 'analytics_configuration',
    keyName: 'analyticsConfiguration',
    permissionKeys: ['readAnalyticsConfiguration', 'writeAnalyticsConfiguration']
  }
};

import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import asyncComponent from '../shared/asyncFunction';
import App from '../containers/app/app';
import Loader from '../components/shared/loader';
import ErrorPage from '../containers/pages/500';
import { convertIntoHypenValues, getModuleDetails } from '../shared/helper';

const PrivateRoute = ({
  component: Component,
  isLoggedIn,
  isShowErrorPage,
  saasData,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        isShowErrorPage ? (
          <ErrorPage />
        ) : saasData ? (
          <Component {...props} />
        ) : (
          <Loader />
        )
      ) : (
        <Redirect
          to={{
            pathname: '/sign-in',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const PreventedRoute = ({
  component: Component,
  isLoggedIn,
  saasData,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      let { from } =
        props.location.state ||
        (saasData && {
          from: {
            pathname: convertIntoHypenValues(
              getModuleDetails('ui', 'employees'),
            ),
          },
        });
      return isLoggedIn && saasData ? (
        <Redirect to={from} />
      ) : (
        <Component {...props} />
      );
    }}
  />
);

const PublicRoutes = ({
  history,
  isLoggedIn,
  validatingAuthToken,
  permissionsLoader,
  saasLoader,
  saasData,
  isShowErrorPage,
  isSubDomainInvalid,
}) => {
  if (validatingAuthToken || permissionsLoader || saasLoader) {
    return <Loader />;
  }
  const page404 = (
    <Route
      component={asyncComponent(() => import('../containers/pages/404'))}
    />
  );
  return (
    <Router history={history}>
      <Switch>
        {isSubDomainInvalid && page404}
        {(!isLoggedIn || (isLoggedIn && saasData)) && (
          <Route
            exact
            path={'/'}
            render={() => <Redirect to={{ pathname: '/sign-in' }} />}
          />
        )}
        {(!isLoggedIn || (isLoggedIn && saasData)) && (
          <Route
            exact
            path={'/login'}
            render={() => <Redirect to={{ pathname: '/sign-in' }} />}
          />
        )}
        {(!isLoggedIn || (isLoggedIn && saasData)) && (
          <Route
            exact
            path={'/signin'}
            render={() => <Redirect to={{ pathname: '/sign-in' }} />}
          />
        )}
        {(!isLoggedIn || (isLoggedIn && saasData)) && (
          <Route
            exact
            path={'/sign_in'}
            render={() => <Redirect to={{ pathname: '/sign-in' }} />}
          />
        )}
        <PreventedRoute
          path="/sign-in"
          component={asyncComponent(() =>
            import('../containers/onBoarding/login'),
          )}
          isLoggedIn={isLoggedIn}
          saasData={saasData}
        />
        <PreventedRoute
          path="/forgot-password"
          component={asyncComponent(() =>
            import('../containers/onBoarding/forgotPassword'),
          )}
          isLoggedIn={isLoggedIn}
          saasData={saasData}
        />
        <PreventedRoute
          path="/reset-password"
          component={asyncComponent(() =>
            import('../containers/onBoarding/resetPassword'),
          )}
          isLoggedIn={isLoggedIn}
          saasData={saasData}
        />
        <PreventedRoute
          path="/employees/reset-password"
          component={asyncComponent(() =>
            import('../containers/onBoarding/resetPassword'),
          )}
          isLoggedIn={false}
          saasData={saasData}
        />
        <PrivateRoute
          path="/"
          component={App}
          isLoggedIn={isLoggedIn}
          isShowErrorPage={isShowErrorPage}
          saasData={saasData}
        />
        {page404}
      </Switch>
    </Router>
  );
};

export default connect(state => ({
  isLoggedIn: state.Auth.isLoggedIn,
  saasLoader: state.Auth.saasLoader,
  saasData: state.Auth.saasData,
  isShowErrorPage: state.Auth.isShowErrorPage,
  validatingAuthToken: state.Auth.validatingAuthToken,
  permissionsLoader: state.Auth.permissionsLoader,
  isSubDomainInvalid: state.Auth.isSubDomainInvalid,
}))(PublicRoutes);
